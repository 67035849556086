import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import * as AllIcons from 'react-icons/fa';
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddMasterItemInventory({ api }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const generateIconComponent = (iconName) => {
    const IconComponent = AllIcons[iconName];
    return <IconComponent size={20} />;
  };

  const iconOptions = Object.keys(AllIcons).map((iconName) => ({
    label: iconName,
    value: iconName,
    icon: generateIconComponent(iconName),
  }));

  const formik = useFormik({
    initialValues: {
      key: '',
      value: '',
      description: '',
      selectedIcon: null,
    },

    validate: (values) => {
      const errors = {};

      if (!values.key) {
        errors.key = 'Key Name Is Required';
      }
      if (!values.value) {
        errors.value = 'Value is Required';
      }
      if (!values.description) {
        errors.description = 'Description is Required';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      const dataToSend = {
        key: values.key,
        value: values.value,
        description: values.description,
        icon: values.selectedIcon?.label || '',
      };
      setLoading(true);
      api.post(`/addInventoryMaster`, dataToSend).then((response) => {
        if (response.status === 201) {
          if (response.data.status === false) {
            navigate('/403');
          }
          setLoading(false);
          navigate('/Master-Item-List');
          setTimeout(() => {
            showToast(response.data.message, "success")
          }, 1000);
          setSubmitting(false);
        }
      }).catch((error) => {
        showToast(error.response.data.errors,'error');
        setLoading(false);
      });
    },
  });

  const partsOfComputer = "Input Computer parts name like CPU , Mouse , Keyboard  etc...";
  const brandNameOfParts = "Input brand name of parts like Sony, Dell, Logitech";
  const descriptionTooltip = "In this description you should be give proper information about instrument.";

  return (
    <>
      <div>
        <Row>
          <Col sm="12" lg="6">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Add new inventories</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="key" className='d-flex align-item-center'>
                      Computer Parts
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip-help">{partsOfComputer}</Tooltip>}
                      >
                        <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                          <FaQuestionCircle />
                        </Button>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      id="key"
                      name="key"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.key}
                      isInvalid={formik.touched.key && formik.errors.key}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.key}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label htmlFor="value" className='d-flex align-item-center'>
                      Brand Name
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip-help">{brandNameOfParts}</Tooltip>}
                      >
                        <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                          <FaQuestionCircle />
                        </Button>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      id="value"
                      name="value"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.value}
                      isInvalid={formik.touched.value && formik.errors.value}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.value}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label htmlFor="value" className='d-flex align-item-center'>
                      description
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip-help">{descriptionTooltip}</Tooltip>}
                      >
                        <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                          <FaQuestionCircle />
                        </Button>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      id="description"
                      name="description"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      isInvalid={formik.touched.description && formik.errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Select an Icon:</Form.Label>
                    <Select
                      value={formik.values.selectedIcon}
                      onChange={(selectedOption) => formik.setFieldValue('selectedIcon', selectedOption)}
                      options={iconOptions}
                      isClearable={true}
                      placeholder="Select an icon..."
                      isSearchable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      formatOptionLabel={(option) => (
                        <div>
                          {option.icon}
                          {option.label}
                        </div>
                      )}
                    />
                  </Form.Group>
                  <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/Master-Item-List")}
                  >
                    Cancel
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainerWrapper />
        </Row>
      </div>
    </>
  );
}

export default AddMasterItemInventory;
