import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditHoliday({ api }) {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/editHoliday/${id}`).then((res) => {
            if (res.data !== undefined) {
                setTitle(res.data.holidayData.holiday_name);
                const date = new Date(res.data.holidayData.holiday_date);
                const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

                setDate(formattedDate);
            }
        }).catch((error) => {
            console.log("error", error)
        })
        return () => {

        };
    }, []);

    const UpdateHoliday = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        setValidated(true);
        if (isValid) {
            await api.post(`/editHoliday/${id}`, {
                holiday_name: title,
                holiday_date: date
            }).then((response) => {
                navigate('/holiday');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100)
            }).catch((error) => {
                if (error.response.status == 403) {
                    navigate("/403");
                  }
                setLoading(false);
                showToast(error.response.data.errors,'error');
            });
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Holiday</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={e => UpdateHoliday(e)} noValidate validated={validated}>
                                    <Row>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Holiday Title:<span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" id="title"
                                                    value={title}
                                                    onChange={e => setTitle(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="task_description">Date:<span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="date"
                                                    value={date}
                                                    onChange={e => setDate(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                                                {loading ? 'Updating...' : 'Update'}
                                            </Button>
                                            <Button type="submit" variant="btn btn-danger" onClick={() => navigate
                                                ('/holiday')}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ToastContainerWrapper />
            </div>
        </>
    )
}

export default EditHoliday;
