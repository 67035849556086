import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditTask({ api }) {

    const { id } = useParams();
    const [task_type, setTask_type] = useState("0");
    const [title, setTitle] = useState("");
    const [hours, setHours] = useState("");
    const [hoursError, setHoursError] = useState(false);
    const [short_description, setShort_description] = useState("");
    const [project, setProject] = useState('');
    const [employee, setEmployee] = useState('');
    const [projectlist, setProjectlist] = useState([]);
    const [employeelist, setEmployeelist] = useState([]);
    const [prevProject, setPrevProject] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/editTask/${id}`).then((response) => {
            if (response.data !== undefined) {
                setTitle(response.data.taskdata[0].title);
                setEmployee(response.data.taskdata[0].user_id);
                setShort_description(response.data.taskdata[0].short_description);
                setHours(response.data.taskdata[0].estimatedHours);
                setProject(response.data.taskdata[0].project_id);
                setTask_type(response.data.taskdata[0].task_type);
                setProjectlist(response.data.projectData);
            }
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            console.log("error", error)
        });
    }, []);

    useEffect(() => {
        if (project !== '') {
            api.post(`/getUserByProject/${project}`, '').then((response) => {
                if (response.data !== undefined) {
                    setEmployeelist(response.data.userProjectData.userData);
                }
            }).catch((error) => {
                showToast(error.response.data.errors, 'error');
            });
        }
        if (prevProject !== project) {
            resetEmployeeList();
        }
        setPrevProject(project);
    }, [project]);

    const UpdateTask = async (e) => {
        e.preventDefault();
        setLoading(true);
        const requestData = {
            title,
            short_description,
            hours,
            project_id: project,
            user_id: employee,
            task_type: task_type,
            task_estimation: hours
        };
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            await api.post(`/editTask/${id}`, requestData).then((response) => {
                navigate('/task');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            }).catch((error) => {
                if(error.response.status == 403){
                    navigate('/403')
                 }
                showToast(error.response.data.errors, 'error');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setValidated(true);
            setLoading(false);
        }
    };

    const resetEmployeeList = () => {
        setEmployeelist([]);
    };

    useEffect(() => {
        if (hours < 0) {
            setHoursError(true);
        } else {
            setHoursError(false);
        }
    }, [hours]);

    const options = [
        { value: '0', label: 'Feature' },
        { value: '1', label: 'Bug' }
    ];

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Task</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => UpdateTask(e)} noValidate validated={validated}>
                                <Row>
                                    <Col sm="12" lg="4">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Project </Form.Label>
                                            <select
                                                className='form-select mb-3 shadow-none form-control'
                                                onChange={(e) => setProject(e.target.value)}
                                                value={project}
                                            >
                                                <option value="">Select</option>
                                                {projectlist?.length > 0 ? (
                                                    projectlist.map((item, index) => (
                                                        <option value={item._id} key={index}>
                                                            {item.title}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option disabled>No data found</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Label htmlFor="permission_description">Employee</Form.Label>
                                        <select
                                            name="project_id"
                                            id=""
                                            className='form-select mb-3 shadow-none form-control'
                                            value={employee}
                                            onChange={e => setEmployee(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            {employeelist?.length > 0 ? (
                                                employeelist.map((item, index) => (
                                                    <option value={item._id} key={index}>
                                                        {item.firstname}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>No data found</option>
                                            )}
                                        </select>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Type</Form.Label>
                                            <Select
                                                options={options}
                                                value={options.find(item => item.value == task_type)}
                                                onChange={(e) => setTask_type(e.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="title"
                                                value={title}
                                                onChange={e => {
                                                    setTitle(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="task_description">Description:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="task_description"
                                                value={short_description}
                                                onChange={e => {
                                                    setShort_description(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Estimated hours</Form.Label>
                                            <Form.Control
                                                type="number"
                                                id="title"
                                                value={hours}
                                                onChange={e => setHours(e.target.value)}
                                                step={0.5}
                                                min={0}
                                                style={hoursError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <Button
                                            type="submit"
                                            variant="btn btn-primary me-2"
                                            disabled={loading}
                                        >
                                            {loading ? 'Updating...' : 'Update'}
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="btn btn-danger"
                                            onClick={() => navigate('/task')}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <ToastContainerWrapper />
            </Row>
        </>
    );
}

export default EditTask;
