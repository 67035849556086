import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "../../assets/css/pagination.css";
import Card from "../../components/Card";
import { PermissionData } from '../../layouts/dashboard/default';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../plugins/toastUtils'

const TechnologyLists = ({ api }) => {
    const navigate = useNavigate();
    const Permissions = useContext(PermissionData);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [technologyData, setTechnologyData] = useState([]);
    const [entry] = useState(10);
    const [dataperPage, setDataperPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const technologydata = () => {
        setLoading(true);
        api.get(`/technologies?search=${searchValue}&page=${pageNumber}&limit=${dataperPage}`).then((response) => {
            if (response.status === false) {
                navigate('/403');
            } else {
                setTechnologyData(response.data.technologyData);
                setTotalPages(response.data.totalPages);
                setLoading(false);
            }
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            setLoading(false);
            showToast(error.response.data.errors, 'error');
        });
    }

    const perpagedata = (e) => {
        setDataperPage(e.target.value);
        setPageNumber(0);
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected + 1);
    };

    const handleSearch = async (event) => {
        if (searchValue && event.key === 'Enter') {
            technologydata()
        }
    }

    useEffect(() => {
        if (!searchValue && technologyData.length) {
            technologydata()
        }
    }, [searchValue]);

    useEffect(() => {
        technologydata();
    }, [pageNumber, dataperPage, searchValue]);

    const renderTechnologyData = () => {
        if (technologyData.length === 0 && !loading) {
            return (
                <tr>
                    <td colSpan={3} className="text-center">No data found</td>
                </tr>
            );
        } else {
            return (
                technologyData.map((item, index) => (
                    <tr className="border-light" key={index}>
                        <td>{item.index}</td>
                        <td>{item.technology}</td>
                        {(Permissions.includes("Update Technology")) && (

                            <td>
                                <div className="d-flex" style={{ gap: "3px" }}>
                                    <div>
                                        <Link
                                            className="btn btn-sm btn-icon btn-warning"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            to="edit"
                                            data-original-title=""
                                            state={{
                                                data: item
                                            }}
                                        >
                                            <span className="btn-inner">
                                                <svg
                                                    width="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                        d="M15.1655 4.60254L19.7315 9.16854"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                ))
            );
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">List of Technologies</h4>
                                </div>
                                <div className="Add-user">
                                    {(Permissions.includes('Add Technology')) && (
                                        <Link
                                            to="add"
                                            className="add-emp"
                                            style={{ marginRight: "5px" }}
                                        >
                                            <h4 className="btn btn-primary">
                                                <i
                                                    className="fa-solid fa-plus"
                                                    style={{ marginRight: "5px" }}
                                                ></i>
                                                Add Technology
                                            </h4>
                                        </Link>
                                    )}
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="row p-3 mb-3">
                                    <div className="col-md-6">
                                        <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                            <label>Show</label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select-sm w-25"
                                                onChange={perpagedata}
                                                defaultValue={entry}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%' }}>
                                            <label>Search:</label>
                                            <input type="search" className="form-control form-control-sm w-50" placeholder=""
                                                aria-controls="user-list-table" onChange={(event) => setSearchValue(event.target.value)} onKeyDown={handleSearch}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive" id="emp-scroll">
                                    <table
                                        id="user-list-table"
                                        className="table table-striped"
                                        role="grid"
                                        data-toggle="data-table"
                                    >
                                        <thead>
                                            <tr className="ligth">
                                                <th>No</th>
                                                <th style={{ cursor: "pointer" }}>
                                                    <div className=" d-flex ">
                                                        <span className="title">Name</span>
                                                        <div className="sorting-icon justify-content-center align-items-center">
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-up position-relative `}
                                                                style={{ left: "50%" }}
                                                            ></i>
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-down position-relative `}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                {Permissions.includes('Update Technology') &&
                                                    <th min-width="100px">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={3} className="text-center">
                                                        <Spinner
                                                            animation="border"
                                                            variant="primary"
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            ) : renderTechnologyData()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <div className="">
                                        <div className="dataTables_length d-flex align-items-center mx-3" id="user-list-table_length">
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row align-items-center w-100">
                                            <div
                                                id="user-list-table_filter"
                                                className="dataTables_filter d-flex align-items-center justify-content-end"
                                                style={{ gap: "2%", position: "relative", right: "30%" }}
                                            >
                                                <div style={{ position: "relative", right: "74px" }}>
                                                    {technologyData?.length > 0 && (
                                                        <div className="dataTables_paginate paging_simple_numbers">
                                                            <ReactPaginate
                                                                previousLabel={"Previous"}
                                                                nextLabel={"Next"}
                                                                pageCount={totalPages}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={"pagination"}
                                                                previousLinkClassName={"page-link"}
                                                                nextLinkClassName={"page-link"}
                                                                disabledClassName={"disabled"}
                                                                activeClassName={"active"}
                                                                breakLabel="..."
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
};

export default TechnologyLists;