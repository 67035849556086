import FsLightbox from 'fslightbox-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../assets/css/employeeprofile.css';
import { default as avatars1, default as avatars2 } from '../../assets/images/avatars/avtar_1.png';
import avatars3 from '../../assets/images/avatars/avtar_2.png';
import avatars4 from '../../assets/images/avatars/avtar_3.png';
import avatars5 from '../../assets/images/avatars/avtar_4.png';
import avatars6 from '../../assets/images/avatars/avtar_5.png';
import icon1 from '../../assets/images/icons/01.png';
import icon2 from '../../assets/images/icons/02.png';
import icon4 from '../../assets/images/icons/04.png';
import icon5 from '../../assets/images/icons/05.png';
import icon8 from '../../assets/images/icons/08.png';
import shap2 from '../../assets/images/shapes/02.png';
import shap4 from '../../assets/images/shapes/04.png';
import shap6 from '../../assets/images/shapes/06.png';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';
import '../Employee/edit-profile.css';

const EmployeePersonalProfile = ({ api, user_id, imagePath }) => {

   const themeData = useSelector((state) => state.mode.colorcustomizer);
   const [employeedata, setemployeedata] = useState("");
   const [inventoryData, setInventoryData] = useState("");
   const [cpudata, setCpuData] = useState("");

   useEffect(() => {
      api.get(`/profile/${user_id}`).then((response) => {
         if (response.data.userData[0] !== undefined) {
            setemployeedata(response.data.userData[0]);
            setInventoryData(response.data.AssignInventoryData)
            setCpuData(response.data.cpuData[0])
         }
      }).catch((error) => {
         showToast(error.response.data.errors, 'error');
      });
      return () => {
      };
   }, []);

   let oldDob = moment(new Date(employeedata.dob)).format('DD-MM-YYYY').split('T')[0];
   let oldDoj = moment(new Date(employeedata.doj)).format('DD-MM-YYYY').split('T')[0];

   return (
      <>
         <FsLightbox
            sources={[icon4, shap2, icon8, shap4, icon2, shap6, icon5, shap4, icon1]}
         />
         <div className="profile-data">
            <Tab.Container defaultActiveKey="first">
               <Row className='edit-profile'>
                  <Form className='main-form'>
                     <Col lg="12" md="12">
                        <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">

                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                       <img src={`${employeedata?.photo ? imagePath + employeedata?.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' ? avatars4 : avatars1
                                          }`} alt="Profile" className="theme-color-default-img img-fluid avatar avatar-100 avatar-rounded" />
                                       <img src={`${employeedata.photo ? imagePath + employeedata.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' && avatars4
                                          }`} alt="User-Profile" className="theme-color-purple-img img-fluid avatar avatar-100 avatar-rounded" />
                                       <img src={`${employeedata.photo ? imagePath + employeedata.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' && avatars4
                                          }`} alt="User-Profile" className="theme-color-blue-img img-fluid avatar avatar-100 avatar-rounded" />
                                       <img src={`${employeedata.photo ? imagePath + employeedata.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' && avatars4
                                          }`} alt="User-Profile" className="theme-color-green-img img-fluid avatar avatar-100 avatar-rounded" />
                                       <img src={`${employeedata.photo ? imagePath + employeedata.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' && avatars4
                                          }`} alt="User-Profile" className="theme-color-yellow-img img-fluid avatar avatar-100 avatar-rounded" />
                                       <img src={`${employeedata.photo ? imagePath + employeedata.photo
                                          : themeData === 'theme-color-default' ? avatars1 :
                                             themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                   themeData === 'theme-color-red' ? avatars5 :
                                                      themeData === 'theme-color-yellow' ? avatars6 :
                                                         themeData === 'theme-color-pink' && avatars4
                                          }`} alt="User-Profile" className="theme-color-pink-img img-fluid avatar avatar-100 avatar-rounded" />
                                    </div>

                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                       <h4 className="me-2 h4">{employeedata.firstname}</h4>
                                       <span> - {employeedata.roleData !== undefined ? employeedata.roleData[0].role_name : ''}</span>
                                    </div>
                                 </div>

                                 <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab"
                                    id="profile-pills-tab" role="tablist">
                                    <Nav.Item as="li">
                                       <Link className='btn btn-primary active' to={`edit/${employeedata._id}`}>Edit Profile</Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>

                     <Col lg="12" md="12">
                        <Card>
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Personal information</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <Row>
                                 <div className='part-1 col-lg-6 col-md-6'>
                                    <div className="mt-2">
                                       <h6 className="mb-1">First name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.firstname ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.firstname}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Middle name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.middle_name ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.middle_name}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Last name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.last_name ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.last_name}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Contact number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.mo_number ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.mo_number}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Date of birth:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control disabled type="text" className="form-control  position-relative"
                                             name="user_name" id='username'
                                             defaultValue={oldDob !== "Invalid date" ? oldDob : ''} />
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Gender:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.gender ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.gender}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Personal email:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.personal_email ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.personal_email}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Joined:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                             defaultValue={oldDoj !== "Invalid date" ? oldDoj : ''} />
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Role:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.roleData ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.roleData !== undefined ? employeedata.roleData[0].role_name : ""}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>
                                 </div>

                                 <div className="part-2 col-lg-6 col-md-6">
                                    <div className="mt-2">
                                       <h6 className="mb-1">Employee Code:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.emp_code ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.emp_code}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Pancard number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.pan_number ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.pan_number}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Aadhar number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.aadhar_number ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.aadhar_number}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 1:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.add_1 ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.add_1}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 2:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.add_2 ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.add_2}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">City:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.city ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.city}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">State:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.state && employeedata.state !== "undefined" ? (
                                             < Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.state}
                                             />
                                          ) : (<span className="form-control">N/A</span>)
                                          }
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Country:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.country ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.country}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Pincode:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.pincode ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.pincode}
                                             />
                                          ) : (
                                             <span className="form-control">N/A</span>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </Row>
                           </Card.Body>
                        </Card>
                     </Col>

                     <Row>
                        <Col lg="6" md="6">
                           <Card>

                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Banking Detail</h4>
                                 </div>
                              </Card.Header>

                              <Card.Body className='box-2'>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Bank name:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.bank_name ? (
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                             defaultValue={employeedata.bank_name} />
                                       ) : (
                                          <span className="form-control">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Ifsc Code:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.ifsc_code ? (
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id='username'
                                             placeholder=""
                                             defaultValue={employeedata.ifsc_code}
                                          />
                                       ) : (
                                          <span className="form-control">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Account number:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.bank_account_no ? (
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id='username'
                                             placeholder=""
                                             defaultValue={employeedata.bank_account_no}
                                          />
                                       ) : (
                                          <span className="form-control" >N/A</span>
                                       )}
                                    </div>
                                 </div>

                              </Card.Body>
                           </Card>
                        </Col>

                        <Col lg="6" md="6">
                           <Card>

                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Login Information</h4>
                                 </div>
                              </Card.Header>

                              <Card.Body className='box-3'>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Username:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.user_name ? (
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username' pattern="^[A-Za-z0-9]{4,29}$"
                                             defaultValue={employeedata.user_name} />
                                       ) : (
                                          <span className="form-control">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Email address:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.company_email ? (
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id='username'
                                             placeholder=""
                                             defaultValue={employeedata.company_email}
                                          />
                                       ) : (
                                          <span className="form-control">N/A</span>
                                       )}
                                    </div>
                                 </div>

                              </Card.Body>
                           </Card>
                        </Col>

                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">My Inventory Item</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-3'>
                                 {inventoryData?.length > 0 && inventoryData.map((item) => (
                                    <div className="mt-2" key={item._id}>
                                       <h6 className="mb-1">{item.main_key}:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id={`username-${item._id}`}
                                             pattern="^[A-Za-z0-9]{4,29}$"
                                             value={item.name || "N/A"}
                                          />
                                       </div>
                                    </div>
                                 ))}
                              </Card.Body>
                           </Card>
                        </Col>

                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">CPU Inventory Item</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-3'>
                                 {cpudata?.length > 0 && cpudata.map((item, index) => (
                                    <div key={index} className="mt-2">
                                       <h6 className="mb-1">{item.key}:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control
                                             key={index}
                                             disabled
                                             type="text"
                                             className="form-control mb-2"
                                             name={`cpu_id_${index}`}
                                             id={`cpu_id_${index}`}
                                             placeholder={`Id No ${index + 1}`}
                                             defaultValue={item.value}
                                          />
                                       </div>
                                    </div>
                                 ))}
                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </Form>
                  <ToastContainerWrapper />
               </Row>
            </Tab.Container >
         </div >
      </>
   )
}

export default EmployeePersonalProfile;