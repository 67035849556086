import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./consoleConfig";
import reportWebVitals from "./reportWebVitals";
import Store from "./store/index";
import { AxiosWrapper } from "./utils/api";

let persistor = persistStore(Store);
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
// console.log = () => { }
root.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <Provider store={Store}>
        <PersistGate persistor={persistor}>
          <AxiosWrapper />
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 3000,
              success: {
                style: {
                  padding: "14px",
                  background: "#d8ffe3",
                  border: "1px solid green",
                  color: "black",
                  fontWeight: "bolder",
                },
              },
              error: {
                style: {
                  padding: "14px",
                  background: "#ffdede",
                  color: "black",
                  fontWeight: "bolder",
                  border: "1px solid red",
                },
              },
            }}
          />
          <App />
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
