import React from "react";
import { Row, Col, Image, Alert } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import mail from "../../../assets/images/auth/03.png";
import auth7 from "../../../assets/images/auth/07.png";
import Card from "../../../components/Card";
import { useState } from "react";
import { useEffect } from "react";

const ConfirmMail = () => {
  // const [emailStatus, setEmailStatus] = useState(false);

  // useEffect(() => {
  //   const email = localStorage.getItem("hasChangeEmail");
  //   if (email !== null) {
  //     setEmailStatus(true);
  //   } else {
  //     setEmailStatus(false);
  //   }
  //   return () => { };
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     localStorage.removeItem("hasChangeEmail");
  //     //   event.preventDefault();
  //     //   event.returnValue = '';
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const { email } = useParams();

  return (
    <>

      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-3">
              <Card.Body>
                <Link
                  to="/dashboard"
                  className="navbar-brand d-flex align-items-center mb-3"
                >
                  <img src={auth7} alt="" height={50} width={50} />

                  <h4 className="logo-title ms-3">CodeCrew EMS</h4>
                </Link>
                <Row>
                  <Col lg="12">
                    <Alert
                      variant="warning d-flex align-items-center"
                      role="alert"
                    >
                      <svg
                        className="me-2"
                        id="exclamation-triangle-fill"
                        fill="currentColor"
                        width="20"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                      </svg>
                      <div>You can't access this page directly</div>
                    </Alert>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={mail}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>

      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-3">
              <Card.Body>
                <Link
                  to="/dashboard"
                  className="navbar-brand d-flex align-items-center mb-3"
                >
                  <img src={auth7} alt="" height={50} width={50} />

                  <h4 className="logo-title ms-3">CodeCrew EMS</h4>
                </Link>
                <h2 className="mt-3 mb-0">Success !</h2>
                <p className="cnf-mail mb-1">
                  An email has been send to {email}. Please check for an email
                  from company and click on the included link to reset your
                  password.
                </p>
                <div className="d-inline-block w-100">
                  <Link to="/login" className="btn btn-primary mt-3">
                    Back to Home
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={mail}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>

    </>
  );
};

export default ConfirmMail;
