import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../components/Card'

const EmployeeSalaryList = ({ api, user_data }) => {

    const navigate = useNavigate();
    let currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();
    const [salaryData, setSalaryData] = useState([]);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [deleteid, setDeleteid] = useState('');
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState('ASC');
    const [pageNumber, setPageNumber] = useState(0);
    const [entry] = useState(10);
    const [dataperPage, setDataperPage] = useState(entry);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setYear(currentYear);
        setMonth(currentMonth);
        return () => {
        };
    }, []);

    const SalaryDetail = async () => {
        setLoading(true);
        await api.get(`/salaryListing`).then((response) => {
            if (response.data !== undefined) {
                if (response.data.status === false) {
                    navigate('/403')
                }
                else {
                    if (user_data.roleData[0].role_name === 'Admin') {

                        setSalaryData(response.data.adminSalaryData);
                    } else {
                        setSalaryData(response.data.userSalaryData);
                    }
                }
            }
            setLoading(false);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    useEffect(() => {
        SalaryDetail();
        return () => {
        };
    }, []);

    const Handledelete = async () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            await api.post(`/deleteHoliday/${id}`, '').then(() => {
                setShow(false);
                SalaryDetail();
            }).catch((error) => {
                console.log("error", error)
                setLoading(false);
            });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    const PageChange = ({ selected }) => {
        setPageNumber(selected);
    }

    const perpagedata = (e) => {
        setDataperPage(e.target.value);
    }

    const sorting = (col) => {
        if (order === 'ASC') {
            const sorted = [...salaryData].sort((a, b) =>
                a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            if (searchData.length > 0) {
                setSearchData(sorted);
            }
            else {
                setSalaryData(sorted);
            }
            setOrder('DSC')
        }
        if (order === 'DSC') {
            const sorted = [...salaryData].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            if (searchData.length > 0) {
                setSearchData(sorted);
            }
            else {
                setSalaryData(sorted);
            }
            setOrder('ASC')
        }
    }

    const handlePdf = (id) => {
        api.post(`/salary-slip/${id}/${month}/${year}`, { responseType: 'arraybuffer' }).then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `salary_slip-${id}-${month}-${year}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }).catch(error => {
            console.error(error);
        });
    }

    const showAlert = () => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Email Send Successfully',
            showConfirmButton: false,
            timer: 1500

        })
    }

    const handleEmail = (id) => {
        api.post(`/send_salary-slip/${id}/${month}/${year}`, '')
            .then((response) => {
                if (response.data.email_status === true) {
                    showAlert();
                }
                SalaryDetail();
            }).catch((error) => {
                console.log("error", error)
            });
    }

    const pageVisited = pageNumber * dataperPage;

    const pageCount = Math.ceil((searchData.length > 0 ? searchData.length : salaryData.length) / dataperPage);

    return (
        <>
            <div>
                <Row>
                    <Modal show={show} onHide={handleClose} className='text-center'>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button variant="primary" disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                            <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>
                        </Modal.Body>
                    </Modal>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Employee Salary</h4>
                                </div>
                            </Card.Header>
                            <Card.Header className={`d-flex justify-content-between`}>
                                <div className="row align-items-center w-100">
                                    <div className="col-md-2">
                                        <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                            <label>Show</label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select-sm " onChange={(e) => perpagedata(e)}
                                                defaultValue={entry}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                            <label> Year</label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select-sm "
                                                onChange={(e) => setYear(e.target.value)}
                                                defaultValue={currentYear}
                                            >
                                                <option value={`${moment().year() - 2}`}>{moment().year() - 2}</option>
                                                <option value={`${moment().year() - 1}`}>{moment().year() - 1}</option>
                                                <option value={`${moment().year()}`}>{moment().year()}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                            <label> Month</label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select-sm "
                                                onChange={(e) => setMonth(e.target.value)}
                                                defaultValue={currentMonth}>
                                                <option value="" disabled>select</option>
                                                <option value="1">January</option>
                                                <option value="2">February</option>
                                                <option value="3">March</option>
                                                <option value="4">April</option>
                                                <option value="5">May</option>
                                                <option value="6">June</option>
                                                <option value="7">July</option>
                                                <option value="8">August</option>
                                                <option value="9">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                        <thead>
                                            <tr className="ligth">
                                                <th>No</th>
                                                <th style={{ cursor: 'pointer' }} onClick={() => sorting('salaryData_name')}>
                                                    <div className=" d-flex ">
                                                        <span className="title">Name</span>
                                                        <div className="sorting-icon justify-content-center align-items-center">
                                                            <i className={`fa-sharp fa-solid fa-sort-up position-relative ${order === 'ASC' ? 'opacity-50' : ''}`} style={{ left: "50%" }}></i>
                                                            <i className={`fa-sharp fa-solid fa-sort-down position-relative ${order === 'DSC' ? 'opacity-50' : ''}`}></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th min-width="100px">Action</th>
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={20} className='text-center'>
                                                        <Spinner animation="border" variant="primary" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className='text-capitalize'>
                                                {
                                                    salaryData.length > 0 ? salaryData.slice(pageVisited, +pageVisited + +dataperPage).map((item, index) => (
                                                        <tr key={index} className="border-light">
                                                            <td>{pageVisited + index + 1}</td>
                                                            <td>{item.firstname + ' ' + item.last_name}</td>
                                                            {
                                                                <td>
                                                                    {user_data.roleData[0].role_name === 'Admin' ?

                                                                        <Button onClick={() => handlePdf(item._id)} className='cursor-pointer bg-transparent p-0 border-0'>
                                                                            <svg
                                                                                width="30"
                                                                                version="1.1"
                                                                                id="Capa_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                                viewBox="0 0 56 56"
                                                                                xmlSpace="preserve"
                                                                            >
                                                                                <g>
                                                                                    <path
                                                                                        style={{ fill: "#E9E9E0" }}
                                                                                        d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                                                                                    />
                                                                                    <polygon
                                                                                        style={{ fill: "#D9D7CA" }}
                                                                                        points="37.5,0.151 37.5,12 49.349,12 	"
                                                                                    />
                                                                                    <path
                                                                                        style={{ fill: "#CC4B4C" }}
                                                                                        d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
		c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
		c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
		c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
		c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
		c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
		c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
		c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
		 M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
		c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
		c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
		 M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                                                                                    />
                                                                                    <path
                                                                                        style={{ fill: "#CC4B4C" }}
                                                                                        d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                                                                                    />
                                                                                    <g>
                                                                                        <path
                                                                                            style={{ fill: "#FFFFFF" }}
                                                                                            d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
			c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
			c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
			s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
			c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
			c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
			c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                                                                                        />
                                                                                        <path
                                                                                            style={{ fill: "#FFFFFF" }}
                                                                                            d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
			s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
			c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
			 M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
			c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                                                                                        />
                                                                                        <path
                                                                                            style={{ fill: "#FFFFFF" }}
                                                                                            d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                                                                                        />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </Button>
                                                                        :
                                                                        <Button onClick={() => handleEmail(item._id)} className='cursor-pointer p-0 border-0 bg-transparent'>
                                                                            <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.4" d="M22 15.94C22 18.73 19.76 20.99 16.97 21H16.96H7.05C4.27 21 2 18.75 2 15.96V15.95C2 15.95 2.006 11.524 2.014 9.298C2.015 8.88 2.495 8.646 2.822 8.906C5.198 10.791 9.447 14.228 9.5 14.273C10.21 14.842 11.11 15.163 12.03 15.163C12.95 15.163 13.85 14.842 14.56 14.262C14.613 14.227 18.767 10.893 21.179 8.977C21.507 8.716 21.989 8.95 21.99 9.367C22 11.576 22 15.94 22 15.94Z" fill="currentColor" />
                                                                                <path d="M21.4759 5.67351C20.6099 4.04151 18.9059 2.99951 17.0299 2.99951H7.04988C5.17388 2.99951 3.46988 4.04151 2.60388 5.67351C2.40988 6.03851 2.50188 6.49351 2.82488 6.75151L10.2499 12.6905C10.7699 13.1105 11.3999 13.3195 12.0299 13.3195C12.0339 13.3195 12.0369 13.3195 12.0399 13.3195C12.0429 13.3195 12.0469 13.3195 12.0499 13.3195C12.6799 13.3195 13.3099 13.1105 13.8299 12.6905L21.2549 6.75151C21.5779 6.49351 21.6699 6.03851 21.4759 5.67351Z" fill="currentColor" />
                                                                            </svg>
                                                                        </Button>
                                                                    }
                                                                </td>
                                                            }
                                                        </tr>
                                                    )) :
                                                        <tr><td colSpan={4} className='text-center'>No data Found</td></tr>
                                                }
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="row align-items-center w-100">
                                    <div className="col-md-6">
                                        {salaryData?.length > 0 &&
                                            <div className="dataTables_length d-flex align-items-center mx-3" id="user-list-table_length">
                                                <label> Total {searchData.length > 0 ? searchData.length : searchData.length > 0 ? searchData.length : salaryData.length} entries</label>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        {salaryData?.length > 0 &&
                                            <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%', position: "relative", right: '30%' }}>
                                                <div className="dataTables_paginate paging_simple_numbers" id="user-list-table_paginate">
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        pageCount={pageCount}
                                                        onPageChange={PageChange}
                                                        containerClassName={'pagination align-items-center'}
                                                        previousClassName={'paginate_button page-item previous'}
                                                        previousLinkClassName={'page-link border-0'}
                                                        nextLinkClassName={'page-link border-0'}
                                                        nextClassName={'paginate_button page-item next border-none'}
                                                        disabledClassName={'disabled'}
                                                        activeClassName={'active'}
                                                        breakLabel="..."
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EmployeeSalaryList;