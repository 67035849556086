import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/authSlice'

const baseURL = process.env.REACT_APP_API_KEY
const apiKey = process.env.REACT_APP_API_SECRET_KEY

const axiosInstance = axios.create({
    baseURL
})

export const AxiosWrapper = () => {
    const token = useSelector((state) => state.user.user.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('user');
        localStorage.removeItem('message');
        navigate('/login');
    };

    axiosInstance.interceptors.request.use(
        function (config) {
            config.headers['x-api-key'] = apiKey;
            if (token) config.headers['x-access-token'] = token;
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
            return Promise.reject(error);
        }
    );

    return null;
};

export default axiosInstance