// src/slices/holidaysSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

// Async thunk to fetch holidays
// export const fetchHolidays = createAsyncThunk(
//   "holidays/fetchHolidays",
//   async () => {
//     const response = await axios.get("/jkab");
//     return response.data; // Assuming the API returns an array of holiday objects
//   }
// );

export const fetchHolidayList = createAsyncThunk(
  "holiday/fetchHolidays",
  async (query, { getState }) => {
    const { searchValue, page, dataperPage, year, order, reload } = query || {};
    const currentState = getState();
    let response = []
    if (reload) {
      response = await api.get(
        `/holidayListing?search=${searchValue}&page=${page}&limit=${dataperPage}&year=${year}&nameSort=${order}`
      );
    } else {
      response = currentState.holidays.holidays;
    }
    return response;
  }
);

const holidaysSlice = createSlice({
  name: "holidays",
  initialState: {
    holidays: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidayList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHolidayList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.holidays = action.payload;
      })
      .addCase(fetchHolidayList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default holidaysSlice.reducer;
