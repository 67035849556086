import React, { useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/customAlert.css";
import Card from "../../components/Card";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast } from "../../plugins/toastUtils";

const RolePermission = ({ api, id, role_name }) => {
  const themeMode = useSelector((state) => state.mode.scheme);
  let navigate = useNavigate();
  const [permission, setPermission] = useState([]);
  const [permissionModules, setPermissionModules] = useState([]);
  const [existpermission, setExistpermission] = useState([]);
  const [unChek, setUnChek] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const permissiondata = () => {
    api
      .get(`/rolepermission/${id}`)
      .then((response) => {
        if (response.data !== undefined) {
          setPermission(response.data.permissions);
          setExistpermission(response.data.roleHasPermission);
          setPermissionModules(response.data.abc);
        }
      })
      .catch((error) => {
        showToast(error.response.data.errors, "error");
      });
  };

  const GivePermission = (e) => {
    e.preventDefault();
    let ResultData = [...new Set(existpermission)];
    setLoading(true);
    api
      .post(`/rolepermission/${id}`, {
        role_id: id,
        permission_id: ResultData,
      })
      .then((response) => {
        showToast(response.data.message, "success");
        setLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.errors, "error");
        setLoading(false);
      });
  };

  const handlePermissionChange = (e) => {
    const permissionId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setExistpermission([...existpermission, permissionId]);
      setSelectAll(existpermission.length + 1 === permission.length);
    } else {
      const newPermissions = existpermission.filter(
        (id) => id !== permissionId
      );
      setExistpermission(newPermissions);
      setSelectAll(false);
    }
  };

  const handleMultiSelect = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectAll(true);
      const allPermissionIds = permission.map((p) => p._id);
      setExistpermission(allPermissionIds);
      setUnChek(true);
    } else {
      setSelectAll(false);
      setExistpermission([]);
      setUnChek(false);
    }
  };

  useEffect(() => {
    permissiondata();
    return () => { };
  }, []);

  useEffect(() => {
    if (existpermission.length === permission.length) {
      setUnChek(true);
    } else {
      setUnChek(false);
    }
    return () => { };
  }, [existpermission]);

  // const newModules = permissionModules?.map((module) => module.name);
  const Modules = [
    "Holiday",
    "Employee",
    "Role",
    "Leave",
    "Permission",
    "Project",
    "Task",
    // "Question",
    // "Assignment",
    // "Billing",
    // "Assign Inventory",
    // "Creation of Inventories",
    // "Master Inventories",
    // "CPU master Item",
    "Announcement",
    "Dropdown",
    "Salary",
    "Punching"
    // "Technology",
    // "master permission",
  ];

  const allModules = [...new Set([...Modules])];
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between flex-wrap">
              <div className="header-title">
                <h4 className="card-title mb-0">Role & Permission</h4>
              </div>
              <div>
                <Nav
                  as="ul"
                  className="d-flex nav-pills mb-0 text-center profile-tab"
                  data-toggle="slider-tab"
                  id="profile-pills-tab"
                  role="tablist"
                >
                  <Nav.Item as="li">
                    <Nav.Link eventKey="fourth" className="active">
                      <span className="text-capitalize">{role_name}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Card.Header>
            <Card.Body>
              <h3>
                Your are selecting a permission for{" "}
                <span className="text-capitalize">{role_name}</span>
              </h3>
              <div className="col-12">
                <Card.Body>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => handleMultiSelect(e)}
                    checked={unChek}
                  />
                  <span className="mx-2">
                    {unChek ? "Deselect All" : "Select All"}
                  </span>
                  <Form onSubmit={(e) => GivePermission(e)}>
                    <div className="col-md-12 mt-1">
                      <div className="row">
                        <div className="col-md-12 mt-1">
                          <div className="row">
                            <div
                              className={`fw-bold col-2  d-flex text-bold align-items-center ${themeMode === "dark" ? "border" : "bDefault"
                                } px-3 py-3`}
                              style={{
                                borderColor:
                                  themeMode === "dark" && "#1b202f !important",
                                backgroundColor:
                                  themeMode === "dark" ? "#151824" : "#f4f4f4",
                              }}
                            >
                              Modules
                            </div>
                            <div
                              className={`fw-bold col-10  d-flex justify-content-center align-items-center ${themeMode === "dark" ? "border" : "bDefault"
                                } px-3 py-3`}
                              style={{
                                borderColor:
                                  themeMode === "dark" && "#1b202f !important",
                                backgroundColor:
                                  themeMode === "dark" ? "#151824" : "#f4f4f4",
                              }}
                            >
                              Permissions
                            </div>

                            {allModules.map((module, index) => {
                              const matchedPermissions = permission.filter(
                                (p) =>
                                  p.permission_name
                                    .toLowerCase()
                                    .includes(module.toLowerCase())
                              );
                              return (
                                <React.Fragment key={Math.random()}>
                                  <div
                                    className={`module-name col-lg-2 col-md-12   d-flex text-bold align-items-center ${themeMode === "dark"
                                        ? "border"
                                        : "bDefault"
                                      } px-3 py-3`}
                                    style={{
                                      borderColor:
                                        themeMode === "dark" &&
                                        "#1b202f !important",
                                      backgroundColor:
                                        themeMode === "dark"
                                          ? "#151824"
                                          : "#f4f4f4",
                                    }}
                                    key={index}
                                  >
                                    {module}
                                  </div>
                                  <div className="col-md-10">
                                    <div className="row">
                                      {matchedPermissions.map((item, index) => {
                                        if (
                                          module === "Role" &&
                                          item.permission_name.includes(
                                            "RolePermission"
                                          )
                                        ) {
                                          return (
                                            <div
                                              className={`col-md-3 ${themeMode === "dark"
                                                  ? "border"
                                                  : "bDefault"
                                                } 
                                                                                        d-flex align-items-center justify-content-between`}
                                              style={{
                                                borderColor:
                                                  themeMode === "light" &&
                                                  "#dee2e6 !important",
                                              }}
                                              key={index}
                                            >
                                              <>
                                                <label
                                                  style={{ cursor: "pointer" }}
                                                  htmlFor={`checkbox-${index}`}
                                                  className="px-3 py-3"
                                                >
                                                  {item.permission_name}
                                                </label>
                                                {selectAll ||
                                                  existpermission.includes(
                                                    item._id
                                                  ) ? (
                                                  <div
                                                    style={{ float: "right" }}
                                                  >
                                                    <input
                                                      className="form-check-input a"
                                                      type="checkbox"
                                                      value={item._id}
                                                      checked={
                                                        existpermission.includes(
                                                          item._id
                                                        ) || selectAll
                                                      }
                                                      onChange={(e) =>
                                                        handlePermissionChange(
                                                          e
                                                        )
                                                      }
                                                      id={`checkbox-${index}`}
                                                    />
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{ float: "right" }}
                                                  >
                                                    <input
                                                      className="form-check-input b"
                                                      type="checkbox"
                                                      value={item._id}
                                                      checked={existpermission.includes(
                                                        item._id
                                                      )}
                                                      onChange={(e) =>
                                                        handlePermissionChange(
                                                          e
                                                        )
                                                      }
                                                      id={`checkbox-${index}`}
                                                    />
                                                  </div>
                                                )}
                                              </>
                                            </div>
                                          );
                                        } else if (
                                          module === "Permission" &&
                                          item.permission_name.includes(
                                            "RolePermission"
                                          )
                                        ) {
                                        } else {
                                          return (
                                            <div
                                              className={`col-md-3 ${themeMode === "dark"
                                                  ? "border"
                                                  : "bDefault"
                                                } 
                                                                                        d-flex align-items-center justify-content-between`}
                                              style={{
                                                borderColor:
                                                  themeMode === "light" &&
                                                  "#dee2e6 !important",
                                              }}
                                              key={index}
                                            >
                                              <label
                                                style={{ cursor: "pointer" }}
                                                htmlFor={`checkbox-${index}`}
                                                className="px-3 py-3"
                                              >
                                                {item.permission_name}
                                              </label>
                                              {selectAll ||
                                                existpermission.includes(
                                                  item._id
                                                ) ? (
                                                <div style={{ float: "right" }}>
                                                  <input
                                                    className="form-check-input a"
                                                    type="checkbox"
                                                    value={item._id}
                                                    checked={
                                                      existpermission.includes(
                                                        item._id
                                                      ) || selectAll
                                                    }
                                                    onChange={(e) =>
                                                      handlePermissionChange(e)
                                                    }
                                                    id={`checkbox-${index}`}
                                                  />
                                                </div>
                                              ) : (
                                                <div style={{ float: "right" }}>
                                                  <input
                                                    className="form-check-input b"
                                                    type="checkbox"
                                                    value={item._id}
                                                    checked={existpermission.includes(
                                                      item._id
                                                    )}
                                                    onChange={(e) =>
                                                      handlePermissionChange(e)
                                                    }
                                                    id={`checkbox-${index}`}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="text-center">
                      <Button
                        type="cancel"
                        variant="danger me-2"
                        onClick={() => navigate("/role")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={loading}
                        variant="primary"
                      >
                        {loading ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <ToastContainerWrapper />
      </Row>
    </>
  );
};
export default RolePermission;
