import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";

const TimeEntryRequestList = ({ api, user_data }) => {

    const Permissions = useContext(PermissionData);

    const [entryRequestData, setEntryRequestData] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [pageNumber, setPageNumber] = useState(0);
    const [paginationEntry, setPaginationEntry] = useState(10);
    const [dataperPage, setDataperPage] = useState(paginationEntry);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [acceptid, setAcceptid] = useState("");
    const [rejectid, setRejectid] = useState("");
    const [acceptshow, setAcceptshow] = useState(false);
    const [rejectshow, setRejectshow] = useState(false);

    const Entrydatalist = async () => {
        setLoading(true);
        await api.get(`/timeEntryRequestListing`).then((response) => {
            if (response.data !== undefined) {
                if (user_data.roleData[0].role_name === "Admin") {
                    setEntryRequestData(response.data.timeEntryRequestData.reverse());
                } else {
                    setEntryRequestData(
                        response.data.userTimeEntryRequestData.reverse()
                    );
                }
            }
            setLoading(false);
        }).catch((error) => {
            console.log("error", error)
        });
    };

    useEffect(() => {
        Entrydatalist();
        return () => { };
    }, []);

    const pageVisited = pageNumber * dataperPage;
    const pageCount = Math.ceil(
        (searchData.length > 0 ? searchData.length : entryRequestData.length) /
        dataperPage
    );

    const PageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const perpagedata = (e) => {
        setDataperPage(e.target.value);
    };

    const sorting = (col) => {
        if (order === "ASC") {
            const sorted = [...entryRequestData].sort((a, b) =>
                (
                    a.userData[0][col] ||
                    a.projectData[0][col] ||
                    a.taskData[0][col] ||
                    a.hours ||
                    a.date
                ).toLowerCase() >
                    (
                        b.userData[0][col] ||
                        b.projectData[0][col] ||
                        b.taskData[0][col] ||
                        b.hours ||
                        b.date
                    ).toLowerCase()
                    ? 1
                    : -1
            );
            setEntryRequestData(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...entryRequestData].sort((a, b) =>
                (
                    a.userData[0][col] ||
                    a.projectData[0][col] ||
                    a.taskData[0][col] ||
                    a.hours ||
                    a.date
                ).toLowerCase() <
                    (
                        b.userData[0][col] ||
                        b.projectData[0][col] ||
                        b.taskData[0][col] ||
                        b.hours ||
                        b.date
                    ).toLowerCase()
                    ? 1
                    : -1
            );
            setEntryRequestData(sorted);
            setOrder("ASC");
        }
    };

    const handleAcceptClose = () => setAcceptshow(false);
    const handleRejectClose = () => setRejectshow(false);

    const AcceptModel = (id) => {
        setAcceptid(id);
        setAcceptshow(true);
    };

    const RejectModel = (id) => {
        setRejectid(id);
        setRejectshow(true);
    };

    const HandleAccept = async () => {
        const id = acceptid;
        if (id !== undefined) {
            await api.post(`/approveTimeEntryRequest/${id}`, "")
                .then(() => {
                    setAcceptshow(false);
                    Entrydatalist();
                })
                .catch((error) => {
                    console.log("error", error)
                });
        }
    };
    const HandleReject = async () => {
        const id = rejectid;
        if (id !== undefined) {
            await api.post(`/rejectTimeEntryRequest/${id}`, "").then(() => {
                setRejectshow(false);
                Entrydatalist();
            }).catch((error) => {
                console.log("error", error)
            });
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Modal
                        show={acceptshow}
                        onHide={handleAcceptClose}
                        className="text-center"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title style={{ marginLeft: "5rem" }}>
                                Do you want to Approve it!
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button className="me-2" variant="primary" onClick={() => HandleAccept()}>
                                Approve
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => handleAcceptClose()}
                                value="Cancel"
                            >
                                Cancel
                            </Button>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={rejectshow}
                        onHide={handleRejectClose}
                        className="text-center"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title style={{ marginLeft: "5rem" }}>
                                Do you want to Reject it!
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button className='me-2' variant="primary" onClick={() => HandleReject()}>
                                Reject
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => handleRejectClose()}
                                value="Cancel"
                            >
                                Cancel
                            </Button>
                        </Modal.Body>
                    </Modal>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Time Entry Date Request</h4>
                                </div>
                                <div className="entryRequestData-button d-flex justify-content-end">
                                    <div className="Add-Entries me-2">
                                        <Link to="request">

                                            <h4 className="btn btn-primary">
                                                <i
                                                    className="fa-solid fa-calendar-days"
                                                    style={{ marginRight: "7px" }}
                                                ></i>
                                                Date Request
                                            </h4>
                                        </Link>
                                    </div>
                                </div>
                            </Card.Header>

                            <Card.Header className={`d-flex justify-content-between`}>
                                <div className="row align-items-center w-100">
                                    <div className="col-lg-6 col-md-12">
                                        <div
                                            className="dataTables_length d-flex align-items-center"
                                            id="user-list-table_length"
                                            style={{ gap: "2%" }}
                                        >
                                            <label>Show</label>
                                            <select
                                                name="user-list-table_length"
                                                aria-controls="user-list-table"
                                                className="form-select form-select-sm w-25 emp-filter"
                                                onChange={(e) => perpagedata(e)}
                                                defaultValue={paginationEntry}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <table
                                        id="user-list-table"
                                        className="table table-striped"
                                        role="grid"
                                        data-toggle="data-table"
                                    >
                                        <thead>
                                            <tr className="light">
                                                <th>No</th>
                                                {user_data.roleData[0].role_name === "Admin" && (
                                                    <th
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => sorting("firstname")}
                                                    >
                                                        <div className=" d-flex ">
                                                            <span className="title">UserName</span>
                                                            <div className="sorting-icon justify-content-center align-items-center">
                                                                <i
                                                                    className={`fa-sharp fa-solid fa-sort-up position-relative ${order === "ASC" ? "opacity-50" : ""
                                                                        }`}
                                                                    style={{ left: "50%" }}
                                                                ></i>
                                                                <i
                                                                    className={`fa-sharp fa-solid fa-sort-down position-relative ${order === "DSC" ? "opacity-50" : ""
                                                                        }`}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                <th
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => sorting("start_date")}
                                                >
                                                    <div className=" d-flex ">
                                                        <span className="title">From</span>
                                                        <div className="sorting-icon justify-content-center align-items-center">
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-up position-relative ${order === "ASC" ? "opacity-50" : ""
                                                                    }`}
                                                                style={{ left: "50%" }}
                                                            ></i>
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-down position-relative ${order === "DSC" ? "opacity-50" : ""
                                                                    }`}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => sorting("end_date")}
                                                >
                                                    <div className=" d-flex ">
                                                        <span className="title">To</span>
                                                        <div className="sorting-icon justify-content-center align-items-center">
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-up position-relative ${order === "ASC" ? "opacity-50" : ""
                                                                    }`}
                                                                style={{ left: "50%" }}
                                                            ></i>
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-down position-relative ${order === "DSC" ? "opacity-50" : ""
                                                                    }`}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => sorting("status")}
                                                >
                                                    <div className=" d-flex ">
                                                        <span className="title">Status</span>
                                                        <div className="sorting-icon justify-content-center align-items-center">
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-up position-relative ${order === "ASC" ? "opacity-50" : ""
                                                                    }`}
                                                                style={{ left: "50%" }}
                                                            ></i>
                                                            <i
                                                                className={`fa-sharp fa-solid fa-sort-down position-relative ${order === "DSC" ? "opacity-50" : ""
                                                                    }`}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </th>
                                                {Permissions.includes(
                                                    "Accept Or Reject TimeEntryRequest"
                                                ) && <th min-width="100px">Action</th>}
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={20} className='text-center'>
                                                        <Spinner animation="border" variant="primary" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className="text-capitalize">
                                                {entryRequestData.length > 0 ? (
                                                    entryRequestData
                                                        .slice(pageVisited, +pageVisited + +dataperPage)
                                                        .slice(0)
                                                        .map((item, index) => (
                                                            <tr key={index} className="border-light">
                                                                <td>{pageVisited + index + 1}</td>
                                                                {user_data.roleData[0].role_name === "Admin" && (
                                                                    <td>{item.userData[0].firstname}</td>
                                                                )}
                                                                <td>
                                                                    {moment(item.start_date).format("DD/MM/YYYY")}
                                                                </td>
                                                                <td>
                                                                    {moment(item.end_date).format("DD/MM/YYYY")}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={`badge bg-${item.status === "0" ? "warning" : "success"
                                                                            } p-2 w-50`}
                                                                        style={{ fontWeight: "200" }}
                                                                    >
                                                                        {item.status === "0"
                                                                            ? "Pending"
                                                                            : item.status === "1"
                                                                                ? "Approved"
                                                                                : item.status === "2" && "Rejected"}
                                                                    </span>
                                                                </td>
                                                                {Permissions.includes(
                                                                    "Accept Or Reject TimeEntryRequest"
                                                                ) && (
                                                                        <td>
                                                                            <div className="flex align-items-center list-user-action">
                                                                                {item.status === "0" ? (
                                                                                    <>
                                                                                        <Link
                                                                                            onClick={() => AcceptModel(item._id)}
                                                                                            className="btn btn-sm btn-icon btn-success"
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="top"
                                                                                            title=""
                                                                                            data-original-title="Delete"
                                                                                            to="#"
                                                                                            style={{ marginRight: "5%" }}
                                                                                        >
                                                                                            <span className="btn-inner">
                                                                                                <svg
                                                                                                    width="20"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    stroke="currentColor"
                                                                                                >
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        clipRule="evenodd"
                                                                                                        d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                </svg>
                                                                                            </span>
                                                                                        </Link>
                                                                                        <Link
                                                                                            onClick={() => RejectModel(item._id)}
                                                                                            className="btn btn-sm btn-icon btn-danger"
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="top"
                                                                                            title=""
                                                                                            data-original-title="Delete"
                                                                                            to="#"
                                                                                        >
                                                                                            <span className="btn-inner">
                                                                                                <svg
                                                                                                    width="20"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    stroke="currentColor"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M14.3955 9.59497L9.60352 14.387"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M14.3971 14.3898L9.60107 9.59277"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        clipRule="evenodd"
                                                                                                        d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                </svg>
                                                                                            </span>
                                                                                        </Link>
                                                                                    </>
                                                                                ) : item.status === "1" ? (
                                                                                    <Link
                                                                                        className="btn btn-sm btn-icon btn-success"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="top"
                                                                                        title=""
                                                                                        data-original-title="Delete"
                                                                                        to="#"
                                                                                        style={{ marginLeft: "13%" }}
                                                                                    >
                                                                                        <span className="btn-inner">
                                                                                            <svg
                                                                                                width="20"
                                                                                                viewBox="0 0 24 24"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                stroke="currentColor"
                                                                                            >
                                                                                                <path
                                                                                                    fillRule="evenodd"
                                                                                                    clipRule="evenodd"
                                                                                                    d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                />
                                                                                                <path
                                                                                                    d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </Link>
                                                                                ) : (
                                                                                    item.status === "2" && (
                                                                                        <Link
                                                                                            className="btn btn-sm btn-icon btn-danger"
                                                                                            data-toggle="tooltip"
                                                                                            data-placement="top"
                                                                                            title=""
                                                                                            data-original-title="Delete"
                                                                                            to="#"
                                                                                            style={{ marginLeft: "13%" }}
                                                                                        >
                                                                                            <span className="btn-inner">
                                                                                                <svg
                                                                                                    width="20"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    stroke="currentColor"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M14.3955 9.59497L9.60352 14.387"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M14.3971 14.3898L9.60107 9.59277"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        clipRule="evenodd"
                                                                                                        d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                                                                                        stroke="currentColor"
                                                                                                        strokeWidth="1.5"
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                    />
                                                                                                </svg>
                                                                                            </span>
                                                                                        </Link>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td className="text-center" colSpan={9}>
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="row align-items-center w-100 mt-2">
                                    <div className="col-md-6">
                                        <div
                                            className="dataTables_length d-flex align-items-center mx-3"
                                            id="user-list-table_length"
                                        >
                                            <label className="me-1">
                                                Total
                                            </label>
                                            {searchData.length > 0
                                                ? searchData.length
                                                : entryRequestData.length}
                                            <label className="ms-1">
                                                entries
                                            </label>
                                        </div>
                                    </div>

                                    <>
                                        <div className="col-md-6">
                                            <div
                                                id="user-list-table_filter"
                                                className="dataTables_filter d-flex align-items-center justify-content-end"
                                                style={{
                                                    gap: "2%",
                                                    position: "relative",
                                                    right: "30%",
                                                }}
                                            >
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers"
                                                    id="user-list-table_paginate"
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount}
                                                        onPageChange={PageChange}
                                                        containerClassName={"pagination align-items-center"}
                                                        previousClassName={
                                                            "paginate_button page-item previous"
                                                        }
                                                        previousLinkClassName={"page-link border-0"}
                                                        nextLinkClassName={"page-link border-0"}
                                                        nextClassName={
                                                            "paginate_button page-item next border-none"
                                                        }
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        breakLabel="..."
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default TimeEntryRequestList;
