import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Card from '../../components/Card';

function TimeEntryRequest({ api }) {
    const userdata = useSelector((state) => state.user);

    const [reason, setReason] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [next_date, setNext_date] = useState('');
    const [validated, setValidated] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        if (dateFrom !== '') {
            const startDate = new Date(dateFrom);
            startDate.setDate(startDate.getDate());
            const nextDate = startDate.toISOString().substr(0, 10);
            setNext_date(nextDate);
        }
    }, [dateFrom, dateTo]);

    const LeaveDateRequest = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when API call starts
        const form = e.currentTarget;
        const isValid = form.checkValidity();

        if (isValid) {
            await api.post(
                `/timeEntryRequest`,
                {
                    reason: reason,
                    start_date: dateFrom,
                    end_date: dateTo,
                }
            )
                .then((response) => {
                    if (response.data.date_status === 0) {
                        setInvalidDate(true);
                    } else {
                        navigate('/time-entry-date-request');
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    setLoading(false); // Set loading to false when API call is complete
                });
        } else {
            setValidated(true);
            setLoading(false); // Set loading to false in case of form validation failure
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Time Entry Date Request</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    onSubmit={(e) => LeaveDateRequest(e)}
                                    noValidate
                                    validated={validated}
                                >
                                    <Row>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Reason</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="title"
                                                    onChange={(e) => setReason(e.target.value)}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">From</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    id="title"
                                                    onChange={(e) => setDateFrom(e.target.value)}
                                                    required
                                                    max={new Date(
                                                        Date.now() - 3 * 86400000
                                                    )
                                                        .toISOString()
                                                        .split('T')[0]}
                                                    isInvalid={invalidDate}
                                                    style={
                                                        invalidDate
                                                            ? { backgroundImage: 'none' }
                                                            : {}
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {invalidDate
                                                        ? 'Invalid date'
                                                        : 'This field is required'}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">To</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    id="project_description"
                                                    onChange={(e) => setDateTo(e.target.value)}
                                                    required
                                                    disabled={dateFrom ? false : true}
                                                    max={new Date(
                                                        Date.now() - 3 * 86400000
                                                    )
                                                        .toISOString()
                                                        .split('T')[0]}
                                                    min={next_date}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Button
                                                type="submit"
                                                variant="btn btn-primary me-2"
                                                disabled={loading} // Disable the button when loading
                                            >
                                                {loading ? 'Submitting...' : 'Submit'}
                                            </Button>{' '}
                                            <Button
                                                type="button"
                                                variant="btn btn-danger"
                                                onClick={() =>
                                                    navigate('/time-entry-date-request')
                                                }
                                            >
                                                Cancel
                                            </Button>{' '}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default TimeEntryRequest;
