import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";

const ThemeSetting = ({ api, imagePath }) => {

  const [settingData, setSettingData] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);

  const setting = () => {
    api.get(`/settingListing`).then((response) => {
      if (response.data !== undefined) {
        setSettingData(response.data.settingData);
      }
    }).catch((error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    setting();
    return () => { };
  }, []);

  const Handledelete = () => {
    const id = deleteid;
    if (id !== undefined) {
      api.post(`/deleteSetting/${id}`, "").then(() => {
        setShow(false);
        setting();
      }).catch((error) => {
        console.log("error", error)
      });
    }
  };

  const handleClose = () => setShow(false);
  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button className="me-2" variant="primary" onClick={() => Handledelete()}>
                Delete
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>

          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Setting</h4>
                </div>
                <div className="Add-user">
                  <Link to="add">

                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      New Setting
                    </h4>
                  </Link>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>No</th>
                        <th>Key</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-capitalize">
                      {settingData.map((item, index) => (
                        <tr key={index} className="border-light">
                          <td>{index + 1}</td>
                          <td>{item.key}</td>
                          <td>{item.type}</td>
                          <td>
                            {item.type === "image" ? (
                              <img
                                src={imagePath + item.value}
                                alt=""
                                width={item.key === "logo" ? 100 : 50}
                              />
                            ) : (
                              <span> {item.value}</span>
                            )}
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="btn btn-sm btn-icon btn-warning me-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                to={`edit/${item._id}`}
                              >
                                <span className="btn-inner">
                                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                  </svg>
                                </span>
                              </Link>
                              <Link
                                onClick={() => deletemodel(item._id)}
                                className="btn btn-sm btn-icon btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Delete"
                                to="#"
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="currentColor"
                                  >
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ThemeSetting;
