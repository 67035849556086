import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/customAlert.css";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast } from "../../plugins/toastUtils";

const RoleList = ({ api }) => {
  const Permissions = useContext(PermissionData);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [entry] = useState(10);
  const [dataperPage, setDataperPage] = useState(entry);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [totalData, setTotalData] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  let lastRoleData = roles.slice(-1);

  const roledata = () => {
    api.get(
      `/roles?search=${searchValue}&page=${page}&limit=${dataperPage}&nameSort=${order}`
    ).then((response) => {
      if (response.data.status === false) {
        navigate("/403");
      }
      if (response.data !== undefined) {
        setLoading(false);
        setTotalPages(response.data.totalPages);
        setTotalData(response.data.totalData);
        setRoles(response.data.roleData);
      }
    }).catch((error) => {
      if (error.response.status == 403) {
        navigate("/403");
      }
      console.log("error", error);
    });
  };

  const Handledelete = () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      api.post(`/deleteRole/${id}`, "").then((response) => {
        setShow(false);
        showToast(response.data.message, "success");
        roledata();
      }).catch((error) => {
        showToast(error.response.data.errors, "error");
        setShow(false);
        setLoading(false);
      });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      roledata();
    }
  };

  useEffect(() => {
    if (!searchValue && search) {
      roledata();
    }
    return () => { };
  }, [searchValue]);

  useEffect(() => {
    roledata();
  }, [page, dataperPage, order]);

  return (
    <>
      <Row>
        <Modal show={show} onHide={handleClose} className="text-center">
          <Modal.Header closeButton>
            <Modal.Title style={{ marginLeft: "5rem" }}>
              Do you want to delete it ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              disabled={loading}
              className="me-2"
              onClick={() => Handledelete()}
            >
              {loading ? "Deleting..." : "Delete"}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleClose()}
              value="Cancel"
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>

        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Roles</h4>
              </div>
              {Permissions.includes("Add Role") && (
                <div className="Add-Permission">
                  <Link to="add">
                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      New Role
                    </h4>
                  </Link>
                </div>
              )}
            </Card.Header>

            <Card.Header className={`d-flex justify-content-between`}>
              <div className="row align-items-center w-100">
                <div className="col-md-6">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Show</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-25"
                      onChange={(e) => setPerPage(e.target.value)}
                      defaultValue={entry}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    id="user-list-table_filter"
                    className="dataTables_filter d-flex align-items-center justify-content-end"
                    style={{ gap: "2%" }}
                  >
                    <label>Search:</label>
                    <input
                      type="search"
                      className="form-control form-control-sm w-50"
                      placeholder=""
                      aria-controls="user-list-table"
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                      onKeyDown={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="user-list-table"
                  className="table table-striped"
                  role="grid"
                  data-toggle="data-table"
                >
                  <thead>
                    <tr className="ligth">
                      <th>No</th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => sorting("role_name")}
                      >
                        <span className="title">Name</span>
                      </th>
                      <th style={{ cursor: "pointer" }}>
                        <span className="title">Description</span>
                      </th>
                      {(Permissions.includes("Update Role") ||
                        Permissions.includes("Delete Role")) && (
                          <th min-width="100px">Action</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={99} className="text-center">
                          <Spinner
                            animation="border"
                            variant="primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    ) : roles.length == 0 ? (
                      <tr>
                        <td colSpan="20" className="text-center">
                          No Search results found
                        </td>
                      </tr>
                    ) : roles?.length > 0 ? (
                      roles.map((item, index) => (
                        <tr key={index} className="border-light">
                          <td>{item.index}</td>
                          <td>{item.role_name}</td>
                          <td>{item.role_description}</td>
                          {(Permissions.includes("Update Role") ||
                            Permissions.includes("Delete Role")) && (
                              <td>
                                <div>
                                  {Permissions.includes("Add RolePermission") && (
                                    <Link
                                      className="btn btn-sm btn-icon btn-success"
                                      style={{ marginRight: "5px" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      to={`role-permission/${item._id}/${item.role_name}`}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                  {Permissions.includes("Update Role") && (
                                    <Link
                                      className="btn btn-sm btn-icon btn-warning"
                                      style={{ marginRight: "5px" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      to={`edit/${item._id}`}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M15.1655 4.60254L19.7315 9.16854"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                  {Permissions.includes("Delete Role") && (
                                    <Link
                                      onClick={() => deletemodel(item._id)}
                                      className="btn btn-sm btn-icon btn-danger"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      to="#"
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="currentColor"
                                        >
                                          <path
                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M20.708 6.23975H3.75"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                </div>
                              </td>
                            )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row align-items-center w-100 mt-2 p-0 m-0">
                <div className="col-md-6">
                  <div
                    className="dataTables_length d-flex align-items-center mx-3"
                    id="user-list-table_length"
                  >
                    {roles?.length > 0 && (
                      <label>
                        Showing {roles[0]?.index} to {lastRoleData[0]?.index} of
                        {totalData} entries
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <ReactPaginate
                    nextLabel="Next"
                    onPageChange={PageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination float-end"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <ToastContainerWrapper />
      </Row>
    </>
  );
};

export default RoleList;
