import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

const AssesmbleComponent = ({ data, ramOptions, formik }) => {
  const renderDataUi = () => {
    if (data?.key === 'CPU') {

      return (
        <>
          <div className="row">
            {ramOptions.map((obj, index) => (
              <div key={index} className="col-md-6">
                {Object.keys(obj).map((key, innerIndex) => (
                  <div key={innerIndex}>
                    <label style={{ marginBottom: '0px' }} htmlFor={`cpu_item_${index}_${innerIndex}`}>
                      {key} :-
                    </label>
                    <Select
                      isMulti
                      options={obj[key].map((data) => ({ value: data._id, label: data.value }))}
                      onChange={(selected) => {
                        formik.setFieldValue(`selectedOptions.${key}`, selected);
                        const selectedIds = (selected || []).map((option) => option.value);
                        formik.setFieldValue(`selectedOptionsID.${key}`, selectedIds);
                      }}
                      value={formik.values.selectedOptions[key] || []}
                      id={`cpu_item_${index}_${innerIndex}`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {data && (
        <>
          <div key={data?._id} className="d-flex flex-wrap align-items-center" style={{ gap: '20px' }}>
            <div>
              <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                {data?.key} Name :-
              </Form.Label>
              <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                <Form.Control
                  type="text"
                  id="cpu_item"
                  value={formik.values.Name}
                  onChange={(e) => formik.setFieldValue('Name', e.target.value)}
                  required
                  isInvalid={formik.touched.Name && formik.errors.Name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.touched.Name && formik.errors.Name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div>
              <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                {data?.key} description :-
              </Form.Label>
              <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                <Form.Control
                  type="text"
                  id="cpu_item"
                  value={formik.values.Description}
                  onChange={(e) => formik.setFieldValue('Description', e.target.value)}
                  required
                  isInvalid={formik.touched.Description && formik.errors.Description}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.touched.Description && formik.errors.Description}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div>
              <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                {data?.key} unique ID :-
              </Form.Label>
              <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                <Form.Control
                  type="text"
                  id="cpu_item"
                  value={formik.values.UniqueID}
                  onChange={(e) => formik.setFieldValue('UniqueID', e.target.value)}
                  required
                  isInvalid={formik.touched.UniqueID && formik.errors.UniqueID}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.touched.UniqueID && formik.errors.UniqueID}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          {renderDataUi(data)}
        </>
      )}
    </>
  );
};

const AddMasterItem = ({ api }) => {

  const navigate = useNavigate();
  const [ramOptions, setRamOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      selectedInventoryItem: null,
      inventoryItems: [],
      Name: '',
      Description: '',
      UniqueID: '',
      selectedOptions: {},
      selectedOptionsID: {},
      main_key: ''
    },

    validationSchema: Yup.object().shape({
      Name: Yup.string().required('Name is required'),
      Description: Yup.string().required('Description is required'),
      UniqueID: Yup.string().required('Unique ID is required'),
    }),

    onSubmit: async (values) => {

      const selectedCPUData = values?.selectedOptionsID;
      let allIds = [];

      for (let key in selectedCPUData) {
        allIds = allIds.concat(selectedCPUData[key]);
      }

      const payload = {
        inventoryItemID: values?.selectedInventoryItem?._id,
        name: values.Name,
        description: values.Description,
        uniqueID: values.UniqueID,
        ...(values?.selectedInventoryItem?.key === 'CPU' && {
          cpu_data: allIds,
        }),
        main_key: values.selectedInventoryItem.key
      }
      setLoading(true);
      const response = await api.post(
        `/addInventoryItem`, {
        payload
      }
      );
      setLoading(false);
      navigate('/master-item');
      setTimeout(() => {
        showToast(response.data.message, "success")
      }, 300);
      formik.setFieldValue('Name', "");
      formik.setFieldValue('Description', "");
      formik.setFieldValue('UniqueID', "");
      formik.setFieldValue('selectedOptions', {});
      formik.setFieldValue('selectedOptionsID', {});
      formik.setFieldValue('main_key', "");
    },
  });

  useEffect(() => {
    api.get(`/inventoryMaster`).then((response) => {
      if (response.data !== undefined) {
        if (response.data.status === false) {
          navigate('/403');
        } else {
          const inventoryData = response.data.inventory || [];
          formik.setFieldValue('inventoryItems', inventoryData);
          api.get(`/getcpuData`).then((cpuResponse) => {
            if (cpuResponse.data !== undefined) {
              if (cpuResponse.data.status === false) {
                navigate('/403');
              } else {
                const cpuData = cpuResponse.data.cpuData || [];
                setRamOptions(cpuData);
              }
            }
          }).catch((error) => {
            console.error('Error fetching CPU data:', error);
            showToast(error.response.data.errors,'error');
          });
        }
      }
    }).catch((error) => {
      console.error('Error fetching inventory items:', error);
    });
  }, []);

  const handleInventoryChange = (e) => {
    const selectedItemId = e.target.value;
    const selectedInventoryItem = formik.values.inventoryItems.find((item) => item._id === selectedItemId);
    formik.setFieldValue('selectedInventoryItem', selectedInventoryItem);
    formik.setFieldValue('Name', "");
    formik.setFieldValue('Description', "");
    formik.setFieldValue('UniqueID', "");
    formik.setFieldValue('selectedOptions', {});
    formik.setFieldValue('selectedOptionsID', {});
    formik.setFieldValue('main_key', "");
  };

  return (
    <Row>
      <Col sm="12" lg="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Select inventory item from master inventories</h4>
            </div>
            <div>
              <Form onSubmit={formik.handleSubmit}>
                <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                  {loading ? 'Submitting...' : 'Add inventory item'}
                </Button>
                <Button type="button" variant="btn btn-danger" onClick={() => navigate('/master-item')}>
                  Cancel
                </Button>
              </Form>
            </div>
          </Card.Header>
          <Card.Body>
            <Row className="mb-4">
              <Col md={6}>
                <h6 className="mb-1">Select Inventory Item:</h6>
                <div className="edit-data w-75">
                  <select
                    className="form-select"
                    name="selectedInventoryItem"
                    value={formik.values.selectedInventoryItem ? formik.values.selectedInventoryItem._id : ''}
                    onChange={handleInventoryChange}
                  >
                    <option value="" disabled>
                      Select an Inventory
                    </option>
                    {formik.values.inventoryItems.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.key}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            {formik?.values?.selectedInventoryItem &&
              <Row className="mb-4">
                <Col md={6}>
                  <h6 className="mb-1">Create Inventories :</h6>
                </Col>
              </Row>
            }
            <div>
              <AssesmbleComponent
                data={formik?.values?.selectedInventoryItem}
                ramOptions={ramOptions}
                formik={formik}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <ToastContainerWrapper />
    </Row>
  );
};

export default AddMasterItem;
