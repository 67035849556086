import React, { useEffect } from 'react'
import { useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import './assets/css/cutomStyle.css'
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/hope-ui.scss"
import "./assets/scss/rtl.scss"
import Default from "./layouts/dashboard/default"
import ProtectedAuth from './router/ProtectedRouts/ProtectedAuth'
import ProtectedRoutes from './router/ProtectedRouts/ProtectedRoutes'
import ForgotPassword from "./views/Employee/ForgotPass"
import ForgotPasswordChange from './views/Employee/ForgotPasswordChange'
import SetPassword from './views/Employee/SetPassword'
import ConfirmMail from "./views/dashboard/auth/confirm-mail"
import SignIn from "./views/dashboard/auth/sign-in"
import Error403 from './views/dashboard/errors/error403'

function App() {
  const api = process.env.REACT_APP_API_KEY;
  const data = useSelector((state) => state.user.user);
  const user_id = data._id;
  const token = data.token;
  const role_id = data.role_id;

  useEffect(() => {
    const preventRightClick = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', preventRightClick);
    return () => document.removeEventListener('contextmenu', preventRightClick);
  }, []);
  console.log("Testing of git branch")
  return (

    <div className="App">
      <Routes>
        <Route element={<ProtectedAuth />}>
          <Route path='/login' exact element={<SignIn />} />
        </Route>
        <Route path='/403' exact element={<Error403 />} />
        <Route path='/forgot-password' exact element={<ForgotPassword api={api} />} />
        <Route path='/forgot-password-change/:id/:token' exact element={<ForgotPasswordChange api={api} />} />
        <Route path='/active-account/:id' exact element={<SetPassword api={api} />} />
        <Route path='/confirm-mail/:email' exact element={<ConfirmMail />} />

        <Route element={<ProtectedRoutes />}>
          <Route path='*' exact element={<Default user_id={user_id} token={token} role_id={role_id} api={api} />} />
        </Route>
      </Routes>
    </div>
  );

}

export default App;
