
import React from 'react';
import { useParams } from 'react-router-dom';
import AdvanceRP from './AdvanceRP';

const RolePermission = ({ api }) => {
    const { id, role_name } = useParams();
    return (
        <>
            <AdvanceRP api={api} id={id} role_name={role_name} />
        </>
    )
}

export default RolePermission;