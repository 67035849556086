import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'
import moment from 'moment'
import SvgTick from '../../components/SvgTick'
import SvgCross from '../../components/SvgCross'

const PunchingList = ({ api, user_data, showToast }) => {

    const Permissions = useContext(PermissionData);
    const themeData = useSelector((state) => state.mode.colorcustomizer);
    const [loading, setLoading] = useState(false);
    const [totalColor, setTotalColor] = useState('');
    const [workingHourData, setWorkingHourData] = useState([]);
    const [breakHourData, setBreakHourData] = useState([]);
    const [totalWrokingHour, setTotalWrokingHour] = useState('');
    const [totalBreakHour, setTotalBreakHour] = useState('');
    const [userId, setUserId] = useState('');
    const [userDetail, setUserDetail] = useState('');
    const [date, setDate] = useState('');
    const [month, setMonth] = useState('');
    const [cancelToken, setCancelToken] = useState(null);
    const [show, setShow] = useState(false);
    const [deleteid, setDeleteid] = useState('');
    const [userList, setUserList] = useState([]);
    const [average, setAverage] = useState(0);
    const [error, setError] = useState('');
    const [showAddButton, setShowAddButton] = useState(null);
    const [showEditButton, setShowEditButton] = useState(null);
    const [showInput, setShowInput] = useState(null);
    const [empUserIds, setEmpUserIds] = useState([]);
    const [updatedTime, setUpdatedTime] = useState(null);
    const [addInputField, setAddInputField] = useState(null);

    useEffect(() => {
        if (themeData === 'theme-color-default') {
            setTotalColor('#2e46ba');
        }
        else if (themeData === 'theme-color-blue') {
            setTotalColor('#009cc7');
        }
        else if (themeData === 'theme-color-gray') {
            setTotalColor('#91969e');
        }
        else if (themeData === 'theme-color-red') {
            setTotalColor('#af424f');
        }
        else if (themeData === 'theme-color-yellow') {
            setTotalColor('#bb550e');
        }
        else if (themeData === 'theme-color-pink') {
            setTotalColor('#b76b8f');
        }
        return () => { };
    }, [themeData]);


    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const maxDate = moment().subtract(1, 'day').toISOString().split('T')[0];

        if (selectedDate > maxDate) {
            setError('Future dates are not allowed!');
            setDate(maxDate); // Clear the invalid date
        } else {
            setError('');
            setDate(selectedDate);
        }
    };


    const setTimeAndDate = () => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();

        const todayDate = `${year}-${month}-${day}`;
        const isMonday = moment(todayDate).day() == 1
        const isSunday = moment(todayDate).day() == 0
        const subtractValue = isMonday ? 3 : isSunday ? 2 : 1
        let previosWorkingDay = moment(todayDate).subtract(subtractValue, 'day').format('yyyy-MM-DD')
        setDate(previosWorkingDay)
        setMonth(month)
    }

    const WorkingHourListing = async ({ id, date }) => {
        try {
            setLoading(true)
            if (cancelToken) {
                cancelToken.cancel('New request initiated');
            }
            const source = axios.CancelToken.source();
            setCancelToken(source);
            if (!(id && date)) return;
            const response = await api.get(`/punches/${id}?date=${date}`);

            if (response.data !== undefined) {
                setAverage(response.data.totalAverage)
                const result = [];

                for (let i = 0; i < response.data.punches.length; i += 2) {
                    result.push({ checkIn: response.data.punches[i], checkOut: response.data.punches[i + 1] });
                }

                setWorkingHourData(result);
                setBreakHourData(response.data.breaks);
                setTotalWrokingHour(response.data.totalWorkingHours);
                setTotalBreakHour(response.data.totalBreakTime);
            }
        } catch (error) {
            setWorkingHourData([]);
            setBreakHourData([]);
            setTotalWrokingHour([]);
            setTotalBreakHour([]);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTimeAndDate();
        api.get('/punchEmployee')
            .then(res => setEmpUserIds(res.data.map(item => (
                {
                    emp_code: item.emp_code,
                    _id: item._id,
                    firstname: item.firstname,
                    last_name: item.last_name
                }
            ))))
            .catch((err) => setEmpUserIds([user_data]))
        setUserId(user_data._id)
    }, []);

    useEffect(() => {
        WorkingHourListing({ date, id: userId });
    }, [date, userId]);

    const Handledelete = () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            api.delete(`/deletePunch/${id}`)
                .then(() => {
                    setShow(false);
                    WorkingHourListing({ date, id: userId });
                }).catch((error) => {
                    setLoading(false);
                    console.log("error", error)
                });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    function deletemodel(id) {
        setDeleteid(id);
        setShow(true);
    }
    const formatTime = (time) => (moment(time).isValid() ? moment(time).format('hh:mm A') : '-');
    const formatTime24H = (time) => (moment(time).isValid() ? moment(time).format('HH:mm') : '-');

    const formatPunchingHours = async (id, hours) => {
        try {
            const dateTimeString = `${date} ${hours}`
            const dateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm').format()
            setUpdatedTime({ id, dateTime })
        } catch (error) {
            showToast(error.message, 'error')
        }
    }
    const updatePunchingHours = async () => {
        try {
            if (!Object.keys(updatedTime).length) throw new Error('Something went wrong! Please try again!')
            if (updatedTime.id == null && updatedTime.dateTime) {
                await api.post(`/addPunch/${userId}`, { punch_time: updatedTime.dateTime })
                showToast('Hour Added', 'success')
                setAddInputField(null)
            } else {
                await api.put(`/updatePunches/${updatedTime.id}`, { punch_time: updatedTime.dateTime })
                showToast('Hour updated', 'success')
                setUpdatedTime(null)
            }
            WorkingHourListing({ date, id: userId })
        } catch (error) {
            showToast(error.message, 'error')
        } finally {
            setShowInput(null)
            setShowAddButton(null)
            setAddInputField(null)
        }
    }
    const cancelupdateHours = async () => {
        setShowInput(null)
        setUpdatedTime(null)
        setAddInputField(null)
    }



    const calculateDuration = (checkIn, checkOut) => {
        if (!moment(checkIn).isValid() || !moment(checkOut).isValid()) return '-';

        const hours = moment(checkOut).diff(moment(checkIn), 'hours');
        const minutes = moment(checkOut).diff(moment(checkIn), 'minutes') % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    return (
        <>
            <Row>
                <Modal show={show} onHide={handleClose} className='text-center'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="primary" className='me-2' disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                        <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>
                    </Modal.Body>
                </Modal>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Working hour</h4>
                            </div>
                        </Card.Header>

                        <Card.Header className={`d-flex justify-content-between`}>
                            <div className="row align-items-center w-100">
                                <Col sm="12" lg="3">
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="permission_description">Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            id="punching"
                                            value={date}
                                            onChange={handleDateChange}
                                            required
                                            max={moment().subtract(1, 'day').toISOString().split('T')[0]}
                                        />
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </Form.Group>
                                </Col>
                                {(Permissions.includes("View Punching")) && (
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="permission_description">Employee</Form.Label>
                                            <select
                                                name="user-list-table_length"
                                                aria-controls="user-list-table"
                                                className="form-select form-select"
                                                value={userId}
                                                onChange={(e) => setUserId(e.target.value)} >
                                                {empUserIds && empUserIds.map((item) => (
                                                    <option value={item._id} key={item._id}>
                                                        {item.firstname + ' ' + item.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                )}

                                {/* <Col sm="12" lg="3" className='h-100'>
                                    <Form.Label>Employee average </Form.Label>
                                    <h4 className='mt-2'>{average}</h4>
                                </Col> */}
                            </div>
                        </Card.Header>

                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                    <thead>
                                        <tr className="light">
                                            <th>
                                                <span className="title">Punch In</span>
                                            </th>
                                            <th>
                                                <span className="title">Punch Out</span>
                                            </th>
                                            <th>
                                                <span className="title">Total</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={20} className="text-center">
                                                    <Spinner
                                                        animation="border"
                                                        variant="primary"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) :
                                        (<tbody className='text-capitalize'>
                                            {workingHourData.length ?
                                                workingHourData.map((item, index) => {
                                                    const { checkIn, checkOut } = item;

                                                    const checkInTime = checkIn?.punch_time ? checkIn.punch_time : '-'
                                                    const checkOutTime = checkOut?.punch_time ? checkOut.punch_time : '-'

                                                    const hasAddPermission = Permissions.includes("Add Punching");
                                                    const hasEditPermission = Permissions.includes("Edit Punching");
                                                    const hasDeletePermission = Permissions.includes("Delete Punching");

                                                    const handleMouseEnter = (params, index) => {
                                                        if (typeof params == 'object' && !showAddButton && params?.id && params?.punch_time) {
                                                            if ((hasEditPermission || hasDeletePermission)) {
                                                                setShowEditButton(params.id);
                                                            }
                                                        } else if (typeof params == 'undefined' && hasAddPermission) {
                                                            setShowAddButton(index);
                                                        }
                                                    };

                                                    const handleMouseLeave = (params) => {
                                                        if (params?.id && (hasEditPermission || hasDeletePermission)) {
                                                            setShowEditButton(null);
                                                        } else if (hasAddPermission && !addInputField) {
                                                            setShowAddButton(null);
                                                        }
                                                    };
                                                    return (
                                                        <tr key={index} className="border-light">
                                                            <td className='position-relative'
                                                                onMouseEnter={() => handleMouseEnter(checkIn, `checkin${index}`)}
                                                                onMouseLeave={() => handleMouseLeave(checkIn)}>
                                                                {showInput == checkIn?.id && checkIn?.id ?
                                                                    <Form.Group className='d-flex'>
                                                                        <Form.Control
                                                                            type="time"
                                                                            id="check-in"
                                                                            defaultValue={formatTime24H(checkInTime)}
                                                                            onChange={(e) => formatPunchingHours(checkIn.id, e.target.value)}
                                                                            autoFocus
                                                                            className="w-25"
                                                                            required
                                                                        />
                                                                        <div className='d-flex gap-2 align-items-center'>
                                                                            <span
                                                                                onClick={() => updatePunchingHours()}
                                                                                className="btn-inner ms-2"
                                                                            >
                                                                                <SvgTick />
                                                                            </span>
                                                                            <span onClick={() => cancelupdateHours()} className="btn-inner text-danger">
                                                                                <SvgCross />
                                                                            </span>
                                                                        </div>
                                                                    </Form.Group>
                                                                    : formatTime(checkInTime)}
                                                                {showEditButton === checkIn?.id && !showInput ? (
                                                                    <div className='d-flex gap-2 position-absolute top-1 ms-5 ps-4'>
                                                                        {hasEditPermission &&
                                                                            <span
                                                                                onClick={() => setShowInput(checkIn.id)}
                                                                                className="btn-inner ms-2"
                                                                            >
                                                                                <svg
                                                                                    width="20"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M15.1655 4.60254L19.7315 9.16854"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            </span>}
                                                                        {hasDeletePermission &&
                                                                            <span onClick={() => deletemodel(checkIn?.id)} className="btn-inner text-danger">
                                                                                <svg
                                                                                    width="20"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <path
                                                                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                    <path
                                                                                        d="M20.708 6.23975H3.75"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                    <path
                                                                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>}
                                                                    </div>
                                                                ) : showAddButton == `checkin${index}` && !showInput && hasAddPermission && (
                                                                    <div className='d-flex gap-2 position-absolute top-1 ms-5 ps-4'>
                                                                        <span
                                                                            onClick={() => setShowInput(checkIn.id)}
                                                                            className="btn-inner ms-2"
                                                                        >
                                                                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="20" height="20" viewBox="0 0 24 24">
                                                                                <path d="M12.0001 8.32739V15.6537"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M15.6668 11.9904H8.3335"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z" stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                </path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="position-relative"
                                                                onMouseEnter={() => handleMouseEnter(checkOut, `checkout${index}`)}
                                                                onMouseLeave={() => handleMouseLeave(checkOut)}>

                                                                {(showInput === checkOut?.id && checkOut?.punch_time) ? (
                                                                    <Form.Group className='d-flex'>
                                                                        <Form.Control
                                                                            type="time"
                                                                            id="check-in"
                                                                            defaultValue={formatTime24H(checkOutTime)}
                                                                            onChange={(e) => formatPunchingHours(checkOut.id, e.target.value)}
                                                                            autoFocus
                                                                            className="w-25"
                                                                            required
                                                                        />
                                                                        <div className='d-flex gap-2 align-items-center'>
                                                                            <span
                                                                                onClick={() => updatePunchingHours()}
                                                                                className="btn-inner ms-2"
                                                                            >
                                                                                <SvgTick />
                                                                            </span>

                                                                            <span onClick={() => cancelupdateHours()} className="btn-inner text-danger">
                                                                                <SvgCross />
                                                                            </span>
                                                                        </div>

                                                                    </Form.Group>
                                                                ) : addInputField == `checkout${index}` ? (<Form.Group className='d-flex'>
                                                                    <Form.Control
                                                                        type="time"
                                                                        id="check-in"
                                                                        defaultValue={null}
                                                                        onChange={(e) => formatPunchingHours(null, e.target.value)}
                                                                        autoFocus
                                                                        className="w-25"
                                                                        required
                                                                    />
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <span
                                                                            onClick={() => updatePunchingHours()}
                                                                            className="btn-inner ms-2"
                                                                        >
                                                                            <SvgTick />
                                                                        </span>

                                                                        <span onClick={() => cancelupdateHours()} className="btn-inner text-danger">
                                                                            <SvgCross />
                                                                        </span>
                                                                    </div>

                                                                </Form.Group>) : (
                                                                    formatTime(checkOutTime)
                                                                )}

                                                                {showEditButton === checkOut?.id && !showInput ? (
                                                                    <div className='d-flex gap-2 position-absolute top-1 ms-5 ps-4'>
                                                                        {hasEditPermission &&
                                                                            <span
                                                                                onClick={() => setShowInput(checkOut.id)}
                                                                                className="btn-inner ms-2"
                                                                            >
                                                                                <svg
                                                                                    width="20"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M15.1655 4.60254L19.7315 9.16854"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            </span>}
                                                                        {hasDeletePermission &&
                                                                            <span onClick={() => deletemodel(checkOut?.id)} className="btn-inner text-danger">
                                                                                <svg
                                                                                    width="20"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <path
                                                                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                    <path
                                                                                        d="M20.708 6.23975H3.75"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                    <path
                                                                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>}
                                                                    </div>
                                                                ) : showAddButton == `checkout${index}` && (!showInput && !addInputField) && hasAddPermission && (
                                                                    <div className='d-flex gap-2 position-absolute top-1 ms-5 ps-4'>
                                                                        <span
                                                                            onClick={() => setAddInputField(`checkout${index}`)}
                                                                            className="btn-inner ms-2"
                                                                        >
                                                                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="22" height="22" viewBox="0 0 24 24">
                                                                                <path d="M12.0001 8.32739V15.6537"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M15.6668 11.9904H8.3335"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z" stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                </path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>{calculateDuration(checkInTime, checkOutTime)}</td>
                                                        </tr>
                                                    );
                                                }) :
                                                <tr>
                                                    <td colSpan={99} className='text-center'>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>)}

                                    <thead>
                                        <tr className="light">
                                            <th style={{ color: totalColor }}>Total</th>
                                            <th></th>
                                            <th style={{ color: totalColor }}>{totalWrokingHour}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Break Hours</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                    <thead>
                                        <tr className="light">
                                            <th>
                                                <span className="title">Break Start</span>
                                            </th>
                                            <th>
                                                <span className="title">Break End</span>
                                            </th>
                                            <th>
                                                <span className="title">Total</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-capitalize'>
                                        {breakHourData?.length > 0 ?
                                            breakHourData.map((item, index) => {
                                                const { break_start, break_end } = item;
                                                return (
                                                    <tr key={index} className="border-light">
                                                        <td>{formatTime(break_start)}</td>
                                                        <td>{formatTime(break_end)}</td>
                                                        <td>{calculateDuration(break_start, break_end)}</td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td colSpan={99} className='text-center'
                                                >No Data Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <thead>
                                        <tr className="light">
                                            <th style={{ color: totalColor }}>Total</th>
                                            <th></th>
                                            <th style={{ color: totalColor }}>{totalBreakHour}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col >
            </Row >
        </>
    )
}

export default PunchingList;