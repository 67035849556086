import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Card from '../../components/Card';
import './project.css';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'


function EditProject({ api }) {

    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [short_description, setShort_description] = useState('');
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [projectstatus, setProjectstatus] = useState('');
    const [projectType, setProjectType] = useState('');
    const [technologydata, setTechnologydata] = useState([]);
    const [projectemployee, setProjectemployee] = useState([]);
    const [technolgy, setTechnolgy] = useState([]);
    const [employee, setEmployee] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/editProject/${id}`).then((response) => {
            if (response.data !== undefined) {
                setTechnologydata(response.data.technologyname);
                setTechnolgy(response.data.existTechnologyname);
                setProjectemployee(response.data.userName);
                setEmployee(response.data.existUserName);
            }
            let otherdetail = response.data.projectData;
            if (otherdetail !== undefined) {
                setTitle(otherdetail.title);
                setShort_description(otherdetail.short_description);
                setStart_date(otherdetail.start_date)
                setEnd_date(otherdetail.end_date)
                setProjectstatus(otherdetail.status);
                setProjectType(otherdetail.project_type);
            }
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            console.log("error", error)
        });
        return () => {
            
        };
    }, []);

    const UpdateProject = async (e) => {
        e.preventDefault();
        let Tech = [];
        technolgy.map((item) => Tech.push(item.value));
        let EMP = [];
        employee.map((item) => EMP.push(item.value));
        await api.post(`/editProject/${id}`, {
            title: title,
            short_description: short_description,
            start_date: start_date,
            end_date: end_date,
            status: projectstatus,
            technology: Tech,
            project_type: projectType,
            user_id: EMP,
        }).then((response) => {
            setTimeout(() => {
                showToast(response.data.message, "success")
            }, 300);
            navigate('/project');
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            showToast(error.response.data.errors,'error');
        })
    }

    const projectStatusList = ['on Hold', 'in Progress', 'Completed']
    const projectTypeList = ['Fixed Cost', 'Dedicated', 'Hourly']

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Project</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>

                                <Form onSubmit={e => UpdateProject(e)}>
                                    <Row>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Project Title <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" id="title" value={title} onChange={e => setTitle(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">Project Description </Form.Label>
                                                <Form.Control type="text" id="project_description" value={short_description} onChange={e => setShort_description(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Start Date <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="date" id="title" value={start_date} onChange={e => setStart_date(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">End Date:</Form.Label>
                                                <Form.Control type="date" value={end_date} id="project_description" onChange={e => setEnd_date(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <div className="form-group">
                                                <Form.Label htmlFor="permission_description">Project Status <span className='text-danger'>*</span></Form.Label>
                                                <select name="" id="" className='form-select mb-3 shadow-none form-control' value={projectstatus} onChange={e => setProjectstatus(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {projectStatusList.length > 0 ?
                                                        projectStatusList.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>
                                                        ))
                                                        :
                                                        <option>No data found</option>
                                                    }
                                                </select>
                                            </div>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <div className="form-group">
                                                <Form.Label htmlFor="permission_description">Project Type <span className='text-danger'>*</span></Form.Label>
                                                <select name="" id="" className='form-select mb-3 shadow-none form-control' value={projectType} onChange={e => setProjectType(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {projectTypeList.length > 0 ?
                                                        projectTypeList.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>
                                                        ))
                                                        :
                                                        <option>No data found</option>
                                                    }
                                                </select>
                                            </div>

                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group ">
                                                <Form.Label htmlFor="role">Technology <span className='text-danger'>*</span></Form.Label><br />
                                                <Select
                                                    options={technologydata}
                                                    isMulti
                                                    onChange={(data) => setTechnolgy(data)}
                                                    value={technolgy}
                                                    required></Select>
                                            </Form.Group>
                                        </Col>

                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group ">
                                                <Form.Label htmlFor="role">Employees <span className='text-danger'>*</span></Form.Label><br />
                                                <Select
                                                    options={projectemployee}
                                                    isMulti
                                                    onChange={(data) => setEmployee(data)}
                                                    value={employee}
                                                    required></Select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Button type="submit" variant="btn btn-primary me-2">Update</Button>{' '}
                                            <Button type="button" variant="btn btn-danger" onClick={() => navigate
                                                ('/project')}>cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div>
        </>
    )
}

export default EditProject


