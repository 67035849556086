import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast, successShowToast } from "../../plugins/toastUtils";

const ProjectList = ({ api, user_data }) => {
  const Permissions = useContext(PermissionData);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState("");
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [search, setSearch] = useState(false);
  const [entry] = useState(10);
  const [dataperPage, setDataperPage] = useState(entry);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  let lastProjectData = projects.slice(-1);

  const projectlist = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/projectslisting?search=${searchValue}&page=${page}&limit=${dataperPage}&nameSort=${order}&status=${status}&userId=${user}`
      );
      if (response.data !== undefined) {
        if (response.data.status === false) navigate("/403");
        setProjects(response.data.projectData);
        setUserList(response.data.userData);
        setTotalPages(response.data.totalPages);
        setTotalData(response.data.totalData);
      }
    } catch (error) {
      if (error.response.status == 403) {
        navigate("/403");
      }
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const Handledelete = () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      api
        .post(`/deleteProject/${id}`, "")
        .then((response) => {
          setShow(false);
          projectlist();
          showToast(response.data.message, "success");
        })
        .catch((error) => {
          if (error.response.status == 403) {
            navigate("/403");
          }
          showToast(error.response.data.errors, "error");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  };

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      projectlist();
    }
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (!searchValue && search) {
      projectlist();
    }
    return () => {};
  }, [searchValue]);

  useEffect(() => {
    projectlist();
    return () => {};
  }, [status, user, page, dataperPage, order]);

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                className="me-2"
                variant="primary"
                disabled={loading}
                onClick={() => Handledelete()}
              >
                {loading ? "Deleting..." : "Delete"}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Projects</h4>
                </div>
                {Permissions.includes("Add Project") && (
                  <div className="Add-project">
                    <Link to="add">
                      {" "}
                      <h4 className="btn btn-primary">
                        <i
                          className="fa-solid fa-plus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        New Project
                      </h4>
                    </Link>
                  </div>
                )}
              </Card.Header>
              <Card.Header className={`d-flex justify-content-between`}>
                <div className="row align-items-center w-100">
                  <div className="col-md-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Show</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm "
                        onChange={(e) => setPerPage(e.target.value)}
                        defaultValue={entry}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Status</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm "
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="on Hold">on Hold</option>
                        <option value="in Progress">in Progress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  {Permissions.includes("View Dropdown") && (
                    <div className="col-md-4">
                      <div
                        className="dataTables_length d-flex align-items-center"
                        id="user-list-table_length"
                        style={{ gap: "2%" }}
                      >
                        <label> Employee</label>
                        <select
                          name="user-list-table_length"
                          aria-controls="user-list-table"
                          className="form-select form-select-sm "
                          onChange={(e) => setUser(e.target.value)}
                        >
                          <option value="">All</option>
                          {userList &&
                            userList.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.firstname + " " + item.last_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row align-items-center ">
                  <div className="col-md-12">
                    <div
                      id="user-list-table_filter"
                      className="dataTables_filter d-flex align-items-center justify-content-end"
                      style={{ gap: "2%" }}
                    >
                      <label>Search:</label>
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="user-list-table"
                        onChange={(event) => setSearchValue(event.target.value)}
                        onKeyDown={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>No</th>
                        <th
                          min-width="50px"
                          style={{
                            cursor: "pointer",
                            whiteSpace: "break-spaces",
                          }}
                          onClick={() => sorting("title")}
                        >
                          <span className="title">Title</span>
                        </th>
                        <th>
                          <span className="title">Start Date</span>
                        </th>
                        <th>
                          <span className="title">End Date</span>
                        </th>
                        <th>
                          <span className="title">Technology</span>
                        </th>
                        <th>
                          <span className="title">Employees</span>
                        </th>
                        <th>
                          <span className="title">Project Type</span>
                        </th>
                        <th>
                          <span className="title">Status</span>
                        </th>
                        <th>
                          <span
                            className="title"
                            title="Estimated Project Hours"
                          >
                            EPH
                          </span>
                        </th>
                        <th>
                          <span className="title" title="Actual Project Hours">
                            APH
                          </span>
                        </th>
                        {(Permissions.includes("Update Project") ||
                          (Permissions.includes("Delete Project") &&
                            Permissions.includes("View Project"))) && (
                          <th min-width="100px">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={20} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      ) : projects?.length > 0 ? (
                        projects.map((item, index) => (
                          <tr key={index} className="border-light">
                            <td>{item.index}</td>
                            <td
                              style={{
                                minWidth: "300px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {item.title}
                            </td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td>
                              <div className="iq-media-group iq-media-group-1">
                                {item.technology &&
                                  item.technology.map((data, index) => (
                                    <OverlayTrigger
                                      key={index}
                                      placement="bottom"
                                      overlay={<Tooltip>{`${data}`}</Tooltip>}
                                    >
                                      <Link to="#" className="iq-media-1">
                                        <div
                                          className="icon iq-icon-box-3 rounded-pill d-flex justify-content-center align-items-center"
                                          style={{ textTransform: "uppercase" }}
                                          title={`${data}`}
                                        >
                                          {`${data.charAt(0)}`}
                                        </div>
                                      </Link>
                                    </OverlayTrigger>
                                  ))}
                              </div>
                            </td>
                            <td>
                              <div className="iq-media-group iq-media-group-1">
                                {item.userData &&
                                  item.userData.map((data, index) => (
                                    <OverlayTrigger
                                      key={index}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip>
                                          {`${data.firstname} ${data.last_name}`}
                                        </Tooltip>
                                      }
                                    >
                                      <Link to="#" className="iq-media-1">
                                        <div
                                          className="icon iq-icon-box-3 rounded-pill d-flex justify-content-center align-items-center"
                                          style={{ textTransform: "uppercase" }}
                                          title={`${data.firstname} ${data.last_name}`}
                                        >
                                          {`${data.firstname.charAt(
                                            0
                                          )}${data.last_name.charAt(0)}`}
                                        </div>
                                      </Link>
                                    </OverlayTrigger>
                                  ))}
                              </div>
                            </td>
                            <td>{item.project_type}</td>
                            <td>
                              <span
                                className={`badge ${
                                  item.status === "on Hold"
                                    ? "bg-secondary"
                                    : item.status === "in Progress"
                                    ? "bg-primary"
                                    : item.status === "Completed"
                                    ? "bg-success"
                                    : ""
                                } p-2 w-100`}
                                style={{ fontWeight: "200" }}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.totalProjectEstimatedHours}</td>
                            <td>{item.totalProjectActualHours}</td>
                            {(Permissions.includes("Update Project") ||
                              Permissions.includes("Delete Project") ||
                              Permissions.includes("View Project")) && (
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  {Permissions.includes("Update Project") && (
                                    <Link
                                      className="btn btn-sm btn-icon btn-warning"
                                      style={{ marginRight: "5px" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      to={`edit/${item._id}`}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M15.1655 4.60254L19.7315 9.16854"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                  {Permissions.includes("Delete Project") && (
                                    <Link
                                      onClick={() => deletemodel(item._id)}
                                      className="btn btn-sm btn-icon btn-danger me-2"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      to="#"
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="currentColor"
                                        >
                                          <path
                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M20.708 6.23975H3.75"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                  {Permissions.includes("View Project") && (
                                    <Link
                                      onClick={() => deletemodel(item._id)}
                                      className="btn btn-sm btn-icon btn-primary"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View"
                                      to={`view/${item._id}`}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 1024 1024"
                                          className="icon"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill="#ffffff"
                                            stroke="#ffffff"
                                            d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 110 448 224 224 0 010-448zm0 64a160.192 160.192 0 00-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            <span className="" aria-hidden="true"></span>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row align-items-center w-100 mt-2">
                  <div className="col-md-6">
                    <div
                      className="dataTables_length d-flex align-items-center mx-3"
                      id="user-list-table_length"
                    >
                      {projects?.length > 0 && (
                        <label>
                          Showing {projects[0]?.index} to{" "}
                          {lastProjectData[0]?.index} of {totalData} entries
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%', position: "relative", right: '30%' }}>
                                            <div className="dataTables_paginate paging_simple_numbers" id="user-list-table_paginate"> */}
                    <ReactPaginate
                      nextLabel="Next"
                      onPageChange={PageChange}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={totalPages}
                      previousLabel="Previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination float-end"
                      renderOnZeroPageCount={null}
                    />
                    {/* {projects?.length > 0 && (
                                                    
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        pageCount={totalPages}
                                                        onPageChange={PageChange}
                                                        containerClassName={'pagination align-items-center'}
                                                        previousClassName={'paginate_button page-item previous'}
                                                        previousLinkClassName={'page-link border-0'}
                                                        nextLinkClassName={'page-link border-0'}
                                                        nextClassName={'paginate_button page-item next border-none'}
                                                        disabledClassName={'disabled'}
                                                        activeClassName={'active'}
                                                        breakLabel="..."
                                                    />
                                                    
                                                )} */}
                    {/* </div>
                                        </div> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainerWrapper />
        </Row>
      </div>
    </>
  );
};

export default ProjectList;
