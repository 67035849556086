import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddEntry({ api }) {

    const navigate = useNavigate();
    const [hours, setHours] = useState("");
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [project, setProject] = useState("");
    const [task, setTask] = useState("");
    const [prevProject, setPrevProject] = useState("");
    const [projectlist, setProjectlist] = useState([]);
    const [approvedDates, setApprovedDates] = useState([]);
    const [tasklist, setTasklist] = useState([]);
    const [validated, setValidated] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [dateRange, setDateRange] = useState('');
    const [isSunday, setIsSunday] = useState(false);
    const [isSaturday, setIsSaturday] = useState(false);
    const [isholiday, setIsHoliday] = useState(false);
    const [isOnleave, setIsOnleave] = useState(false);
    const [hoursError, setHoursError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [holiday, setHoliday] = useState([]);
    const [userLeaves, setUserLeaves] = useState([]);
    const today = new Date();
    const selectedDate = new Date(date);
    const approveFilterDates = [...new Set(approvedDates)]
    const diffInDays = Math.floor((today - selectedDate) / (1000 * 60 * 60 * 24));

    const AddTimeEntry = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();

        if (isValid && !isSunday && !isholiday && !isOnleave && !hoursError && !isSaturday) {
            await api.post(`/addTimeEntries`, {
                project_id: project,
                task_id: task,
                hours: hours,
                date: date,
            }).then((response) => {
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100)
                navigate('/time-entry')
            }).catch((error) => {
                showToast(error.response.data.errors,'error');
                setValidated(true)
            })
            setLoading(false);
        }
        else {
            setValidated(true);
            setLoading(false);
        }
    }

    const TimeEntryFormDetail = async () => {
        await api.get(`/addTimeEntries`).then((response) => {
            if (response.data !== undefined) {
                setProjectlist(response.data.projectData);
                setDateRange(response.data.validDays);

                const parsedHolidays = response.data.holidayData?.map(holiday => (new Date(holiday.holiday_date)).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                }).replace(/\//g, '-'));
                setHoliday(parsedHolidays);
                const dates = [];
                response.data.userLeavesdata?.forEach((entry) => {
                    const startDate = new Date(entry.datefrom);
                    const endDate = new Date(entry.dateto);
                    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                        dates.push(new Date(date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        }).replace(/\//g, '-'));
                    }
                });
                dates?.length > 0 && setUserLeaves(dates);
                const generateDatesBetween = (startDate, endDate) => {
                    const dates = [];
                    const currentDate = new Date(startDate);
                    const lastDate = new Date(endDate);

                    while (currentDate <= lastDate) {
                        dates.push(new Date(currentDate));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                    return dates;
                };

                const allDates = response?.data?.timeEntryRequestData?.reduce((acc, curr) => {
                    const datesBetween = generateDatesBetween(curr.start_date, curr.end_date);
                    acc.push(...datesBetween);
                    return acc;
                }, []);

                const sortedDates = allDates?.sort((a, b) => a.getTime() - b.getTime());
                const sortedDateStrings = sortedDates?.map(date => date.toISOString().split('T')[0]);
                setApprovedDates(sortedDateStrings);

            }
        }).catch((error) => {
            console.log(error, "error");
        });
    }

    useEffect(() => {
        if (project) {
            api.post(`/getTaskByProject/${project}`, '').then((response) => {
                if (response.data !== undefined) {
                    setTasklist(response.data.taskData);
                }
            }).catch((error) => {
                console.log("error", error)
            });
            if (prevProject !== project) {
                resetTaskList();
            }
            setPrevProject(project);
        }
        return () => {
        };
    }, [project, date]);

    useEffect(() => {
        if (hours > 24 || hours < 0) {
            setHoursError(true);
        }
        else {
            setHoursError(false);
        }
        return () => {
        };
    }, [hours]);

    useEffect(() => {
        if (date) {
            const formatedDate = ((new Date(date).toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' })).replace(/\//g, '-'));
            if (holiday.includes(formatedDate)) {
                setIsHoliday(true);
                setIsSaturday(false);
                setDateError(false);
                setIsSunday(false);
                setIsOnleave(false)
                return
            }
            if (userLeaves.includes(formatedDate)) {
                setIsOnleave(true)
                setIsSaturday(false);
                setIsHoliday(false);
                setDateError(false);
                setIsSunday(false);
                return
            }
            if ((new Date(date).getDay() === 0) === true) {
                setIsSunday(true);
                setIsSaturday(false);
                setDateError(false);
                setIsOnleave(false);
                setIsHoliday(false);
                return;
            }
            if ((new Date(date).getDay() === 6) === true) {
                setIsSunday(false);
                setIsSaturday(true);
                setDateError(false);
                setIsOnleave(false);
                setIsHoliday(false);
                return;
            }
            if (diffInDays > parseInt(dateRange) + +approveFilterDates?.length) {
                setDateError(true);
                setIsSaturday(false);
                setIsSunday(false);
                setIsHoliday(false);
                setIsOnleave(false)
                if ((new Date(date).getDay() === 0) === false) {
                    setIsSunday(false);
                    setIsHoliday(false);
                    setIsOnleave(false)
                }
                return;
            }
            if (date < new Date(Date.now() - dateRange * 24 * 60 * 60 * 1000)
                || date > new Date().toISOString().split('T')[0]) {
                setDateError(true);
                setIsSaturday(false);
                setIsHoliday(false);
                setIsOnleave(false);
                return;
            }
            setDateError(false);
            setIsSunday(false);
            setIsOnleave(false);
            setIsSaturday(false);
            setIsHoliday(false);
            setDate(date);
        }
        return () => {
        };
    }, [date]);

    const getProjectData = (e) => {
        setProject(e.target.value);
    }

    const resetTaskList = () => {
        setTasklist([]);
    };

    useEffect(() => {
        TimeEntryFormDetail();
        return () => {
        };
    }, []);

    const last3DaysDate = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

    return (
        <div>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">New Time Entry</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => AddTimeEntry(e)} noValidate validated={validated}>
                                <Row>
                                    <Col sm="12" lg="6">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Project Name<span className='text-danger'>*</span></Form.Label>
                                            <select name="" id="" className='form-select mb-3 shadow-none form-control' onChange={e => getProjectData(e)} >
                                                <option value="">Select</option>
                                                {projectlist || projectlist?.length > 0 ?
                                                    projectlist.map((item, index) => (
                                                        <option value={item._id} key={index}>{item.title}</option>
                                                    ))
                                                    :
                                                    <option>No data found</option>
                                                }
                                            </select>
                                            <Form.Control.Feedback type="invalid">
                                                PLease Select project name
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    <Col sm="12" lg="6">
                                        <Form.Label htmlFor="permission_description">Task Name<span className='text-danger'>*</span></Form.Label>
                                        <select name="" id="" className='form-select mb-3 shadow-none form-control' onChange={e => setTask(e.target.value)} >
                                            <option value="">Select</option>
                                            {tasklist?.length > 0 ?
                                                tasklist.map((item, index) => (
                                                    <option value={item._id} key={index}>{item.title}</option>
                                                ))
                                                :
                                                <option disabled>No data found</option>
                                            }
                                        </select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select task name
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col sm="12" lg="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Total Hours<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                id="title"
                                                onChange={e => setHours(e.target.value)}
                                                step={0.5}
                                                min={0}
                                                style={hoursError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                            />
                                            {hoursError &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Please enter hour between 0 to 24
                                                </Form.Control.Feedback>
                                            }
                                            <Form.Control.Feedback type="invalid">
                                                Total hours is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col sm="12" lg="6">
                                        <Form.Group className="form-group" noValidate validated={validated.toString()}>
                                            <Form.Label htmlFor="task_description">Date:<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control
                                                type="date"
                                                id="task_description"
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                                min={approvedDates && approvedDates?.length > 0 ? approvedDates[0] : last3DaysDate}
                                                max={(new Date())?.toISOString().split('T')[0]}

                                                style={isSaturday || isSunday || dateError || isholiday || isOnleave ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                            />
                                            {isSunday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Sunday
                                                </Form.Control.Feedback>
                                            }
                                            {isSaturday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Saturday.
                                                </Form.Control.Feedback>
                                            }
                                            {isOnleave &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    You are on leave on this date
                                                </Form.Control.Feedback>
                                            }
                                            {isholiday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Holiday
                                                </Form.Control.Feedback>
                                            }
                                            {!approvedDates.includes(date) && dateError && (
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Date is out of range
                                                </Form.Control.Feedback>
                                            )}
                                            <Form.Control.Feedback type="invalid">
                                                Date is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col sm="12" lg="6">
                                        <Button
                                            type="submit"
                                            variant="btn btn-primary me-2"
                                            disabled={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Submit'}
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="btn btn-danger"
                                            onClick={() =>
                                                navigate('/time-entry')
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainerWrapper />
        </div>
    )
}

export default AddEntry
