import React, { useContext } from "react";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { PermissionData } from "../../../../layouts/dashboard/default";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const Permissions = useContext(PermissionData);

  //location
  let location = useLocation();

  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        {/* dashboard navigation */}
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Home</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/dashboard" || location.pathname === "/"
              ? "active"
              : ""
              } nav-link `}
            aria-current="page"
            to="dashboard"
            onClick={() => { }}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>
        {(Permissions?.includes("View Holidays") ||
          Permissions?.includes("View Employees")) && (
            <li>
              <hr className="hr-horizontal" />
            </li>
          )}
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">
              {Permissions?.includes("View Employees") && "Employee"}
              {Permissions?.includes("View Employees") &&
                Permissions?.includes("View Holidays") &&
                " & "}
              {Permissions?.includes("View Holidays") && "Holiday"}
            </span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {/* employee  */}
        {Permissions.length > 0 && Permissions?.includes("View Employees") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname?.includes("/employee") &&
                !location.pathname?.includes("/employee-salary")
                ? "active"
                : ""
                } nav-link `}
              aria-current="page"
              to="/employee"
              onClick={() => { }}
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
              <span className="item-name">Employee</span>
            </Link>
          </li>
        )}

        {/* holiday */}
        {Permissions.length > 0 && Permissions?.includes("View Holidays") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname?.includes("/holiday") ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="holiday"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 16.8701V9.25708H21V16.9311C21 20.0701 19.0241 22.0001 15.8628 22.0001H8.12733C4.99561 22.0001 3 20.0301 3 16.8701ZM7.95938 14.4101C7.50494 14.4311 7.12953 14.0701 7.10977 13.6111C7.10977 13.1511 7.46542 12.7711 7.91987 12.7501C8.36443 12.7501 8.72997 13.1011 8.73985 13.5501C8.7596 14.0111 8.40395 14.3911 7.95938 14.4101ZM12.0198 14.4101C11.5653 14.4311 11.1899 14.0701 11.1701 13.6111C11.1701 13.1511 11.5258 12.7711 11.9802 12.7501C12.4248 12.7501 12.7903 13.1011 12.8002 13.5501C12.82 14.0111 12.4643 14.3911 12.0198 14.4101ZM16.0505 18.0901C15.596 18.0801 15.2305 17.7001 15.2305 17.2401C15.2206 16.7801 15.5862 16.4011 16.0406 16.3911H16.0505C16.5148 16.3911 16.8902 16.7711 16.8902 17.2401C16.8902 17.7101 16.5148 18.0901 16.0505 18.0901ZM11.1701 17.2401C11.1899 17.7001 11.5653 18.0611 12.0198 18.0401C12.4643 18.0211 12.82 17.6411 12.8002 17.1811C12.7903 16.7311 12.4248 16.3801 11.9802 16.3801C11.5258 16.4011 11.1701 16.7801 11.1701 17.2401ZM7.09989 17.2401C7.11965 17.7001 7.49506 18.0611 7.94951 18.0401C8.39407 18.0211 8.74973 17.6411 8.72997 17.1811C8.72009 16.7311 8.35456 16.3801 7.90999 16.3801C7.45554 16.4011 7.09989 16.7801 7.09989 17.2401ZM15.2404 13.6011C15.2404 13.1411 15.596 12.7711 16.0505 12.7611C16.4951 12.7611 16.8507 13.1201 16.8705 13.5611C16.8804 14.0211 16.5247 14.4011 16.0801 14.4101C15.6257 14.4201 15.2503 14.0701 15.2404 13.6111V13.6011Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M3.00293 9.25699C3.01577 8.66999 3.06517 7.50499 3.15803 7.12999C3.63224 5.02099 5.24256 3.68099 7.54442 3.48999H16.4555C18.7376 3.69099 20.3677 5.03999 20.8419 7.12999C20.9338 7.49499 20.9832 8.66899 20.996 9.25699H3.00293Z"
                    fill="currentColor"
                  />
                  <path
                    d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Holiday</span>
            </Link>
          </li>
        )}
        {(Permissions?.includes("View Roles") ||
          Permissions?.includes("View Permissions")) && (
            <li>
              <hr className="hr-horizontal" />
            </li>
          )}
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">
              {Permissions?.includes("View Roles") && "Role"}
              {Permissions?.includes("View Roles") &&
                Permissions?.includes("View Permissions") &&
                " & "}
              {Permissions?.includes("View Permissions") && "permission"}
            </span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {/* role module  */}
        {Permissions.length > 0 && Permissions?.includes("View Roles") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/role" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="role"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z"
                    fill="currentColor"
                  ></path>
                  <circle
                    cx="18"
                    cy="11.8999"
                    r="1"
                    fill="currentColor"
                  ></circle>
                </svg>
              </i>
              <span className="item-name">Role</span>
            </Link>
          </li>
        )}
        {/* permission module  */}
        {Permissions.length > 0 &&
          Permissions?.includes("View Permissions") && (
            <li className="nav-item">
              <Link
                className={`${location.pathname === "/permission" ? "active" : ""
                  } nav-link `}
                aria-current="page"
                to="permission"
              >
                <i className="icon">
                  <svg
                    width="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M12.0865 22C11.9627 22 11.8388 21.9716 11.7271 21.9137L8.12599 20.0496C7.10415 19.5201 6.30481 18.9259 5.68063 18.2336C4.31449 16.7195 3.5544 14.776 3.54232 12.7599L3.50004 6.12426C3.495 5.35842 3.98931 4.67103 4.72826 4.41215L11.3405 2.10679C11.7331 1.96656 12.1711 1.9646 12.5707 2.09992L19.2081 4.32684C19.9511 4.57493 20.4535 5.25742 20.4575 6.02228L20.4998 12.6628C20.5129 14.676 19.779 16.6274 18.434 18.1581C17.8168 18.8602 17.0245 19.4632 16.0128 20.0025L12.4439 21.9088C12.3331 21.9686 12.2103 21.999 12.0865 22Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.3194 14.3209C11.1261 14.3219 10.9328 14.2523 10.7838 14.1091L8.86695 12.2656C8.57097 11.9793 8.56795 11.5145 8.86091 11.2262C9.15387 10.9369 9.63207 10.934 9.92906 11.2193L11.3083 12.5451L14.6758 9.22479C14.9698 8.93552 15.448 8.93258 15.744 9.21793C16.041 9.50426 16.044 9.97004 15.751 10.2574L11.8519 14.1022C11.7049 14.2474 11.5127 14.3199 11.3194 14.3209Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">Permission</span>
              </Link>
            </li>
          )}
        {/* {Permissions?.includes("View TimeEntries") && ( */}
        <li>
          <hr className="hr-horizontal" />
        </li>
        {/* )} */}

        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Time Entry</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {/* TimeEntries */}
        {/* {Permissions.length > 0 && Permissions?.includes("View TimeEntries") && ( */}
        <>
          {/* {Permissions.length > 0 && Permissions?.includes("View TimeEntries") && ( */}
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/time-entry" ||
                location.pathname === "/time-entry/add" ||
                location.pathname === "/time-entry/edit"
                ? "active"
                : ""
                } nav-link`}
              to="/time-entry"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Time Entry</span>
            </Link>
          </li>
          {/* )} */}
          {/* {Permissions.length > 0 && Permissions?.includes("View TimeEntries") && ( */}
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/time-entry-history" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="time-entry-history"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Time Entry History</span>
            </Link>
          </li>
          {/* )} */}
          {/* {Permissions.length > 0 && Permissions?.includes("View TimeEntries") && ( */}
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/time-entry-date-request" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="time-entry-date-request"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Date Request</span>
            </Link>
          </li>
          {/* )} */}
          {/* {Permissions.length > 0 && Permissions?.includes("View TimeEntries") && ( */}
          {/* <li className="nav-item">
            <Link
              className={`${location.pathname === "/working-hour" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="working-hour"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Working Hour</span>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/working-hour" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="working-hour"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Working Hour</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/average-hour" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="average-hour"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Average hour</span>
            </Link>
          </li>
          {/* )} */}
        </>
        {/* )} */}
        {(Permissions?.includes("View Projects") ||
          Permissions?.includes("View Tasks")) && (
            <li>
              <hr className="hr-horizontal" />
            </li>
          )}
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">
              {Permissions?.includes("View Projects") && "Project"}
              {Permissions?.includes("View Projects") &&
                Permissions?.includes("View Tasks") &&
                " & "}
              {Permissions?.includes("View Tasks") && "Task"}
            </span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {/* project  */}
        {Permissions.length > 0 && Permissions?.includes("View Projects") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/project" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="project"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.36866 9.3689C6.91533 9.3689 6.54199 9.74223 6.54199 10.2045V17.0756C6.54199 17.5289 6.91533 17.9022 7.36866 17.9022C7.83088 17.9022 8.20421 17.5289 8.20421 17.0756V10.2045C8.20421 9.74223 7.83088 9.3689 7.36866 9.3689Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.0352 6.08887C11.5818 6.08887 11.2085 6.4622 11.2085 6.92442V17.0755C11.2085 17.5289 11.5818 17.9022 12.0352 17.9022C12.4974 17.9022 12.8707 17.5289 12.8707 17.0755V6.92442C12.8707 6.4622 12.4974 6.08887 12.0352 6.08887Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.6398 12.9956C16.1775 12.9956 15.8042 13.3689 15.8042 13.8312V17.0756C15.8042 17.5289 16.1775 17.9023 16.6309 17.9023C17.0931 17.9023 17.4664 17.5289 17.4664 17.0756V13.8312C17.4664 13.3689 17.0931 12.9956 16.6398 12.9956Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Project</span>
            </Link>
          </li>
        )}
        {/* task module  */}
        {Permissions.length > 0 && Permissions?.includes("View Tasks") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname?.includes("/task") ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="task"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.97398 11.3877H12.359C12.77 11.3877 13.104 11.0547 13.104 10.6437C13.104 10.2327 12.77 9.89868 12.359 9.89868H8.97398C8.56298 9.89868 8.22998 10.2327 8.22998 10.6437C8.22998 11.0547 8.56298 11.3877 8.97398 11.3877ZM8.97408 16.3819H14.4181C14.8291 16.3819 15.1631 16.0489 15.1631 15.6379C15.1631 15.2269 14.8291 14.8929 14.4181 14.8929H8.97408C8.56308 14.8929 8.23008 15.2269 8.23008 15.6379C8.23008 16.0489 8.56308 16.3819 8.97408 16.3819Z"
                    fill="currentColor"
                  />
                </svg>
              </i>
              <span className="item-name">Task</span>
            </Link>
          </li>
        )}

        {/* leave module  */}
        {(Permissions?.includes("View Leaves") ||
          Permissions?.includes("View All UserLeaves") ||
          Permissions?.includes("View Leaves Request")) && (
            <>
              <li>
                <hr className="hr-horizontal" />
              </li>

              <li className="nav-item static-item">
                <Link
                  className="nav-link static-item disabled"
                  to="#"
                  tabIndex="-1"
                >
                  <span className="default-icon">Leaves</span>
                  <span className="mini-icon">-</span>
                </Link>
              </li>
            </>
          )}
        {Permissions?.includes("View Leaves") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/leaves" ? "active" : ""
                } nav-link`}
              to="/leaves"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                    fill="currentColor"
                  />
                </svg>
              </i>

              <span className="item-name">Leaves</span>
            </Link>
          </li>
        )}
        {Permissions?.includes("View Leaves Request") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/all-requests" ? "active" : ""
                } nav-link`}
              to="/all-requests"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                    fill="currentColor"
                  />
                </svg>
              </i>

              <span className="item-name">All Requests</span>
            </Link>
          </li>
        )}
        {Permissions?.includes("View All UserLeaves") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/leave-history" ? "active" : ""
                } nav-link`}
              to="/leave-history"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                    fill="currentColor"
                  />
                </svg>
              </i>

              <span className="item-name">Leave History</span>
            </Link>
          </li>
        )}

        {Permissions?.includes("View All UserLeaves") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/UserLeave" ? "active" : ""
                } nav-link`}
              to="/UserLeave"
            >
              <i className="icon">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                    fill="currentColor"
                  />
                </svg>
              </i>

              <span className="item-name">User Leave</span>
            </Link>
          </li>
        )}
        {/* salary */}
        {(Permissions?.includes("View SalaryStructures") ||
          Permissions?.includes("View Salary")) && (
            <>
              <li>
                <hr className="hr-horizontal" />
              </li>
              <li className="nav-item static-item">
                <Link
                  className="nav-link static-item disabled"
                  to="#"
                  tabIndex="-1"
                >
                  <span className="default-icon">Payroll</span>
                  <span className="mini-icon">-</span>
                </Link>
              </li>
            </>
          )}

        {Permissions.length > 0 &&
          Permissions?.includes("View SalaryStructures") && (
            <li className="nav-item">
              <Link
                className={`${location.pathname === "/salary-structure" ? "active" : ""
                  } nav-link `}
                aria-current="page"
                to="salary-structure"
              >
                <i className="icon">
                  <svg
                    width="19"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 235.517 235.517"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        fill="#8A92A6"
                        d="M118.1,235.517c7.898,0,14.31-6.032,14.31-13.483c0-7.441,0-13.473,0-13.473
                                    c39.069-3.579,64.932-24.215,64.932-57.785v-0.549c0-34.119-22.012-49.8-65.758-59.977V58.334c6.298,1.539,12.82,3.72,19.194,6.549
                                    c10.258,4.547,22.724,1.697,28.952-8.485c6.233-10.176,2.866-24.47-8.681-29.654c-11.498-5.156-24.117-8.708-38.095-10.236V8.251
                                    c0-4.552-6.402-8.251-14.305-8.251c-7.903,0-14.31,3.514-14.31,7.832c0,4.335,0,7.843,0,7.843
                                    c-42.104,3.03-65.764,25.591-65.764,58.057v0.555c0,34.114,22.561,49.256,66.862,59.427v33.021
                                    c-10.628-1.713-21.033-5.243-31.623-10.65c-11.281-5.755-25.101-3.72-31.938,6.385c-6.842,10.1-4.079,24.449,7.294,30.029
                                    c16.709,8.208,35.593,13.57,54.614,15.518v13.755C103.79,229.36,110.197,235.517,118.1,235.517z M131.301,138.12
                                    c14.316,4.123,18.438,8.257,18.438,15.681v0.555c0,7.979-5.776,12.651-18.438,14.033V138.12z M86.999,70.153v-0.549
                                    c0-7.152,5.232-12.657,18.71-13.755v29.719C90.856,81.439,86.999,77.305,86.999,70.153z"
                      />
                    </g>
                  </svg>
                </i>
                <span className="item-name">Salary Structure</span>
              </Link>
            </li>
          )}

        {Permissions.length > 0 && Permissions?.includes("View Salary") && (
          <li className="nav-item">
            <Link
              className={`${location.pathname === "/employee-salary" ? "active" : ""
                } nav-link `}
              aria-current="page"
              to="employee-salary"
            >
              <i className="icon">
                <svg
                  width="19"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 235.517 235.517"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#8A92A6"
                      d="M118.1,235.517c7.898,0,14.31-6.032,14.31-13.483c0-7.441,0-13.473,0-13.473
                                    c39.069-3.579,64.932-24.215,64.932-57.785v-0.549c0-34.119-22.012-49.8-65.758-59.977V58.334c6.298,1.539,12.82,3.72,19.194,6.549
                                    c10.258,4.547,22.724,1.697,28.952-8.485c6.233-10.176,2.866-24.47-8.681-29.654c-11.498-5.156-24.117-8.708-38.095-10.236V8.251
                                    c0-4.552-6.402-8.251-14.305-8.251c-7.903,0-14.31,3.514-14.31,7.832c0,4.335,0,7.843,0,7.843
                                    c-42.104,3.03-65.764,25.591-65.764,58.057v0.555c0,34.114,22.561,49.256,66.862,59.427v33.021
                                    c-10.628-1.713-21.033-5.243-31.623-10.65c-11.281-5.755-25.101-3.72-31.938,6.385c-6.842,10.1-4.079,24.449,7.294,30.029
                                    c16.709,8.208,35.593,13.57,54.614,15.518v13.755C103.79,229.36,110.197,235.517,118.1,235.517z M131.301,138.12
                                    c14.316,4.123,18.438,8.257,18.438,15.681v0.555c0,7.979-5.776,12.651-18.438,14.033V138.12z M86.999,70.153v-0.549
                                    c0-7.152,5.232-12.657,18.71-13.755v29.719C90.856,81.439,86.999,77.305,86.999,70.153z"
                    />
                  </g>
                </svg>
              </i>
              <span className="item-name">Employee Salary</span>
            </Link>
          </li>
        )}

        {/* Billing System */}

        {(Permissions?.includes("Add Billing System") ||
          Permissions?.includes("Add Billing System")) && (
            <>
              <li>
                <hr className="hr-horizontal" />
              </li>
              <li className="nav-item static-item">
                <Link
                  className="nav-link static-item disabled"
                  to="#"
                  tabIndex="-1"
                >
                  <span className="default-icon">Billing</span>
                  <span className="mini-icon">-</span>
                </Link>
              </li>
            </>
          )}

        {Permissions.length > 0 &&
          Permissions?.includes("Add Billing System") && (
            <li className="nav-item">
              <a
                className="nav-link dark-mode"
                aria-current="page"
                href="http://localhost:8080/login"
                target="_blank"
              >
                <i className="icon">
                  <svg
                    fill="#000000"
                    width="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z" />
                  </svg>
                </i>
                <span className="item-name">Billing System Page</span>
              </a>
            </li>
          )}

        {/* Inventry System  */}

        {(Permissions?.includes("View Assign Inventory") ||
          Permissions?.includes("View Assign Inventory")) && (
            <>
              <li>
                <hr className="hr-horizontal" />
              </li>
              <li className="nav-item static-item">
                <Link
                  className="nav-link static-item disabled"
                  to="#"
                  tabIndex="-1"
                >
                  <span className="default-icon">inventory</span>
                  <span className="mini-icon">-</span>
                </Link>
              </li>
            </>
          )}

        {Permissions.length > 0 &&
          Permissions?.includes("View Assign Inventory") && (
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/inventory-system" ? "active" : ""
                  }`}
                aria-current="page"
                to="inventory-system"
              >
                <i className="icon">
                  <svg
                    fill={
                      location.pathname === "/inventory-system"
                        ? "#FFFFFF"
                        : "#000000"
                    }
                    width="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z" />
                  </svg>
                </i>
                <span
                  className="item-name"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Assign Inventory
                </span>
              </Link>
            </li>
          )}

        {Permissions.length > 0 &&
          Permissions?.includes("View Creation of Inventories") && (
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/master-item" ? "active" : ""
                  }`}
                aria-current="page"
                to="master-item"
              >
                <i className="icon">
                  <svg
                    fill={
                      location.pathname === "/master-item"
                        ? "#FFFFFF"
                        : "#000000"
                    }
                    width="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z" />
                  </svg>
                </i>
                <span
                  className="item-name"
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Creation of Inventories
                </span>
              </Link>
            </li>
          )}
        {Permissions.length > 0 &&
          Permissions?.includes("View Master Inventories") && (
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/Master-Item-List" ? "active" : ""
                  }`}
                aria-current="page"
                to="Master-Item-List"
              >
                <i className="icon">
                  <svg
                    fill={
                      location.pathname === "/Master-Item-List"
                        ? "#FFFFFF"
                        : "#000000"
                    }
                    width="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z" />
                  </svg>
                </i>
                <span
                  className="item-name"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Master Inventories
                </span>
              </Link>
            </li>
          )}
        {Permissions.length > 0 &&
          Permissions?.includes("View CPU master Item") && (
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/Add-CPU-Master-Item" ? "active" : ""
                  }`}
                aria-current="page"
                to="Add-CPU-Master-Item"
              >
                <i className="icon">
                  <svg
                    fill={
                      location.pathname === "/Add-CPU-Master-Item"
                        ? "#FFFFFF"
                        : "#000000"
                    }
                    width="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15,8a1,1,0,0,1-1,1H6A1,1,0,0,1,6,7h8A1,1,0,0,1,15,8Zm-1,3H6a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm13-3v8a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H16a3,3,0,0,1,3,3v7h3A1,1,0,0,1,23,12ZM17,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H17Zm4,9H19v8h1a1,1,0,0,0,1-1Z" />
                  </svg>
                </i>
                <span
                  className="item-name"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  CPU master Item
                </span>
              </Link>
            </li>
          )}

        {/* Announcement */}

        {Permissions?.includes("View Announcements") && (
          <>
            <li>
              <hr className="hr-horizontal" />
            </li>
            <li className="nav-item static-item">
              <Link
                className="nav-link static-item disabled"
                to="#"
                tabIndex="-1"
              >
                <span className="default-icon">Announcement</span>
                <span className="mini-icon">-</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${location.pathname === "/announcement" ? "active" : ""
                  } nav-link `}
                aria-current="page"
                to="announcement"
              >
                <i className="icon">
                  <svg
                    width="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8251 15.2171H12.1748C14.0987 15.2171 15.731 13.985 16.3054 12.2764C16.3887 12.0276 16.1979 11.7713 15.9334 11.7713H14.8562C14.5133 11.7713 14.2362 11.4977 14.2362 11.16C14.2362 10.8213 14.5133 10.5467 14.8562 10.5467H15.9005C16.2463 10.5467 16.5263 10.2703 16.5263 9.92875C16.5263 9.58722 16.2463 9.31075 15.9005 9.31075H14.8562C14.5133 9.31075 14.2362 9.03619 14.2362 8.69849C14.2362 8.35984 14.5133 8.08528 14.8562 8.08528H15.9005C16.2463 8.08528 16.5263 7.8088 16.5263 7.46728C16.5263 7.12575 16.2463 6.84928 15.9005 6.84928H14.8562C14.5133 6.84928 14.2362 6.57472 14.2362 6.23606C14.2362 5.89837 14.5133 5.62381 14.8562 5.62381H15.9886C16.2483 5.62381 16.4343 5.3789 16.3645 5.13113C15.8501 3.32401 14.1694 2 12.1748 2H11.8251C9.42172 2 7.47363 3.92287 7.47363 6.29729V10.9198C7.47363 13.2933 9.42172 15.2171 11.8251 15.2171Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M19.5313 9.82568C18.9966 9.82568 18.5626 10.2533 18.5626 10.7823C18.5626 14.3554 15.6186 17.2627 12.0005 17.2627C8.38136 17.2627 5.43743 14.3554 5.43743 10.7823C5.43743 10.2533 5.00345 9.82568 4.46872 9.82568C3.93398 9.82568 3.5 10.2533 3.5 10.7823C3.5 15.0873 6.79945 18.6413 11.0318 19.1186V21.0434C11.0318 21.5715 11.4648 22.0001 12.0005 22.0001C12.5352 22.0001 12.9692 21.5715 12.9692 21.0434V19.1186C17.2006 18.6413 20.5 15.0873 20.5 10.7823C20.5 10.2533 20.066 9.82568 19.5313 9.82568Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">Announcement</span>
              </Link>
            </li>
          </>
        )}

        {/* Technology Start */}

        {Permissions?.includes("View Technology") && (
          <>
            <li>
              <hr className="hr-horizontal" />
            </li>
            <li className="nav-item static-item">
              <Link
                className="nav-link static-item disabled"
                to="#"
                tabIndex="-1"
              >
                <span className="default-icon">Technology</span>
                <span className="mini-icon">-</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${location.pathname === "/technology" ? "active" : ""
                  } nav-link `}
                aria-current="page"
                to="technology"
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-motherboard"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.5 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m2 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-10 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zM5 3a1 1 0 0 0-1 1h-.5a.5.5 0 0 0 0 1H4v1h-.5a.5.5 0 0 0 0 1H4a1 1 0 0 0 1 1v.5a.5.5 0 0 0 1 0V8h1v.5a.5.5 0 0 0 1 0V8a1 1 0 0 0 1-1h.5a.5.5 0 0 0 0-1H9V5h.5a.5.5 0 0 0 0-1H9a1 1 0 0 0-1-1v-.5a.5.5 0 0 0-1 0V3H6v-.5a.5.5 0 0 0-1 0zm0 1h3v3H5zm6.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
                    <path d="M1 2a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-2H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 9H1V8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6H1V5H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 2zm1 11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1z" />
                  </svg>
                  {/* <svg
                    width="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8251 15.2171H12.1748C14.0987 15.2171 15.731 13.985 16.3054 12.2764C16.3887 12.0276 16.1979 11.7713 15.9334 11.7713H14.8562C14.5133 11.7713 14.2362 11.4977 14.2362 11.16C14.2362 10.8213 14.5133 10.5467 14.8562 10.5467H15.9005C16.2463 10.5467 16.5263 10.2703 16.5263 9.92875C16.5263 9.58722 16.2463 9.31075 15.9005 9.31075H14.8562C14.5133 9.31075 14.2362 9.03619 14.2362 8.69849C14.2362 8.35984 14.5133 8.08528 14.8562 8.08528H15.9005C16.2463 8.08528 16.5263 7.8088 16.5263 7.46728C16.5263 7.12575 16.2463 6.84928 15.9005 6.84928H14.8562C14.5133 6.84928 14.2362 6.57472 14.2362 6.23606C14.2362 5.89837 14.5133 5.62381 14.8562 5.62381H15.9886C16.2483 5.62381 16.4343 5.3789 16.3645 5.13113C15.8501 3.32401 14.1694 2 12.1748 2H11.8251C9.42172 2 7.47363 3.92287 7.47363 6.29729V10.9198C7.47363 13.2933 9.42172 15.2171 11.8251 15.2171Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M19.5313 9.82568C18.9966 9.82568 18.5626 10.2533 18.5626 10.7823C18.5626 14.3554 15.6186 17.2627 12.0005 17.2627C8.38136 17.2627 5.43743 14.3554 5.43743 10.7823C5.43743 10.2533 5.00345 9.82568 4.46872 9.82568C3.93398 9.82568 3.5 10.2533 3.5 10.7823C3.5 15.0873 6.79945 18.6413 11.0318 19.1186V21.0434C11.0318 21.5715 11.4648 22.0001 12.0005 22.0001C12.5352 22.0001 12.9692 21.5715 12.9692 21.0434V19.1186C17.2006 18.6413 20.5 15.0873 20.5 10.7823C20.5 10.2533 20.066 9.82568 19.5313 9.82568Z"
                      fill="currentColor"
                    />
                  </svg> */}
                </i>
                <span className="item-name">Technology</span>
              </Link>
            </li>
          </>
        )}
        {/* Technology End */}

        {/* Permission Slug Start */}

        {Permissions?.includes("view master permission") && (
          <>
            <li>
              <hr className="hr-horizontal" />
            </li>
            <li className="nav-item static-item">
              <Link
                className="nav-link static-item disabled"
                to="#"
                tabIndex="-1"
              >
                <span className="default-icon">Permission Module</span>
                <span className="mini-icon">-</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${location.pathname === "/permission-module" ? "active" : ""
                  } nav-link `}
                aria-current="page"
                to="permission-module"
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"
                    />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                  </svg>
                </i>
                <span className="item-name">Permission Module</span>
              </Link>
            </li>
          </>
        )}

        {/* Permission Slug End */}

        {/* <li>
                    <hr className="hr-horizontal" />
                </li> */}

        {/* extra space li's */}
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon"></span>
            <span className="mini-icon"></span>
          </Link>
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon"></span>
            <span className="mini-icon"></span>
          </Link>
        </li>
      </Accordion>
    </>
  );
};
export default VerticalNav;
