import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { NavLink, useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { showToast } from '../../plugins/toastUtils';

const LeaveHistory = ({ api }) => {

    const [employeeData, setEmployeeData] = useState([]);
    const [leaveHistortData, setLeaveHistortData] = useState(null);
    const [editshow, setEditShow] = useState(false);
    const [order, setOrder] = useState('ASC');
    const [entry] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const currentyear = `${moment().month() > 2 ? moment().year() : moment().year() - 1}-${moment().month() > 2 ? moment().year() + 1 : moment().year() + 2}`
    const [year, setYear] = useState(currentyear);
    const [show, setShow] = useState(false);
    const [deleteid, setDeleteid] = useState('');
    const [dataperPage, setDataperPage] = useState(10);
    const [totalLeaves, setTotalLeaves] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [takenLeaves, setTakenLeaves] = useState('');
    const [page, setPage] = useState(1);
    const [remainingLeaves, setRemainingLeaves] = useState('');
    const [search, setSearch] = useState(false);
    const [totalData, setTotalData] = useState("");
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let lastEmployeeLeaveHistoryData = employeeData?.slice(-1)

    const Leavesdata = async () => {
        setLoading(true);
        await api.get(`/alluserleaveHistory?search=${searchValue}&page=${page}&limit=${dataperPage}&nameSort=${order}&year=${year}`).
            then((response) => {
                if (response.data.status === false) {
                    navigate('/403')
                }
                if (response.data !== undefined) {
                    setEmployeeData(response.data.leaveHistoryData);
                    setTotalPages(response.data.totalPages)
                    setTotalData(response.data.totalData)
                }
                setLoading(false);
            }).catch((error) => {
                if (error.response.status == 403) {
                    navigate('/403')
                }
            });
    };

    const Handledelete = async () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            await api.delete(`/deleteLeaveHistory/${id}`, '').then((response) => {
                setShow(false);
                showToast(response.data.message, "success")
                Leavesdata();
            }).catch((error) => {
                setShow(false);
                showToast(error.response.data.error, 'error');
                setLoading(false);
                if (error.response.status == 403) {
                    navigate('/403')
                }
            });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    function deletemodel(id) {
        setDeleteid(id);
        setShow(true);
    }

    const handleEditClose = () => {
        setEditShow(false);
    }

    const PageChange = ({ selected }) => {
        setPage(selected + 1);
    }

    const setPerPage = (value) => {
        setDataperPage(value)
        setPage(1)
    }

    const sorting = () => {
        if (order === 'ASC') {
            setOrder('DSC')
        }
        if (order === 'DSC') {
            setOrder('ASC')
        }
    }

    const handleSearch = async (event) => {
        if (searchValue && event.key === 'Enter') {
            setPage(1)
            setSearch(true)
            Leavesdata()
        }
    }

    const updateLeaveHistory = (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        api.post(`/editLeaveHistory/${id}`, {
            total_leaves: totalLeaves,
            taken_leaves: takenLeaves,
            remaining_leaves: totalLeaves - takenLeaves,
        }).then((response) => {
            setEditShow(false)
            setLoading(false)
            showToast(response.data.message, "success")
        }).catch((error) => {
            showToast(error.response.data.errors, 'error');
            setLoading(false);
            if (error.response.status == 403) {
                navigate('/403')
            }
        });
    }

    const handleEditClick = async (id) => {
        try {
            if (id !== undefined) {
                await api.get(`/editLeaveHistory/${id}`, '')
                    .then((response) => {
                        if (response.data !== undefined) {
                            setLeaveHistortData(response.data.leaveHistory);
                            setTotalLeaves(response.data.leaveHistory.total_leaves)
                            setTakenLeaves(response.data.leaveHistory.taken_leaves)
                            setRemainingLeaves(response.data.leaveHistory.remaining_leaves)
                            setId(response.data.leaveHistory._id)
                            setEditShow(true)
                        }
                    }).catch((error) => {
                        showToast(error.response.data.errors, 'error');
                        if (error.response.status == 403) {
                            navigate('/403')
                        }
                    });
            }
        } catch (error) {
            if (error.response.status == 403) {
                navigate('/403')
            }
        }
    };

    useEffect(() => {
        if (!searchValue && search) {
            Leavesdata()
        }
        return () => {
        };
    }, [searchValue]);

    useEffect(() => {
        Leavesdata()
    }, [page, dataperPage, order, year]);


    return (
        <>
            <div className="leave history-model">
                <Modal show={show} onHide={handleClose} className='text-center'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="primary" className='me-2' disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                        <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>
                    </Modal.Body>
                </Modal>
                <Modal show={editshow} onHide={handleEditClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leave history ({leaveHistortData?.user_id?.firstname + ' ' + leaveHistortData?.user_id?.last_name})</Modal.Title>
                    </Modal.Header>
                    {
                        <Modal.Body>
                            <Form id="form-wizard1" onSubmit={(e) => updateLeaveHistory(e)} >

                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="value">Total Leaves</Form.Label>
                                    <Form.Control className="" type='number' onChange={e => setTotalLeaves(e.target.value)} defaultValue={totalLeaves} id="value" required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="role">Taken Leaves</Form.Label>
                                    <Form.Control className="" disabled type='number' onChange={e => setTakenLeaves(e.target.value)} id="value" defaultValue={takenLeaves} required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="value">Remaining Leaves</Form.Label>
                                    <Form.Control className="" value={remainingLeaves} onChange={e => setRemainingLeaves(e.target.value)} type='number' id="value" required />
                                </Form.Group>
                                <Button variant="primary" className='me-2' type='submit' disabled={loading}>{loading ? 'Updating...' : 'Update'}</Button>
                                <Button variant="danger" type='reset' onClick={() => handleEditClose()} disabled={loading}>Cancel</Button>
                            </Form>
                        </Modal.Body>
                    }
                </Modal>
            </div>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Manage Employee Leaves</h4>
                            </div>
                        </Card.Header>

                        <Card.Header className={`d-flex justify-content-between`}>
                            <div className="row align-items-center w-100">
                                <div className="col-md-3">
                                    <div
                                        className="dataTables_length d-flex align-items-center"
                                        id="user-list-table_length"
                                        style={{ gap: '2%' }}
                                    >
                                        <label>Show</label>
                                        <select
                                            name="user-list-table_length"
                                            aria-controls="user-list-table"
                                            className="form-select form-select-sm"
                                            onChange={(e) => setPerPage(e.target.value)}
                                            defaultValue={entry}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        className="dataTables_length d-flex align-items-center"
                                        id="user-list-table_length"
                                        style={{ gap: '2%' }}
                                    >
                                        <label>Years</label>
                                        <select
                                            name="user-list-table_length"
                                            aria-controls="user-list-table"
                                            className="form-select form-select-sm"
                                            defaultValue={currentyear}
                                            onChange={(e) => setYear(e.target.value)}
                                            onLoad={(e) => setYear(e.target.value)}
                                        >
                                            <option value={`${moment().year() - 3}-${moment().year() - 2}`}>{moment().year() - 3}-{moment().year() - 2}</option>
                                            <option value={`${moment().year() - 2}-${moment().year() - 1}`}>{moment().year() - 2}-{moment().year() - 1}</option>
                                            <option value={`${moment().year() - 1}-${moment().year()}`}>{moment().year() - 1}-{moment().year()}</option>
                                            <option value={`${moment().year()}-${moment().year() + 1}`}>{moment().year()}-{moment().year() + 1}</option>


                                            {moment().month() > 2 && (
                                                <option value={`${moment().year() + 1}-${moment().year() + 2}`}>
                                                    {moment().year() + 1}-{moment().year() + 2}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div
                                        id="user-list-table_filter"
                                        className='d-flex justify-content-end'
                                    >
                                        <label>Search:</label>
                                        <input
                                            type="search"
                                            placeholder=""
                                            aria-controls="user-list-table"
                                            onChange={(event) => setSearchValue(event.target.value)}
                                            onKeyDown={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="user-list-table"
                                    className="table table-striped"
                                    role="grid"
                                    data-toggle="data-table"
                                >
                                    <thead>
                                        <tr className="ligth">
                                            <th>No</th>
                                            <th
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => sorting('firstname')}
                                            >
                                                <span className="title">Name</span>
                                            </th>
                                            <th>Year</th>
                                            <th>total leaves</th>
                                            <th>
                                                <span className="title">Taken leaves</span>
                                            </th>
                                            <th>
                                                <span className="title">Remaining Leaves</span>
                                            </th>
                                            <th min-width="100px">Action</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={20} className='text-center'>
                                                    <Spinner animation="border" variant="primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        employeeData?.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={20} className='text-center'>
                                                        No data found
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className="text-capitalize">
                                                {employeeData?.map((item, index) => {
                                                    let fullName = item?.userData?.firstname + " " + item?.userData?.last_name
                                                    return (
                                                        <tr key={index} className="border-light">
                                                            <td>{item.index}</td>
                                                            <td>{fullName}</td>
                                                            <td>{item.year}</td>
                                                            <td>{item.total_leaves}</td>
                                                            <td>
                                                                {!item.unpaid_leaves ? (
                                                                    <>{item.taken_leaves}</>
                                                                ) : (
                                                                    <>
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={<Tooltip id="tooltip-help">Unpaid Leaves:{item.unpaid_leaves}</Tooltip>}
                                                                        >
                                                                            <span className='text-danger' style={{ cursor: "pointer" }}><strong>{item.taken_leaves}</strong></span>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>{item.remaining_leaves}</td>
                                                            <td>
                                                                <NavLink className="btn btn-sm btn-icon btn-warning" style={{ marginRight: "5px" }} data-toggle="tooltip" onClick={() => handleEditClick(item._id)} data-placement="top" title="" data-original-title="Edit">
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </NavLink>
                                                                <NavLink onClick={() => deletemodel(item._id)} className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to='#'>
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </table>
                            </div>
                            <div className="row align-items-center w-100">
                                <div className="col-md-6">
                                    <div
                                        className="dataTables_length d-flex align-items-center mx-3"
                                        id="user-list-table_length"
                                    >
                                        {employeeData?.length > 0 && (
                                            <label>
                                                Showing {employeeData[0]?.index} to {lastEmployeeLeaveHistoryData[0]?.index} of {totalData} entries
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {employeeData?.length > 0 && (
                                        <ReactPaginate
                                            nextLabel="Next"
                                            onPageChange={PageChange}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={2}
                                            pageCount={totalPages}
                                            previousLabel="Previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination float-end"
                                            renderOnZeroPageCount={null}
                                        />
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default LeaveHistory;
