import FsLightbox from 'fslightbox-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/employeeprofile.css';
import icon1 from '../../assets/images/icons/01.png';
import icon2 from '../../assets/images/icons/02.png';
import icon4 from '../../assets/images/icons/04.png';
import icon5 from '../../assets/images/icons/05.png';
import icon8 from '../../assets/images/icons/08.png';
import shap2 from '../../assets/images/shapes/02.png';
import shap4 from '../../assets/images/shapes/04.png';
import shap6 from '../../assets/images/shapes/06.png';
import Card from '../../components/Card';
import '../Employee/edit-profile.css';

const AddSalaryStructure = ({ api }) => {

   const [toggler, setToggler] = useState(false);
   const navigate = useNavigate();
   const [validated, setValidated] = useState(false);
   const [employee, setEmployee] = useState([]);
   const [employeeDetail, setEmployeeDetail] = useState([]);
   const [employee_id, setEmployee_id] = useState('');
   const [year, setYear] = useState('');
   const [totalSalary, setTotalSalary] = useState('');
   const [basicSalary, setBasicSalary] = useState('');
   const [HRA, setHRA] = useState('');
   const [otherAllowance, setOtherAllowance] = useState('');
   const [other, setOther] = useState('');
   const [elEncashments, setElEncashments] = useState('');
   const [loading, setLoading] = useState(false);
   const [performanceAllowance, setPerformanceAllowance] = useState('');
   const [grossSalary, setGrossSalary] = useState('');
   const [ProfessionalTax, setProfessionalTax] = useState('');
   const [incomeTax, setIncomeTax] = useState('');
   const [gratuity, setGratuity] = useState('');
   const [providentFund, setProvidentFund] = useState('');
   const [bonus, setBonus] = useState('');
   const [ESIC, setESIC] = useState('');
   const [OtherDeduction, setOtherDeduction] = useState('');
   const [totalDeduction, setTotalDeduction] = useState('');
   const [netSalary, setNetSalary] = useState('');

   useEffect(() => {
      if (totalSalary) {
         const basicSalarycals = (totalSalary * 0.4);
         const HRAvalue = (totalSalary * 0.2);
         const otherAllowancevalue = (totalSalary * 0.2);
         const performanceAllowancevalue = (totalSalary * 0.2);
         setBasicSalary(Math.round(basicSalarycals));
         setHRA(Math.round(HRAvalue));
         setOtherAllowance(Math.round(otherAllowancevalue));
         setPerformanceAllowance(Math.round(performanceAllowancevalue));
      } else {
         setBasicSalary('');
         setHRA('');
         setOtherAllowance('');
         setPerformanceAllowance('');
      }
   }, [totalSalary]);

   useEffect(() => {
      if (ProfessionalTax || incomeTax || gratuity || providentFund || ESIC || OtherDeduction) {
         setTotalDeduction(+(ProfessionalTax) + +(incomeTax) + +(gratuity) + +(providentFund) + +(ESIC) + +(OtherDeduction))
      } else {
         setTotalDeduction('');
      }
   }, [ProfessionalTax, incomeTax, gratuity, providentFund, ESIC, OtherDeduction]);

   useEffect(() => {
      if (basicSalary || HRA || otherAllowance || performanceAllowance || other || bonus || elEncashments) {
         setGrossSalary(+(basicSalary) + +(HRA) + +(performanceAllowance) + +(bonus) + +(elEncashments) + +(otherAllowance) + +(other))
      } else {
         setGrossSalary('');
      }
   }, [basicSalary, HRA, otherAllowance, performanceAllowance, bonus, elEncashments, other, grossSalary]);

   useEffect(() => {
      if (totalDeduction || grossSalary) {
         setNetSalary((+grossSalary) - (+totalDeduction))
      } else {
         setGrossSalary('');
      }
   }, [totalDeduction, grossSalary]);

   useEffect(() => {
      api.get(`/addSalaryStructure`).then((response) => {
         if (response.data.status === false) {
            navigate('/403')
         }
         setEmployee(response.data.userData);
      }).catch((err) => {
      })
      return () => { };
   }, []);

   useEffect(() => {
      if (employee_id) {
         api.post(`/getUserData`, { user: employee_id }).then((response) => {
            setEmployeeDetail(response.data.userData)
         }).catch((err) => {
         })
      } else {
         setEmployeeDetail([]);
      }
      return () => { };
   }, [employee_id]);

   const AddSalaryStructure = (e) => {
      e.preventDefault();
      setLoading(true);
      const form = e.currentTarget;
      const isValid = form.checkValidity();
      if (isValid) {
         api.post(`/addSalaryStructure`, {
            user_id: employee_id,
            Basic_Salary: basicSalary,
            House_Rent_Allow: HRA,
            Other_Allownces: otherAllowance,
            Performance_Allownces: performanceAllowance,
            Bonus: bonus,
            Other: other,
            EL_Encash_Amount: elEncashments,
            Professional_Tax: ProfessionalTax,
            Income_Tax: incomeTax,
            Gratuity: gratuity,
            Provident_Fund: providentFund,
            ESIC: ESIC,
            Total_Salary: totalSalary,
            Gross_Salary: grossSalary,
            Total_Deduction: totalDeduction,
            Net_Salary: netSalary,
            Other_Deduction: OtherDeduction,
            year
         }).then(() => {
            navigate('/salary-structure')
         }).catch((err) => {
            setLoading(false);
         })

      } else {
         setValidated(true);
         setLoading(false);
      }
   }

   return (
      <>
         <FsLightbox
            toggler={toggler}
            sources={[icon4, shap2, icon8, shap4, icon2, shap6, icon5, shap4, icon1]}
         />
         <div className="profile-data">
            <Tab.Container defaultActiveKey="first">
               <Row className='edit-personal-profile'>
                  <Form noValidate validated={validated} id="form-wizard1" onSubmit={(e) => AddSalaryStructure(e)}>
                     <Col lg="12" md="12">
                        <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">
                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                       <h4 className="me-2 h4">Salary Structure</h4>
                                    </div>
                                 </div>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col lg="12" md="12">

                        <Card>
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Employee Detail</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <Row>
                                 <Col sm="12" lg="4">
                                    <Form.Label htmlFor="permission_description">Employee Name : <span className='text-danger'>*</span></Form.Label>
                                    <select name="" id="" className="form-select form-control-sm mb-3 shadow-none form-control" required onChange={(e) => setEmployee_id(e.target.value)}>
                                       <option value="">Select</option>
                                       {
                                          employee && employee.map((item, index) => (
                                             <option value={item._id} key={index} className='text-capitilize'>{item.firstname + ' ' + item.last_name}</option>
                                          ))
                                       }
                                    </select>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Label htmlFor="permission_description">Year : <span className='text-danger'>*</span></Form.Label>
                                    <select name="" id="" className="form-select form-control-sm mb-3 shadow-none form-control" required onChange={(e) => setYear(e.target.value)}>
                                       <option value="">select</option>
                                       <option value="2022-2023">2022-2023</option>
                                       <option value="2023-2024">2023-2024</option>
                                       <option value="2024-2025">2024-2025</option>
                                       <option value="2025-2026">2025-2026</option>
                                    </select>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Employee Code : <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={employeeDetail && employeeDetail.emp_code} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Designation : <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={employeeDetail && employeeDetail.roleName && employeeDetail.roleName[0]} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Joining : <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required defaultValue={employeeDetail.doj && new Date(employeeDetail.doj).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }).replace(/\//g, "-")} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Pan Number : </Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={employeeDetail && employeeDetail.pan_number} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Bank Name : <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={employeeDetail && employeeDetail.bank_name} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Bank Account NUmber : <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={employeeDetail && employeeDetail.bank_account_no} />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>

                              </Row>
                           </Card.Body>
                        </Card>
                        <Card>
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Salary Particular</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <Row>
                                 <Col sm="12" lg="4">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="role">Total Salary: <span className='text-danger'>*</span></Form.Label>
                                       <Form.Control type="text" id="title" onChange={(e) => setTotalSalary(e.target.value)} required />
                                       <Form.Control.Feedback type="invalid" >
                                          This field is required
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </Col>
                                 <Row>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Basic Salary : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" defaultValue={basicSalary} className='form-control-sm' id="title" required disabled />
                                          <Form.Control.Feedback type="invalid" onChange={(e) => setBasicSalary(e.target.value)}>
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Professional Tax : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setProfessionalTax(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">HRA : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={HRA} />
                                          <Form.Control.Feedback type="invalid" onChange={(e) => setHRA(e.target.value)}>
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Income Tax : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setIncomeTax(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Other Allowances : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required disabled
                                             defaultValue={otherAllowance}
                                             onChange={(e) => setOtherAllowance(e.target.value)}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Gratuity : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setGratuity(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Performance Allowances : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={performanceAllowance}
                                             onChange={(e) => setPerformanceAllowance(e.target.value)}
                                          />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Provident Fund : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setProvidentFund(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Bonus : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setBonus(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">ESIC : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setESIC(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Other : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setOther(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Other Deduction : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setOtherDeduction(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">EL Encashments : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required onChange={(e) => setElEncashments(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Total Deduction : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" defaultValue={totalDeduction} className='form-control-sm' id="title" required disabled onChange={(e) => setTotalDeduction(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Gross Salary : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" className='form-control-sm' id="title" required disabled defaultValue={grossSalary} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="6" lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="role">Net Salary : <span className='text-danger'>*</span></Form.Label>
                                          <Form.Control type="text" defaultValue={netSalary} className='form-control-sm' id="title" required disabled onChange={(e) => setNetSalary(e.target.value)} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="12" className='d-flex justify-content-center mt-3'>

                                       <div className='salary-btns me-3'>
                                          <Button type='submit' className='btn btn-primary' disabled={loading}> {loading ? 'Loading...' : 'Save'}</Button>
                                       </div>
                                       <div className='salary-btns'>
                                          <Link to='/salary-structure' type='cancel' className='btn btn-danger'>Cancel</Link>
                                       </div>

                                    </Col>
                                 </Row>
                              </Row>
                           </Card.Body>

                        </Card>
                     </Col>
                  </Form>
               </Row>
            </Tab.Container>
         </div>
      </>
   )

}
export default AddSalaryStructure



