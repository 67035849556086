import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast } from "../../plugins/toastUtils";

const LeaveList = ({ api, user_data }) => {
  const Permissions = useContext(PermissionData);
  const [leaves, setLeaves] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);
  const [entry] = useState(10);
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalData, setTotalData] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [user, setUser] = useState("");
  const [month, setMonth] = useState("");
  const [search, setSearch] = useState(false);
  const [dataperPage, setDataperPage] = useState(entry);
  const [order, setOrder] = useState("ASC");
  const [status, setStatus] = useState("");
  const currentyear = `${moment().month() > 2 ? moment().year() : moment().year() - 1
    }-${moment().month() > 2 ? moment().year() + 1 : moment().year()}`;
  const [year, setYear] = useState(currentyear);
  const navigate = useNavigate();
  let lastleaveData = leaves?.slice(-1);

  const LeavesData = async () => {
    setLoading(true);
    await api
      .get(
        `/leaves?page=${page}&limit=${dataperPage}&nameSort=${order}&status=${status}&year=${year}&month=${month}&user_id=${user}&search=${searchValue}`
      )
      .then((response) => {
        if (response.data !== undefined) {
          if (response.data.status === false) {
            navigate("/403");
          } else {
            setLeaves(response.data.leavesData);
            setTotalPages(response.data.totalPages);
            setTotalData(response.data.totalData);
            setUserData(response.data.userData);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 403) {
          navigate("/403");
        }
        showToast(error.response.data.errors, "error");
      });
  };

  const handleCancel = async () => {
    const id = cancelId;
    setLoading(true);
    if (id !== undefined) {
      await api
        .post(`/cancelLeaves/${id}`, "")
        .then((response) => {
          showToast(response.data.message, "success");
          setCancelShow(false);
          setShow(false);
          LeavesData();
        })
        .catch((error) => {
          showToast(error.response.data.errors, "error");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const HandleDelete = () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      api
        .post(`/deleteLeaves/${id}`, "")
        .then((response) => {
          LeavesData();
          setTimeout(() => {
            showToast(response.data.message, "success");
          }, 300);
          setShow(false);
        })
        .catch((error) => {
          showToast(error.response.data.errors, "error");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCancelClose = () => {
    setCancelShow(false);
  };

  function cancelmodal(id) {
    setCancelId(id);
    setCancelShow(true);
  }

  function Deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const perpagedata = (e) => {
    setDataperPage(e.target.value);
  };

  const HandleMonth = (e) => {
    setMonth(parseInt(e.target.value));
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      LeavesData();
    }
  };

  useEffect(() => {
    if (!searchValue && search) {
      LeavesData();
    }
    return () => { };
  }, [searchValue]);

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  useEffect(() => {
    LeavesData();
    return () => { };
  }, [page, dataperPage, order, month, year, user, status]);

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="primary"
                className="me-2"
                disabled={loading}
                onClick={() => HandleDelete()}
              >
                {loading ? "Deleting..." : "Delete"}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            show={cancelShow}
            onHide={handleCancelClose}
            className="text-center"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to Cancel it ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="primary"
                className="me-2"
                disabled={loading}
                onClick={() => handleCancel()}
              >
                {loading ? "cancelling..." : "OK"}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleCancelClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Leaves</h4>
                </div>
                <div className="Add-user">
                  <Link to="add">
                    {" "}
                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Add Leave Request
                    </h4>
                  </Link>
                </div>
              </Card.Header>
              <div className="p-3 mt-4">
                <div className="row">
                  <div className="col-md-2 mb-4">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                    >
                      <label className="me-2">Show:</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        onChange={(e) => perpagedata(e)}
                        defaultValue={entry}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-4">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                    >
                      <label className="me-2">Status:</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="APPROVED">Approved</option>
                        <option value="PENDING">Pending</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="CANCELED">Canceled</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-4">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                    >
                      <label className="me-2">Month:</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm w-50"
                        onChange={(e) => HandleMonth(e)}
                      >
                        <option value="">All</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-4">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                    >
                      <label className="me-2">Year:</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        defaultValue={currentyear}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <option
                          value={`${moment().year() - 3}-${moment().year() - 2
                            }`}
                        >
                          {moment().year() - 3}-{moment().year() - 2}
                        </option>
                        <option
                          value={`${moment().year() - 2}-${moment().year() - 1
                            }`}
                        >
                          {moment().year() - 2}-{moment().year() - 1}
                        </option>
                        <option
                          value={`${moment().year() - 1}-${moment().year()}`}
                        >
                          {moment().year() - 1}-{moment().year()}
                        </option>
                        <option
                          value={`${moment().year()}-${moment().year() + 1}`}
                        >
                          {moment().year()}-{moment().year() + 1}
                        </option>

                        {moment().month() > 2 && (

                          <option
                            value={`${moment().year() + 1}-${moment().year() + 2}`}
                          >
                            {moment().year() + 1}-{moment().year() + 2}

                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  {user_data?.roleData[0]?.role_name === "Admin" && (
                    <div className="col-md-2 mb-4">
                      <div>
                        <div
                          className="dataTables_length d-flex align-items-center"
                          id="user-list-table_length"
                        >
                          <label className="me-2">Employee:</label>
                          <select
                            name="user-list-table_length"
                            aria-controls="user-list-table"
                            className="form-select form-select-sm"
                            onChange={(e) => setUser(e.target.value)}
                          >
                            <option value="">All</option>
                            {userData &&
                              userData.map((item, index) => (
                                <option
                                  value={item._id}
                                  key={index + "userName"}
                                >
                                  {" "}
                                  {item.firstname + " " + item.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={`${user_data?.roleData[0]?.role_name === "Admin" ? 'col-md-2' : 'col-md-3 offset-md-1'} mb-4`}>
                    <div
                      id="user-list-table_filter"
                      className="dataTables_filter d-flex align-items-center"
                    >
                      <label className="me-2">Search:</label>
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="user-list-table"
                        onChange={(event) => setSearchValue(event.target.value)}
                        onKeyDown={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>
                          <span className="title">No</span>
                        </th>
                        {user_data?.roleData[0]?.role_name === "Admin" && (
                          <th>
                            <span className="title">User Name</span>
                          </th>
                        )}
                        <th>
                          <span
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={() => sorting("reason")}
                          >
                            Reason
                          </span>
                        </th>
                        <th>
                          <span className="title">From</span>
                        </th>
                        <th>
                          <span className="title">To</span>
                        </th>
                        <th>
                          <span className="title">Days</span>
                        </th>
                        <th>
                          <span className="title">Status</span>
                        </th>
                        <th>
                          <span className="title">Paid Status</span>
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={20} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : leaves?.length === 0 ? (
                      <tbody>
                        <tr>
                          <td colSpan={20} className="text-center">
                            No search results found
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="text-capitalize">
                        {leaves?.map((item, index) => (
                          <tr key={index + "userData"} className="border-light">
                            <td>{item.index}</td>
                            {user_data?.roleData[0]?.role_name === "Admin" && (
                              <td>
                                {item.userData.firstname +
                                  " " +
                                  item.userData.last_name}
                              </td>
                            )}
                            <td>{item.reason}</td>
                            <td>
                              {moment(item.datefrom).format("DD/MM/YYYY")}
                            </td>
                            <td>{moment(item.dateto).format("DD/MM/YYYY")}</td>
                            <td>{item.total_days}</td>
                            <td>
                              <span
                                className={`badge ${item.status === "PENDING"
                                  ? "bg-warning"
                                  : item.status === "REJECTED"
                                    ? "bg-danger"
                                    : item.status === "APPROVED"
                                      ? "bg-success"
                                      : "bg-primary"
                                  } p-2`}
                                style={{ fontWeight: "200" }}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge ${item.paid_status === "PAID"
                                  ? "bg-success"
                                  : item.paid_status === "UNPAID"
                                    ? "bg-danger"
                                    : "bg-primary"
                                  } p-2`}
                                style={{ fontWeight: "200" }}
                              >
                                {item.paid_status}
                              </span>
                            </td>

                            {Permissions.includes("Update Leaves") ||
                              user_data?.roleData[0]?.role_name === "Admin" ||
                              Permissions.includes("Delete Leaves") ? (
                              (Permissions.includes("Update Leaves") ||
                                Permissions.includes("Delete Leaves")) && (
                                <td>
                                  <div>
                                    {Permissions.includes("Update Leaves") && (
                                      <Link
                                        className="btn btn-sm btn-icon btn-warning"
                                        style={{ marginRight: "5px" }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        to={`edit/${item._id}/${item.userData._id}`}
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M15.1655 4.60254L19.7315 9.16854"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}
                                    {Permissions.includes("Delete Leaves") && (
                                      <Link
                                        onClick={() => Deletemodel(item._id)}
                                        className=" btn btn-sm btn-icon btn-danger"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        to="#"
                                        style={{ marginRight: "12%" }}
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="currentColor"
                                          >
                                            <path
                                              d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M20.708 6.23975H3.75"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}
                                    {item.status === "PENDING" ? (
                                      <Link
                                        onClick={() => cancelmodal(item._id)}
                                        className="btn btn-sm btn-icon btn-danger"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        to="#"
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="currentColor"
                                          >
                                            <path
                                              d="M14.3955 9.59497L9.60352 14.387"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M14.3971 14.3898L9.60107 9.59277"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </Link>
                                    ) : item.status === "APPROVED" ? (
                                      <button
                                        className="btn btn-sm btn-icon btn-danger"
                                        disabled={true}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="This action is disabled for approved items"
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="currentColor"
                                          >
                                            <path
                                              d="M14.3955 9.59497L9.60352 14.387"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M14.3971 14.3898L9.60107 9.59277"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    ) : null}
                                  </div>
                                </td>
                              )
                            ) : item.status === "PENDING" ? (
                              <td>
                                <div
                                  className="flex align-items-center list-user-action"
                                  style={{ marginLeft: "8%" }}
                                >
                                  <Link
                                    onClick={() => cancelmodal(item._id)}
                                    className="btn btn-sm btn-icon btn-danger"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Delete"
                                    to="#"
                                  >
                                    <span className="btn-inner">
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        stroke="currentColor"
                                      >
                                        <path
                                          d="M14.3955 9.59497L9.60352 14.387"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14.3971 14.3898L9.60107 9.59277"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </Link>
                                </div>
                              </td>
                            ) : item.status === "APPROVED" ? (
                              <td>
                                <div
                                  className="flex align-items-center list-user-action"
                                  style={{ marginLeft: "8%" }}
                                >
                                  <button
                                    className="btn btn-sm btn-icon btn-danger"
                                    disabled={true}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="This action is disabled for approved items"
                                  >
                                    <span className="btn-inner">
                                      <svg
                                        width="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        stroke="currentColor"
                                      >
                                        <path
                                          d="M14.3955 9.59497L9.60352 14.387"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14.3971 14.3898L9.60107 9.59277"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="row align-items-center w-100 mt-2 p-0 m-0">
                  <div className="col-md-6">
                    <div
                      className="dataTables_length d-flex align-items-center mx-3"
                      id="user-list-table_length"
                    >
                      {leaves?.length > 0 && (
                        <label>
                          Showing {leaves[0]?.index} to{" "}
                          {lastleaveData[0]?.index} of {totalData} entries
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ReactPaginate
                      nextLabel="Next"
                      onPageChange={PageChange}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={totalPages}
                      previousLabel="Previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination float-end"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainerWrapper />
        </Row>
      </div>
    </>
  );
};

export default LeaveList;
