import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Image, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import auth5 from '../../assets/images/auth/05.png'
import auth7 from '../../assets/images/auth/07.png'
import Card from '../../components/Card'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'
import api from '../../utils/api'

const SetPassword = () => {
   let navigate = useNavigate();
   const { id } = useParams();
   const [password, setPassword] = useState("");
   const [cpassword, setCpassword] = useState("");
   const [validated, setValidated] = useState(false);
   const [passwordError, setPasswordError] = useState(false);

   useEffect(() => {
      if (cpassword) {
         if (cpassword !== password) {
            setPasswordError(true);
         }
         else {
            setPasswordError(false);
         }
      } else {
         setPasswordError(false);
      }
      return () => {
      };
   }, [cpassword, password]);

   const ActiveAccount = async (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      const isValid = form.checkValidity();
      if (isValid && passwordError === false) {
         api.post(`/active-account/${id}`, {
            password: password,
            cpassword: cpassword
         })
            .then((response) => {
               navigate('/login')
               setTimeout(() => {
                  showToast(response.data.message, "success");
               }, 300)
            }).catch((error) => {
               showToast(error.response.data.message,'error');
            });
      }
      else {
         setValidated(true)
      }
   }

   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </div>
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                           <Card.Body>
                              <Link className="navbar-brand d-flex align-items-center mb-3">
                                 <img src={auth7} alt="" height={50} width={50} />
                                 <h4 className="logo-title ms-3">Set Password</h4>
                              </Link>

                              <Form id="form-wizard1" onSubmit={e => ActiveAccount(e)} noValidate validated={validated}>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="full-name" className="">Enter Your Password</Form.Label>
                                          <Form.Control type="text" className={`form-control`} id="full-name" placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                                          <Form.Control.Feedback type="invalid">
                                             Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="full-name" className="">Confirm Password</Form.Label>
                                          <Form.Control type="text" className={`form-control`} id="full-name" placeholder="confirm password" onChange={(e) => setCpassword(e.target.value)} required
                                             style={passwordError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}

                                          />
                                       </Form.Group>
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <Button type="submit" variant="primary">Activate</Button>
                                 </div>
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <div className="sign-bg sign-bg-right">
                     <img src={auth7} alt="" height="50%" width="50%" style={{ opacity: '0.15', position: 'relative', left: '13rem' }} />
                  </div>
               </Col>
            </Row>
         </section>
         <ToastContainerWrapper />
      </>
   )
}

export default SetPassword
