import FsLightbox from 'fslightbox-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/employeeprofile.css';
import icon1 from '../../assets/images/icons/01.png';
import icon2 from '../../assets/images/icons/02.png';
import icon4 from '../../assets/images/icons/04.png';
import icon5 from '../../assets/images/icons/05.png';
import icon8 from '../../assets/images/icons/08.png';
import shap2 from '../../assets/images/shapes/02.png';
import shap4 from '../../assets/images/shapes/04.png';
import shap6 from '../../assets/images/shapes/06.png';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';
import '../Employee/edit-profile.css';

const EditPersonalProfile = ({ api, imagePath }) => {
   const { id } = useParams();
   const navigate = useNavigate();
   const [firstname, setFirstname] = useState("");
   const [middle_name, setMiddle_name] = useState("");
   const [last_name, setLast_name] = useState("");
   const [mo_number, setMo_number] = useState("");
   const [dob, setDob] = useState("");
   const [gender, setGender] = useState("");
   const [company_email, setCompany_email] = useState("");
   const [doj, setDoj] = useState("");
   const [role_id, setRole_id] = useState("");
   const [emp_code, setEmp_code] = useState("");
   const [pan_number, setPan_number] = useState("");
   const [aadhar_number, setAadhar_number] = useState("");
   const [add_1, setAdd_1] = useState("");
   const [add_2, setAdd_2] = useState("");
   const [city, setCity] = useState("");
   const [state, setState] = useState("");
   const [pincode, setPincode] = useState("");
   const [country, setCountry] = useState("");
   const [bank_name, setBank_name] = useState("");
   const [ifsc_code, setIfsc_code] = useState("");
   const [bank_account_no, setBank_account_no] = useState("");
   const [user_name, setUser_name] = useState("");
   const [personal_email, setPersonal_email] = useState("");
   const [photo, setPhoto] = useState("");
   const [imagePreview, setImagePreview] = useState('');
   const [validated, setValidated] = useState(false);
   const [imageError, setImageError] = useState(false);
   const [loading, setLoading] = useState(false);

   const UpdateProfilePic = async (e) => {
      const selectedPhoto = e.target.files[0];
      setPhoto(selectedPhoto);
      setImagePreview('');
      const imageObjectURL = URL.createObjectURL(selectedPhoto);
      setImagePreview(imageObjectURL);
   }

   const ChangeProfilePic = async () => {
      const formData = new FormData();
      formData.set('image', photo);
      try {
         await api.post(`/userphoto/${id}`, formData).then((response) => {
            if (response.data.status === false) {
               setImageError(true);
            }
            else {
               setImageError(false);
               setPhoto(response.data.photo);
            }
         })
      } catch (error) {
         showToast(error.response.data.errors, 'error');
      }
   }

   useEffect(() => {
      api.get(`/profile/${id}`).then((response) => {
         if (response.data.userData[0] !== undefined) {
            if (response.data.status === false) {
               navigate('/403')
            }
            setFirstname(response.data.userData[0].firstname)
            setMiddle_name(response.data.userData[0].middle_name)
            setLast_name(response.data.userData[0].last_name)
            setMo_number(response.data.userData[0].mo_number)
            setGender(response.data.userData[0].gender)
            setAdd_1(response.data.userData[0].add_1)
            setAdd_2(response.data.userData[0].add_2)
            setCompany_email(response.data.userData[0].company_email)
            setEmp_code(response.data.userData[0].emp_code)
            setPan_number(response.data.userData[0].pan_number)
            setAadhar_number(response.data.userData[0].aadhar_number)
            setCountry(response.data.userData[0].country)
            setRole_id(response.data.userData[0].roleData[0].role_name)
            setCity(response.data.userData[0].city)
            setState(response.data.userData[0].state)
            setPincode(response.data.userData[0].pincode)
            setBank_name(response.data.userData[0].bank_name)
            setIfsc_code(response.data.userData[0].ifsc_code)
            setBank_account_no(response.data.userData[0].bank_account_no)
            setUser_name(response.data.userData[0].user_name)
            setPersonal_email(response.data.userData[0].personal_email)
            setImagePreview(imagePath + response.data.userData[0].photo)
            const bdate = new Date(response.data.userData[0].dob);
            const dformattedDate = `${bdate.getFullYear()}-${(bdate.getMonth() + 1).toString().padStart(2, '0')}-${bdate.getDate().toString().padStart(2, '0')}`;
            setDob(dformattedDate);
            const jdate = new Date(response.data.userData[0].doj);
            const jformattedDate = `${jdate.getFullYear()}-${(jdate.getMonth() + 1).toString().padStart(2, '0')}-${jdate.getDate().toString().padStart(2, '0')}`;
            setDoj(jformattedDate);
         }
      }).catch((error) => {
         console.log('error', error);
      });
      return () => {
      };
   }, []);

   useEffect(() => {
      return () => {
      };
   }, [imageError]);

   const UpdateProfile = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const isValid = form.checkValidity();
      if (isValid) {
         if (photo) {
            ChangeProfilePic();
         }
         setLoading(true);
         await api.post(`/checkUpdateEmail`, {
            personal_email: personal_email,
            user_id: id
         }).then(async (response) => {
            setLoading(false);
            if (response.data.status === true) {
            }
            else {
               await api.post(`/profile/${id}`, {
                  role_id: role_id,
                  emp_code: emp_code,
                  firstname: firstname,
                  user_name: user_name,
                  middle_name: middle_name,
                  last_name: last_name,
                  gender: gender,
                  dob: dob,
                  doj: doj,
                  personal_email: personal_email,
                  company_email: company_email,
                  mo_number: mo_number,
                  pan_number: pan_number,
                  aadhar_number: aadhar_number,
                  add_1: add_1,
                  add_2: add_2,
                  city: city,
                  state: state,
                  pincode: pincode,
                  country: country,
                  bank_account_no: bank_account_no,
                  bank_name: bank_name,
                  ifsc_code: ifsc_code,
               }).then((response) => {
                  navigate('/personal-profile');
                  setTimeout(() => {
                     showToast(response.data.message, "success")
                  }, 300);
               }).catch((error) => {
                  showToast(error.response.data.errors, 'error');
               });
            }
         })
      } else {
         setValidated(true)
         setLoading(false);
      }
   }

   return (
      <>
         <FsLightbox
            sources={[icon4, shap2, icon8, shap4, icon2, shap6, icon5, shap4, icon1]}
         />
         <div className="profile-data">
            <Tab.Container defaultActiveKey="first">
               <Row className='edit-profile'>
                  <Form noValidate validated={validated} id="form-wizard1" onSubmit={(event) => UpdateProfile(event)}>
                     <Col lg="12" md="12">
                        <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">
                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                       <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <Image className="theme-color-green-img img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100 profile-pic" src={imagePreview}
                                          alt="profile-pic" />
                                       <div className='choose-image'>
                                          <p className="choose-image-text">Choose Image</p>
                                          <input type="file" name="image" id="image-input" onChange={(e) => UpdateProfilePic(e)} />
                                       </div>
                                       {imageError === true ?
                                          <p className='text-danger'>Invalid Image Format</p>
                                          :
                                          ''}
                                    </div>
                                 </div>
                                 <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab bg-transparent" data-toggle="slider-tab"
                                    id="profile-pills-tab" role="tablist">

                                    <Nav.Item as="li">
                                       <button type='Cancel' className='btn btn-danger me-2' onClick={() => navigate('/personal-profile')}>Cancel</button>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                       <Button type='submit' disabled={loading} className='btn btn-primary active'>{loading ? 'Updating...' : 'Update'}</Button>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col lg="12" md="12">
                        <Card>
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Personal information</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <Row>
                                 <div className='part-1 col-lg-6 col-md-6'>
                                    <div className="mt-2">
                                       <h6 className="mb-1">First name:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control" name="user_name" id='username'
                                             placeholder="" onChange={e => setFirstname(e.target.value)}
                                             value={firstname && firstname} required />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Middle name:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setMiddle_name(e.target.value)}
                                             value={middle_name && middle_name} required />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Last name:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setLast_name(e.target.value)}
                                             value={last_name && last_name} required />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Contact number:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setMo_number(e.target.value)}
                                             value={mo_number && mo_number} required />
                                          <Form.Control.Feedback type="invalid">Invalid Contact number </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Date of birth:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="date" className="form-control  position-relative"
                                             name="user_name" id='username' placeholder="" onChange={e => setDob(e.target.value)} required
                                             value={dob && dob} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Gender:</h6>
                                       <div className="edit-data w-75 d-flex" style={{ gap: "5%" }}>
                                          <Form.Check className="d-block">
                                             {gender === 'male' ?
                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('male')} defaultChecked={true} /> :
                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('male')} />}
                                             <Form.Check.Label htmlFor="flexRadioDefault1">
                                                Male
                                             </Form.Check.Label>
                                          </Form.Check>
                                          <Form.Check className="d-block">
                                             {gender === 'female' ?
                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('female')} defaultChecked={true} /> :
                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('female')} />}
                                             <Form.Check.Label htmlFor="flexRadioDefault1">
                                                Female
                                             </Form.Check.Label>
                                          </Form.Check>
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Personal email:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setPersonal_email(e.target.value)}
                                             value={personal_email && personal_email} disabled />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Joining Date:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="date" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setDoj(e.target.value)}
                                             value={doj && doj} disabled min={'2018-01-01'}
                                             max={new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0]}
                                             required
                                          />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Role:</h6>
                                       <div className="edit-data w-75">
                                          < Form.Control disabled type="text" className="form-control" name="user_name" id='username' placeholder=""
                                             onChange={e => setRole_id(e.target.value)} value={role_id && role_id} />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="part-2 col-lg-6 col-md-6">
                                    <div className="mt-2">
                                       <h6 className="mb-1">Employee Code:</h6>
                                       <div className="edit-data w-75">
                                          < Form.Control disabled type="text" className="form-control" name="user_name" id='username' placeholder=""
                                             onChange={e => setEmp_code(e.target.value)} value={emp_code && emp_code} />
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Pancard number:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setPan_number(e.target.value)}
                                             value={pan_number && pan_number} disabled />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Aadhar number:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setAadhar_number(e.target.value)}
                                             value={aadhar_number && aadhar_number} disabled />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 1:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setAdd_1(e.target.value)}
                                             value={add_1 && add_1} required />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 2:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setAdd_2(e.target.value)} value={add_2 && add_2} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">City:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control" name="user_name" id='username'
                                             placeholder="" onChange={e => setCity(e.target.value)} value={city && city} />
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">State:</h6>
                                       <div className="edit-data w-75">
                                          < Form.Control type="text" className="form-control" name="user_name" id='username' placeholder=""
                                             onChange={e => setState(e.target.value)} value={state && state} />
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Country:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control" name="user_name" id='username' placeholder=""
                                             onChange={e => setCountry(e.target.value)} value={country && country} />
                                       </div>
                                    </div>
                                    <div className="mt-2">
                                       <h6 className="mb-1">Pincode:</h6>
                                       <div className="edit-data w-75">
                                          <Form.Control type="text" className="form-control " name="user_name" id='username'
                                             placeholder="" onChange={e => setPincode(e.target.value)}
                                             value={pincode && pincode} />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </div>
                                    </div>
                                 </div>
                              </Row>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Row>
                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Banking Detail</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-2'>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Bank name:</h6>
                                    <div className="edit-data w-75">
                                       <Form.Control type="text" className="form-control " name="user_name" id='username' placeholder=""
                                          onChange={e => setBank_name(e.target.value)} value={bank_name && bank_name} disabled />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </div>
                                 </div>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Ifsc Code:</h6>
                                    <div className="edit-data w-75">
                                       <Form.Control type="text" className="form-control " name="user_name" id='username' placeholder=""
                                          onChange={e => setIfsc_code(e.target.value)} value={ifsc_code && ifsc_code} disabled />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </div>
                                 </div>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Account number:</h6>
                                    <div className="edit-data w-75">
                                       <Form.Control type="text" className="form-control " name="user_name" id='username' placeholder=""
                                          onChange={e => setBank_account_no(e.target.value)} value={bank_account_no && bank_account_no} disabled />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </div>
                                 </div>
                              </Card.Body>
                           </Card>
                        </Col>
                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Login Information</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-3'>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Username:</h6>
                                    <div className="edit-data w-75">
                                       <Form.Control type="text" className="form-control " name="user_name" id='username' placeholder=""
                                          onChange={e => setUser_name(e.target.value)} value={user_name && user_name} disabled />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </div>
                                 </div>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Email address:</h6>
                                    <div className="edit-data w-75">
                                       <Form.Control type="email" className="form-control " name="user_name" id='username' placeholder=""
                                          onChange={e => setCompany_email(e.target.value)} value={company_email && company_email} disabled />
                                       <Form.Control.Feedback type="invalid">
                                          This field is required
                                       </Form.Control.Feedback>
                                    </div>
                                 </div>
                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </Form>
                  <ToastContainerWrapper />
               </Row>
            </Tab.Container>
         </div>
      </>
   )
}

export default EditPersonalProfile;