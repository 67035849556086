import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

const ViewProject = ({ api, user_data }) => {

    let currentYear = new Date().getFullYear();
    const Navigate = useNavigate();
    const { id } = useParams();
    const [projectId, setProjectId] = useState(id)
    const [projectTimeEntryData, setProjectTimeEntryData] = useState("")
    const [projectData, setprojectData] = useState("")
    const [user, setUser] = useState('');
    const [userList, setUserList] = useState([]);
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [isCardOpen, setIsCardOpen] = useState(null);

    const viewProjectsDetails = async () => {
        try {
            const response = await api.get(`/getTimeEntryDataByProject?project_id=${projectId}&user_id=${user}&year=${year}&month=${month}`, '');
            setProjectTimeEntryData(response.data.projectHoursData)
            setprojectData(response.data.projectData)
            setUserList(response.data.userData)
            setProjectList(response.data.allProjectData)
        } catch (error) {
            console.log("error", error)
        }
    };

    const getFullMonthName = (dateString) => {
        const [year, month] = dateString?.split('-');
        const monthIndex = parseInt(month, 10) - 1;
        const fullMonthName = new Date(year, monthIndex, 1).toLocaleString('en-US', { month: 'long' });
        return `${fullMonthName}-${year}`;
    }

    const formatTwoDigits = (value) => {
        if (value < 1 || value >= 10) {
            return value;
        } else {
            return value?.toFixed(1);
        }
    };

    const HandleYear = (e) => {
        setYear(e.target.value);
    }

    const HandleMonth = (e) => {
        setMonth(e.target.value);
    }

    const handleProject = (e) => {
        setProjectId(e.target.value);
    }

    const getTotalEstimatedHours = (hoursData) => {
        let totalEstimatedHours = 0
        hoursData && hoursData.map((hours) => {
            totalEstimatedHours += hours.totalTaskEstimationHoursMonth
        })
        return totalEstimatedHours
    }

    const getTotalHours = (hoursData) => {
        let totalHours = 0
        hoursData && hoursData.map((hours) => {
            totalHours += hours.totalHoursMonth
        })
        return totalHours
    }

    const handlePlusButtonClick = (id) => {
        setIsCardOpen(prevId => prevId === id ? null : id)
    };

    useEffect(() => {
        viewProjectsDetails()
    }, [projectId, user, month, year]);

    return (
        <>
            <Row>
                <Col lg="12" md="12">
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h2 className="card-title">Project TimeEntry Data</h2>
                                <button type='Cancel' onClick={() => Navigate('/project')} className='btn btn-danger'>Cancel</button>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="monthSelect" className="form-label">Select Month</label>
                                    <select
                                        id="monthSelect"
                                        className="form-select form-select-sm"
                                        onChange={(e) => HandleMonth(e)}
                                    >
                                        <option value="">All Months</option>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="yearSelect" className="form-label">Select Year</label>
                                    <select
                                        id="yearSelect"
                                        className="form-select form-select-sm"
                                        onChange={(e) => HandleYear(e)}
                                        defaultValue={currentYear}
                                    >
                                        <option value="">All Years</option>
                                        <option value={`${moment().year() - 2}`}>{moment().year() - 2}</option>
                                        <option value={`${moment().year() - 1}`}>{moment().year() - 1}</option>
                                        <option value={`${moment().year()}`}>{moment().year()}</option>
                                    </select>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="projectSelect" className="form-label">Select Project</label>
                                    <select
                                        id="projectSelect"
                                        className="form-select form-select-sm"
                                        onChange={(e) => handleProject(e)}
                                        value={projectId}
                                    >
                                        {projectList && projectList.map((item, index) => (
                                            <option value={item._id} key={index + index + "projectList"}>{item?.title}</option>
                                        ))}
                                    </select>
                                </div>
                                {user_data?.roleData[0]?.role_name === 'Admin' &&
                                    <div className="col-md-3">
                                        <label htmlFor="employeeSelect" className="form-label">Select Employee</label>
                                        <select
                                            id="employeeSelect"
                                            className="form-select form-select-sm"
                                            onChange={(e) => setUser(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            {userList && userList.map((item, index) => (
                                                <option value={item._id} key={index + "userList"}>{item.firstname + ' ' + item.last_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="12" md="12">

                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-3">
                                <Row className="ligth align-items-center">
                                    <Col md={4}>
                                        <div>
                                            <h3 className='fw-bold'>{projectData?.title}</h3>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <h6 className='fw-bold'>Total Estimated Hours: {getTotalEstimatedHours(projectTimeEntryData)}</h6>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <h6 className='fw-bold'>Total Actual Hours: {getTotalHours(projectTimeEntryData)}</h6>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Title>
                            <hr />
                            {projectTimeEntryData?.length > 0 && projectTimeEntryData.map((item, index) => {
                                return (
                                    <div key={index + "projectTimeEntry"} >
                                        <Card.Body className='mb-2 p-0'>
                                            <Row className="ligth">
                                                <Col md={4}>
                                                    <div>
                                                        <h6 className='fw-bold'>{getFullMonthName(item.month)}</h6>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <h6 className='fw-bold'>Total Month Estimated Hours: {formatTwoDigits(item.totalTaskEstimationHoursMonth)}</h6>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <h6 className='fw-bold'>Total Month Actual Hours: {formatTwoDigits(item.totalHoursMonth)}</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Body>
                                            <Table className="ligth" style={{ overflow: "unset" }}>
                                                <thead>
                                                    <tr>
                                                        <th>View</th>
                                                        <th>User</th>
                                                        <th>Total Estimated Hours</th>
                                                        <th>Total Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.users?.length > 0 && item.users.map((user, Iindex) => {
                                                        return (
                                                            <>
                                                                <tr key={Iindex + "itemUser"}>
                                                                    <td>
                                                                        <button className='btn btn-primary btn-sm' onClick={() => handlePlusButtonClick(`TaskHoursUser${Iindex}-${index}`)}>  <span className="btn-inner">
                                                                            <svg width="20" viewBox="0 0 1024 1024" className="icon" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill="#ffffff" stroke="#ffffff" d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 110 448 224 224 0 010-448zm0 64a160.192 160.192 0 00-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z" />
                                                                            </svg>
                                                                        </span>
                                                                        </button>
                                                                    </td>
                                                                    <td>{user.firstname}</td>
                                                                    <td>{formatTwoDigits(user.totalTaskEstimationHoursUser)}
                                                                    </td>
                                                                    <td className=''>
                                                                        {formatTwoDigits(user.totalHoursUser)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    {(isCardOpen == `TaskHoursUser${Iindex}-${index}`) && (
                                                                        <td colSpan={3}>
                                                                            {user.estimationTasks?.length > 0 && user.estimationTasks.map((task, index) => {
                                                                                return (
                                                                                    <div className='row'>
                                                                                        <div className='col-6' >{task.taskName}:
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            {task.taskEstimationHour}H  ({moment(task.date).format('DD-MM-YYYY')})
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                    )}
                                                                    {(isCardOpen == `TaskHoursUser${Iindex}-${index}`) && (
                                                                        <td className='col-6'>
                                                                            {user.tasks?.length > 0 && user.tasks.map((task, index) => {
                                                                                return (
                                                                                    <div className='row'>
                                                                                        <div className='col-6' >{task.taskName}:
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            {task.taskHour}H  ({moment(task.date).format('DD-MM-YYYY')})
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </div>
                                )
                            })}
                        </Card.Body>
                    </Card >
                </Col>
            </Row >
        </>
    )
}

export default ViewProject