import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from '../../plugins/toastUtils';

const EditInventory = ({ api }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formValidated] = useState(false);
    const [inventoryDataListing, setInventoryDataListing] = useState([]);
    const [allListingData, setAllListingData] = useState([]);
    const [description, setDescription] = useState("");
    const [selectedItems, setSelectedItems] = useState({});
    const [selectedUserId] = useState();
    const [loading, setLoading] = useState(false);

    const HandleEditModel = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/editAssignInventory/${id}`);
            setLoading(false);
            setDescription(response.data.AssignInventoryData[0].UserData.firstname);
            setInventoryDataListing(response.data.AssignInventoryData);
        } catch (error) {
            setLoading(false);
        }
    };

    const updateInventoryData = async (e) => {
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            try {
                const inventoryItemIds = Object.keys(selectedItems).map((key) => selectedItems[key]);
                const unchangedInventoryItemIds = Object.keys(inventoryDataListing[0]?.InventoryItemData || {})
                    .filter((key) => !selectedItems[key])
                    .map((key) => inventoryDataListing[0]?.InventoryItemData[key]._id);

                const allInventoryItemIds = [...inventoryItemIds, ...unchangedInventoryItemIds];

                const response = await api.post(
                    `/editAssignInventory/${id}`,
                    {
                        user_id: selectedUserId,
                        inventoryItem_id: allInventoryItemIds,
                    }
                );
                navigate('/inventory-system');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100);
            } catch (error) {
                showToast(error.response.data.message, 'error');
                console.error('Error occurred:', error);
            }
        }
    };

    const allDataForSelectedItem = async () => {
        try {
            const response = await api.get(`/mainInventoryItem`);
            setAllListingData(response.data.mainInventoryItem)
        } catch (error) {
        }
    };

    const assignedKeys = Object.keys(inventoryDataListing[0]?.InventoryItemData || {});
    const allKeys = allListingData.reduce((acc, inventoryItem) => {
        return [...acc, ...Object.keys(inventoryItem)];
    }, []);

    const unassignedKeys = allKeys.filter((key) => !assignedKeys.includes(key));

    useEffect(() => {
        HandleEditModel();
        allDataForSelectedItem();
    }, []);

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit system inventory to particular user</h4>
                            </div>
                            <div>
                                <Button type="submit" variant="btn btn-primary me-2" onClick={updateInventoryData} disabled={loading}>
                                    {loading ? 'Submitting...' : 'Edit inventory'}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="btn btn-danger"
                                    onClick={() => navigate("/inventory-system")}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form noValidate validated={formValidated}>
                                <Row>
                                    <Col lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="value">Assigned Employee Name</Form.Label>
                                            <Form.Control
                                                className=""
                                                value={description}
                                                type="text"
                                                id="description"
                                                required
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Row>
                                        {Object.keys(inventoryDataListing[0]?.InventoryItemData || {}).map((key) => (
                                            <Col key={key} lg="6" className="mb-3">
                                                <Form.Label>{key} :-</Form.Label>
                                                <Form.Group className="form-group">
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setSelectedItems((prevSelectedItems) => ({
                                                                ...prevSelectedItems,
                                                                [key]: value,
                                                            }));
                                                        }}
                                                        value={selectedItems[key] || inventoryDataListing[0]?.InventoryItemData[key]._id}
                                                    >
                                                        <option value="" disabled>
                                                            Select {key}
                                                        </option>
                                                        {/* Map options */}
                                                        {allListingData.map((inventoryItem) => {
                                                            const items = inventoryItem[key] || [];
                                                            return items.map((item) => (
                                                                <option key={item._id} value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                            ));
                                                        })}
                                                        <option
                                                            key={inventoryDataListing[0]?.InventoryItemData[key]._id}
                                                            value={inventoryDataListing[0]?.InventoryItemData[key]._id}
                                                        >
                                                            {inventoryDataListing[0]?.InventoryItemData[key].name}
                                                        </option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        ))}
                                        {unassignedKeys.map((key) => (
                                            <Col key={key} lg="6" className="mb-3">
                                                <Form.Label>{key} :-</Form.Label>
                                                <Form.Group className="form-group">
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setSelectedItems((prevSelectedItems) => ({
                                                                ...prevSelectedItems,
                                                                [key]: value,
                                                            }));
                                                        }}
                                                        value={selectedItems[key] || ''}
                                                    >
                                                        <option value="" disabled>
                                                            Select {key}
                                                        </option>
                                                        {allListingData.map((inventoryItem) => {
                                                            const items = inventoryItem[key] || [];
                                                            const assignedValues = Object.values(selectedItems);
                                                            const unassignedItems = items.filter((item) => !assignedValues.includes(item._id));
                                                            const existingItem = assignedValues.find((item) => items.some((i) => i._id === item));
                                                            return (
                                                                <React.Fragment key={inventoryItem._id}>
                                                                    {existingItem && (
                                                                        <option key={existingItem} value={existingItem} disabled>
                                                                            {items.find((i) => i._id === existingItem)?.name}
                                                                        </option>
                                                                    )}
                                                                    {unassignedItems.map((item) => (
                                                                        <option key={item._id} value={item._id}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        ))}
                                    </Row>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};


export default EditInventory;
