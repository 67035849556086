import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Card from "../../components/Card";
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddAllCPUMasterItem({ api }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        key: Yup.string()
            .required("Key Name Is Required")
            .matches(/^\S*$/, "Key cannot contain spaces"),
        value: Yup.string().required("Value is Required"),
        description: Yup.string().required("Description is Required"),
    });

    const formik = useFormik({
        initialValues: {
            key: "",
            value: "",
            description: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            api.post(`/cpuMasterInventory`, values).then((response) => {
                if (response.data.status === false) {
                    navigate('/403');
                }
                if (response.status === 201) {
                    setSubmitting(false);
                    setLoading(false);
                    navigate("/Add-CPU-Master-Item");
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 300);
                }
            }).catch((error) => {
                showToast(error.response.data.errors,'error');
            });
        },
    });

    const cpuParts = "Input CPU parts like RAM,ROM, Graphics Card etc ...";
    const partsDetails = "Input parts details like RAM-16GB , RAM-samsung-8GB etc ...";
    const descriptionOfItems = "Input description about item version, year and all";

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add CPU Master Item</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="key" className='d-flex align-item-center'>CPU Parts
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="tooltip-help">{cpuParts}</Tooltip>}
                                            >
                                                <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                                                    <FaQuestionCircle />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            id="key"
                                            name="key"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.key}
                                            isInvalid={formik.touched.key && formik.errors.key}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.key}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="value" className='d-flex align-item-center'>Parts Details
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="tooltip-help">{partsDetails}</Tooltip>}
                                            >
                                                <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                                                    <FaQuestionCircle />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            id="value"
                                            name="value"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.value}
                                            isInvalid={formik.touched.value && formik.errors.value}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.value}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="description" className='d-flex align-item-center'>Description
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="tooltip-help">{descriptionOfItems}</Tooltip>}
                                            >
                                                <Button variant="none" className="btn-help p-0 ms-2" style={{ backgroundColor: "transparent", border: "none" }}>
                                                    <FaQuestionCircle />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            id="description"
                                            name="description"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            isInvalid={formik.touched.description && formik.errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </Button>
                                    <Button
                                        variant="danger"
                                        type="button"
                                        onClick={() => navigate("/Add-CPU-Master-Item")}
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div>
        </>
    );
}

export default AddAllCPUMasterItem;
