import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Dropdown, Badge, Form, Row, Col, Container, Modal, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import CustomToggle from '../../../dropdowns'
import { bindActionCreators } from "redux"
import '../../../../assets/css/employeeprofile.css'
//img
import flag1 from '../../../../assets/images/Flag/flag001.png'
import flag2 from '../../../../assets/images/Flag/flag-02.png'
import flag3 from '../../../../assets/images/Flag/flag-03.png'
import flag4 from '../../../../assets/images/Flag/flag-04.png'
import flag5 from '../../../../assets/images/Flag/flag-05.png'
import flag6 from '../../../../assets/images/Flag/flag-06.png'
import shapes1 from '../../../../assets/images/shapes/01.png'
import shapes2 from '../../../../assets/images/shapes/02.png'
import shapes3 from '../../../../assets/images/shapes/03.png'
import shapes4 from '../../../../assets/images/shapes/04.png'
import shapes5 from '../../../../assets/images/shapes/05.png'
import avatars1 from '../../../../assets/images/avatars/01.png'
import avatars2 from '../../../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../../../assets/images/avatars/avtar_5.png'
// logo
import Logo from '../../components/logo'
import '../../../../assets/css/headerCustomstyle.css'
// store
import { NavbarstyleAction, getDirMode, SchemeDirAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../../store/setting/setting'
import { connect, useSelector } from "react-redux"
import { useDispatch } from 'react-redux'
import { logout } from '../../../../store/slices/authSlice'
import moment from 'moment'
import Swal from 'sweetalert2'
const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
        },
        dispatch
    )
})

const Header = (props) => {
    const themeData = useSelector((state) => state.mode.colorcustomizer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { SignalR_token } = StoreAuthentication.useState();
    const imagePath = props.imagePath;
    useEffect(() => {
        // navbarstylemode
        const navbarstyleMode1 = sessionStorage.getItem('Navbarstyle-mode');
        if (navbarstyleMode1 === null) {
            props.NavbarstyleAction(props.navbarstylemode);
        }
        else {
            props.NavbarstyleAction(navbarstyleMode1);
        }
    })
    const minisidebar = () => {
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }
    // logout code-------------------------------
    const Handlelogout = (e) => {
        e.preventDefault();
        dispatch(logout());
        localStorage.removeItem('user');
        localStorage.removeItem('message');
        sessionStorage.removeItem("redirectUrl")
        navigate('/login');
    }
    // --------------------------------
    let user_data = useSelector((state) => state.user.user);
    const [EmployeeData, setEmployeeData] = useState([]);
    useEffect(() => {
        props.api.get(`/profile/${user_data._id}`)
            .then((Response) => {
                if (Response.data.userData[0] !== undefined) {
                    Response.data.userData[0] && setEmployeeData(Response.data.userData[0]);
                }
            }).catch((error) => {
            });
        return () => {
        };
    }, []);
    // Announcement list
    const [unreadAnnouncement, setUnreadAnnouncement] = useState([]);
    const [readAnnouncement, setReadAnnouncement] = useState([]);
    const [allAnnouncement, setAllAnnouncement] = useState([]);
    const [show, setShow] = useState(false);
    // set unread to read 
    const handleClose = async () => {
        await props.api.post(`/statusAnnouncements/${announcementId}`, '').then((Response) => {
        }).catch((error) => {
        })
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const [resetAnnounceList, setResetAnnounceList] = useState(false);
    useEffect(() => {
        if (props.announce) {
            setResetAnnounceList(true)
        }
        setTimeout(() => {
            setReadAnnouncement(false);
        }, 3000);
        return () => {

        };
    }, []);
    // announcement get api 
    const AnnounceList = () => {
        props.api.get(`/announcementListing/`).then((response) => {
            if (response.data.status === false) {
            }
            else {
                setUnreadAnnouncement(response.data.AnnouncementStatus0);
                setReadAnnouncement(response.data.AnnouncementStatus1);
                setAllAnnouncement(response.data.AnnouncementData);
            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        AnnounceList();
        if (resetAnnounceList) {
            AnnounceList();
        }
        return () => {
        };
    }, [resetAnnounceList]);
    // read unread model 
    const [announcementsData, setAnnouncementsData] = useState([]);
    const [userOfAnnounce, setUserOfAnnounce] = useState('');
    const [announcementId, setAnnouncementId] = useState('');


    const HandleRead = async (id) => {
        setAnnouncementId(id);
        await props.api.get(`/AnnouncementsDetails/${id}`).then((response) => {
            if (response.data.status === false) {
            }
            else {
                setAnnouncementsData(response.data.AnnouncementData[0]);
                setUserOfAnnounce(response.data.AnnouncementData[0].userData[0].firstname);
                AnnounceList();
                setShow(true);
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    // delete model
    const [deleteshow, setdeleteShow] = useState(false);
    const [deleteid, setDeleteid] = useState('');
    const handledeleteClose = () => setdeleteShow(false);
    const handledeleteShow = () => setdeleteShow(true);
    function deletemodel(id) {
        setShow(false);
        setDeleteid(id);
        setdeleteShow(true);
    }


    // sweetalert code
    const showAlert = () => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Announcement delted successfully',
            showConfirmButton: false,
            timer: 1500

        })
    }

    /******************* */
    const Handledelete = () => {
        const id = deleteid;
        if (id !== undefined) {
            const deleteemployee = props.api.post(`/deleteAnnouncement/${id}`, '')
                .then(() => {
                    setdeleteShow(false);
                    showAlert();
                    const updatedAnnouncements = allAnnouncement.filter(
                        (announcement) => announcement._id !== id
                    );
                    setAllAnnouncement(updatedAnnouncements);
                }).catch((error) => {
                });
        }
    }


    return (
        <>
            <div className="Announcements-model">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Announcements</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="part-1 col-lg-12 col-md-12">
                                <div className="my-3 d-flex col-lg-12 align-items-center" style={{ gap: "5%" }}>
                                    <h6 className="mb-0 text-dark" style={{ width: '30%' }}>Announced By:</h6>
                                    <div className="">
                                        <h6 className="mb-0" style={{ fontWeight: "400" }}>{userOfAnnounce && userOfAnnounce}</h6>
                                    </div>
                                </div>
                                <div className="my-3 d-flex col-lg-12 align-items-center" style={{ gap: "5%" }}>
                                    <h6 className="mb-0 text-dark" style={{ width: '30%' }}>Title:</h6>
                                    <div className="">
                                        <h6 className="mb-0" style={{ fontWeight: "400" }}>{announcementsData && announcementsData.title}</h6>
                                    </div>
                                </div>
                                <div className="my-3 d-flex col-lg-12 align-items-center" style={{ gap: "5%" }}>
                                    <h6 className="mb-0 text-dark" style={{ width: '30%' }}>Description:</h6>
                                    <div className="">
                                        <h6 className="mb-0" style={{ fontWeight: "400" }}>{announcementsData && announcementsData.description}</h6>
                                    </div>
                                </div>
                                <div className="my-3 d-flex col-lg-12 align-items-center" style={{ gap: "5%" }}>
                                    <h6 className="mb-0 text-dark" style={{ width: '30%' }}>Time:</h6>
                                    <div className="">
                                        <h6 className="mb-0" style={{ fontWeight: "400" }}>{moment(announcementsData && announcementsData.created_at).fromNow()}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button variant="danger" type='reset' onClick={() => handleClose()}>close</Button>
                    </Modal.Body>
                </Modal>
            </div>
            {/* delete model */}
            <div className="delte-model">
                <Modal show={deleteshow} onHide={handledeleteClose} className='text-center'>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button variant="primary" onClick={() => Handledelete()}>Delete</Button>
                        {' '}
                        <Button variant="danger" onClick={() => handledeleteClose()} value="Cancel" >Cancel</Button>

                    </Modal.Body>
                </Modal>
            </div>
            <Navbar expand="lg" variant="light" className="nav iq-navbar">
                <Container fluid className="navbar-inner">
                    <Link to="/dashboard" className="navbar-brand">
                        <Logo color={true} />
                        <h4 className="logo-title">CodeCrew UI</h4>
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                        <i className="icon">
                            <svg width="20px" height="20px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                            </svg>
                        </i>
                    </div>
                    {/* <div className="input-group search-input">
                        <span className="input-group-text" id="search-input">
                            <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <input type="search" className="form-control" placeholder="Search..." />
                    </div> */}
                    <Navbar.Toggle aria-controls="navbarSupportedContent">
                        <span className="navbar-toggler-icon">
                            <span className="mt-2 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav as="ul" className="mb-2 ms-auto navbar-list mb-lg-0">

                            {/* announcement notifiaction */}
                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} href="#" variant=" nav-link" id="notification-drop" data-bs-toggle="dropdown" >
                                    <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M14.0088 19.2283C13.5088 19.1215 10.4627 19.1215 9.96275 19.2283C9.53539 19.327 9.07324 19.5566 9.07324 20.0602C9.09809 20.5406 9.37935 20.9646 9.76895 21.2335L9.76795 21.2345C10.2718 21.6273 10.8632 21.877 11.4824 21.9667C11.8123 22.012 12.1482 22.01 12.4901 21.9667C13.1083 21.877 13.6997 21.6273 14.2036 21.2345L14.2026 21.2335C14.5922 20.9646 14.8734 20.5406 14.8983 20.0602C14.8983 19.5566 14.4361 19.327 14.0088 19.2283Z" fill="currentColor"></path>
                                    </svg>
                                    <span className="bg-danger dots"></span>
                                    {user_data.roleData[0].role_name !== 'Admin' &&
                                        <span className={`badge rounded-pill badge-notification bg-danger ${unreadAnnouncement?.length > 0 ? ' d-flex' : ' d-none'}`}
                                            style={{
                                                position: "relative",
                                                left: "50%", marginTop: "-30px", width: "18px", display: "flex", alignItems: "center", justifyContent: "center"
                                            }}
                                        >{unreadAnnouncement?.length}</span>
                                    }
                                </Dropdown.Toggle>
                                {/* Announcement Notification  */}
                                <Dropdown.Menu className="p-0 sub-drop dropdown-menu-end" aria-labelledby="notification-drop">
                                    <div className="m-0 shadow-none card">
                                        <div className="py-3 card-header d-flex justify-content-between bg-primary">
                                            <div className="header-title">
                                                <h5 className="mb-0 text-white">All Announcements</h5>
                                            </div>
                                        </div>
                                        <div className="p-0 card-body" id='style-4' style={{ height: `${(allAnnouncement || unreadAnnouncement || readAnnouncement)?.length > 4 ? '310px' : 'auto'}` }}>
                                            {/* for the admin */}

                                            {user_data.roleData[0].role_name === 'Admin' ?
                                                allAnnouncement?.length > 0 ?
                                                    allAnnouncement.slice(0).reverse().map((item, index) => (
                                                        <Link to="#" className="iq-sub-card notify-list" key={index} style={{ borderBottom: '1px solid #8a92a62b' }}>
                                                            <div className="d-flex align-items-center" >
                                                                <img className="avatar-40 rounded-pill bg-soft-primary" src={item.userData[0] && imagePath + item.userData[0].photo} alt="" />
                                                                <div className={`${item.status === 1 ? ' opacity-50 ' : ''}ms-3 w-100`} onClick={() => HandleRead(item._id)}>
                                                                    <h6 className="mb-0 ">{item.userData[0] && item.userData[0].firstname}</h6>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0 overflow-hidden">{item.title}</p>
                                                                        <small className="float-right font-size-12">{moment(item.created_at).fromNow()}</small>
                                                                    </div>
                                                                </div>
                                                                <Button onClick={() => deletemodel(item._id)} className="btn btn-sm btn-icon btn-danger delete-btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to='#'>
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Button>{' '}
                                                            </div>
                                                        </Link>))
                                                    :
                                                    <div className="d-flex align-items-center">
                                                        <div className={`m-3 w-100`} >
                                                            <h6 className="mb-0 "></h6>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <p className="mb-0 overflow-hidden">There is nothing announced</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : ''

                                            }

                                            {/* for the other */}
                                            {user_data.roleData[0].role_name !== 'Admin' &&
                                                <>
                                                    <div className="unread-msg">
                                                        {unreadAnnouncement?.length > 0 ? <h6 className='text-center mt-2'><span>New</span></h6> : ""}
                                                        {unreadAnnouncement?.length > 0 ?
                                                            unreadAnnouncement.slice(0).reverse().map((item, index) => (
                                                                <Link to="#" className="iq-sub-card" key={index} style={{ borderBottom: '1px solid #8a92a62b' }} onClick={() => HandleRead(item.announcement_id)}>
                                                                    <div className="d-flex align-items-center" >
                                                                        <img className="avatar-40 rounded-pill bg-soft-primary" src={shapes1} alt="" />
                                                                        <div className="ms-3 w-100">
                                                                            <p className="mb-0">{item.announcementData[0] && item.announcementData[0].title}</p>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <h6 className="mb-0 ">{item.userData && item.userData[0]?.firstname}</h6>
                                                                                <small className="float-right font-size-12">{moment(item.created_at).fromNow()}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            ))

                                                            : <p className='text-center my-2'>No new announcement</p>}
                                                    </div>
                                                    <Dropdown.Divider />
                                                    <div className="read-msg">
                                                        {readAnnouncement?.length > 0 ?
                                                            readAnnouncement.slice(0).reverse().map((item, index) => (
                                                                <Link to="#" className="iq-sub-card notify-list" key={index} style={{ borderBottom: '1px solid #8a92a62b' }} onClick={() => HandleRead(item.announcement_id)}>
                                                                    <div className="d-flex align-items-center">
                                                                        <img className="p-1 avatar-40 rounded-pill bg-soft-primary" src={shapes1} alt="" />
                                                                        <div className="ms-3 w-100" style={{ opacity: "0.6" }} onClick={() => HandleRead(item._id)}>
                                                                            <h6 className="mb-0 ">{item.userData[0] && item.userData[0].firstname}</h6>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p className="mb-0">{item.announcementData[0] && item.announcementData[0].title}</p>
                                                                                <small className="float-right font-size-12">{moment(item.created_at).fromNow()}</small>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Link>
                                                            ))

                                                            : ''}

                                                    </div>
                                                </>
                                            }
                                            {/* [---------------] */}


                                        </div>
                                    </div>
                                </Dropdown.Menu>
                                {/* ................... */}
                            </Dropdown>

                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-purple-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-green-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-yellow-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <img src={`${EmployeeData.photo ? imagePath + EmployeeData.photo
                                        : themeData === 'theme-color-default' ? avatars1 :
                                            themeData === 'theme-color-blue' ? avatars2 :
                                                themeData === 'theme-color-gray' ? avatars3 :
                                                    themeData === 'theme-color-red' ? avatars5 :
                                                        themeData === 'theme-color-yellow' ? avatars6 :
                                                            themeData === 'theme-color-pink' && avatars4
                                        }`} alt="User-Profile" className="theme-color-pink-img img-fluid avatar avatar-50 avatar-rounded" />
                                    <div className="caption ms-3 d-none d-md-block ">
                                        <h6 className="mb-0 caption-title">{user_data.firstname}</h6>
                                        <p className="mb-0 caption-sub-title">{user_data.roleData[0].role_name}</p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-end w-100" aria-labelledby="navbarDropdown">
                                    <Link to='/personal-profile' className='d-flex align-items-center' style={{
                                        gap: "2%", margin: "8% 0 8% 0", letterSpacing: "0.25px", fontSize: "13px", fontWeight: "500"
                                    }}>
                                        <svg className='mx-1' width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z" fill="currentColor" />
                                            <path opacity="0.4" d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z" fill="currentColor" />
                                        </svg>
                                        Profile
                                    </Link>
                                    <Link to='/change-password' className='d-flex align-items-center' style={{ gap: "2%", margin: "8% 0 8% 0", letterSpacing: "0.25px", fontSize: "13px", fontWeight: "500" }}>
                                        <svg className='mx-1' width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M16.3345 1.99976H7.66549C4.27649 1.99976 2.00049 4.37776 2.00049 7.91676V16.0838C2.00049 19.6218 4.27649 21.9998 7.66549 21.9998H16.3335C19.7225 21.9998 22.0005 19.6218 22.0005 16.0838V7.91676C22.0005 4.37776 19.7235 1.99976 16.3345 1.99976Z" fill="currentColor" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.3144 11.2484H17.0144C17.4244 11.2484 17.7644 11.5884 17.7644 11.9984V13.8484C17.7644 14.2684 17.4244 14.5984 17.0144 14.5984C16.5944 14.5984 16.2644 14.2684 16.2644 13.8484V12.7484H14.9344V13.8484C14.9344 14.2684 14.5944 14.5984 14.1844 14.5984C13.7644 14.5984 13.4344 14.2684 13.4344 13.8484V12.7484H11.3144C10.9944 13.8184 10.0144 14.5984 8.84437 14.5984C7.40437 14.5984 6.23438 13.4384 6.23438 11.9984C6.23438 10.5684 7.40437 9.39844 8.84437 9.39844C10.0144 9.39844 10.9944 10.1784 11.3144 11.2484ZM7.73438 11.9984C7.73438 12.6084 8.23438 13.0984 8.84438 13.0984C9.44438 13.0984 9.94438 12.6084 9.94438 11.9984C9.94438 11.3884 9.44438 10.8984 8.84438 10.8984C8.23438 10.8984 7.73438 11.3884 7.73438 11.9984Z" fill="currentColor" />
                                        </svg>
                                        <span>Change Password</span>
                                    </Link>
                                    {props.userdata.user.roleData[0].role_name === 'Admin' &&
                                        <Link to='/setting' className='d-flex align-items-center' style={{ gap: "2%", margin: "8% 0 8% 0", letterSpacing: "0.25px", fontSize: "13px", fontWeight: "500" }}>
                                            <svg className='mx-1' width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.0122 14.8299C10.4077 14.8299 9.10986 13.5799 9.10986 12.0099C9.10986 10.4399 10.4077 9.17993 12.0122 9.17993C13.6167 9.17993 14.8839 10.4399 14.8839 12.0099C14.8839 13.5799 13.6167 14.8299 12.0122 14.8299Z" fill="currentColor" />
                                                <path opacity="0.4" d="M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.425 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z" fill="currentColor" />
                                            </svg>
                                            Setting
                                        </Link>
                                    }
                                    <Dropdown.Divider />
                                    <Link onClick={(e) => Handlelogout(e)} className='d-flex align-items-center mx-2' style={{ gap: "3%", letterSpacing: "0.25px", fontSize: "13px", fonWeight: "500" }}>
                                        <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className='mx-2' opacity="0.4" d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z" fill="currentColor" />
                                            <path d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z" fill="currentColor" />
                                        </svg>
                                        Logout</Link>
                                    {/* <Dropdown.Item href="https://templates.iqonic.design/hope-ui/react/build/dashboard/app/user-privacy-setting">Privacy Setting</Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)