import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/pagination.css";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

const MasterItemList = ({ api }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [entry] = useState(10);
  const [dataperPage, setDataperPage] = useState(entry);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [order, setOrder] = useState('ASC');
  const Permissions = useContext(PermissionData);
  const [totalDataPage, setTotalDataPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const inventoryList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/inventoryItem?search=${searchValue}&page=${pageNumber + 1}&limit=${dataperPage}`);
      setLoading(false);
      if (response.data !== undefined) {
        if (response.data.status === false) {
          navigate('/403')
        } else {
          const data = response.data.InventoryItemData;
          setInventory(data.reverse());
          setInventoryData(data);
          setTotalDataPage(response.data.totalData);
          const pages = Math.ceil(response.data.totalData / dataperPage);
          setTotalPages(pages);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching inventory items:", error);
    }
  };

  const handleDeleteMasterItem = () => {
    const id = deleteid;
    if (id !== undefined) {
      api.delete(`/deleteInventoryItem/${id}`).then((response) => {
        setShow(false);
        inventoryList();
        showToast(response.data.message, "success")
      }).catch((error) => {
        showToast(error.response.data.errors, 'error');
      });
    }
  };

  const sorting = (col) => {
    if (col === 'Name') {
      if (order === 'ASC') {
        const sorted = [...inventory].sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        if (searchData.length > 0) {
          setSearchData(sorted);
        } else {
          setInventory(sorted);
        }
        setOrder('DSC');
      } else if (order === 'DSC') {
        const sorted = [...inventory].sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
        );
        if (searchData.length > 0) {
          setSearchData(sorted);
        } else {
          setInventory(sorted);
        }
        setOrder('ASC');
      }
    } else if (col === 'Unique ID') {
      if (order === 'ASC') {
        const sorted = [...inventory].sort((a, b) =>
          a.unique_id.toLowerCase() > b.unique_id.toLowerCase() ? 1 : -1
        );
        if (searchData.length > 0) {
          setSearchData(sorted);
        } else {
          setInventory(sorted);
        }
        setOrder('DSC');
      } else if (order === 'DSC') {
        const sorted = [...inventory].sort((a, b) =>
          a.unique_id.toLowerCase() < b.unique_id.toLowerCase() ? 1 : -1
        );
        if (searchData.length > 0) {
          setSearchData(sorted);
        } else {
          setInventory(sorted);
        }
        setOrder('ASC');
      }
    }
  };

  const perpagedata = (e) => {
    setDataperPage(e.target.value);
    setPageNumber(0);
    setPage(1);
    inventoryList();
  };

  const handlePageChange = (selectedPage) => {
    setPageNumber(selectedPage.selected);
    setPage(selectedPage.selected + 1);
    inventoryList();
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (id) => {
    setDeleteid(id);
    setShow(true);
  };

  function deletemodel(id) { }

  const pageVisited = pageNumber * dataperPage;

  useEffect(() => {
    inventoryList();
  }, [searchValue, dataperPage, page]);

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button variant="primary me-2" onClick={() => handleDeleteMasterItem()}>
                Delete
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Listing of created Inventories</h4>
                </div>
                {Permissions.includes("Add Creation of Inventories") && (
                  <div className="Add-user">
                    <Link
                      to="add"
                      className="add-emp"
                      style={{ marginRight: "5px" }}
                    >
                      <h4 className="btn btn-primary">
                        <i
                          className="fa-solid fa-plus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        created master Inventories
                      </h4>
                    </Link>
                  </div>
                )}
              </Card.Header>
              <Card.Body className="px-0">
                <div className="row p-3 mb-3">
                  <div className="col-md-6">
                    <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                      <label>Show</label>
                      <select
                        name="user-list-table_length" aria-controls="user-list-table"
                        className="form-select form-select-sm w-25" onChange={(e) => perpagedata(e)} defaultValue={entry}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%' }}>
                      <label>Search:</label>
                      <input type="search" className="form-control form-control-sm w-50" placeholder=""
                        aria-controls="user-list-table" onChange={(event) => setSearchValue(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive" id="emp-scroll">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>No</th>
                        <th style={{ cursor: "pointer" }} onClick={() => sorting('Name')}>
                          <div className=" d-flex ">
                            <span className="title">Name</span>
                            <div className="sorting-icon justify-content-center align-items-center">
                              <i
                                className={`fa-sharp fa-solid fa-sort-up position-relative `}
                                style={{ left: "50%" }}
                              ></i>
                              <i
                                className={`fa-sharp fa-solid fa-sort-down position-relative `}
                              ></i>
                            </div>
                          </div>
                        </th>
                        <th style={{ cursor: "pointer" }} onClick={() => sorting('Unique ID')}>
                          <div className=" d-flex ">
                            <span className="title">Unique ID</span>
                            <div className="sorting-icon justify-content-center align-items-center">
                              <i
                                className={`fa-sharp fa-solid fa-sort-up position-relative`}
                                style={{ left: "50%" }}
                              ></i>
                              <i
                                className={`fa-sharp fa-solid fa-sort-down position-relative `}
                              ></i>
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className=" d-flex ">
                            <span className="title">Description</span>
                            <div className="sorting-icon justify-content-center align-items-center">
                              <i
                                className={`fa-sharp fa-solid fa-sort-up position-relative `}
                                style={{ left: "50%" }}
                              ></i>
                              <i className={`fa-sharp fa-solid fa-sort-down position-relative `}></i>
                            </div>
                          </div>
                        </th>
                        {(Permissions.includes("Delete Creation of Inventories") ||
                          Permissions.includes("Update Creation of Inventories")) && (
                            <th min-width="100px">Action</th>
                          )}
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={99} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {inventoryData?.length > 0 ? (
                          inventory.map((item, index) => {
                            return (
                              <tr key={index} className="border-light">
                                <td>{index + 1 + pageNumber * dataperPage}</td>
                                <td>{item.name}</td>
                                <td>{item.unique_id}</td>
                                <td>{item.description}</td>
                                {(Permissions.includes("Delete Creation of Inventories") ||
                                  Permissions.includes("Update Creation of Inventories")) && (
                                    <td>
                                      <div
                                        className="d-flex"
                                        style={{ gap: "3px" }}
                                      >
                                        <div>
                                          {Permissions.includes(
                                            "Update Creation of Inventories"
                                          ) && (
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip>Edit</Tooltip>}
                                              >
                                                <Link
                                                  className="btn btn-sm btn-icon btn-warning"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title=""
                                                  to={`edit/${item._id}`}
                                                >
                                                  <span className="btn-inner">
                                                    <svg
                                                      width="20"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M15.1655 4.60254L19.7315 9.16854"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </OverlayTrigger>
                                            )}
                                        </div>
                                        <div>
                                          {Permissions.includes(
                                            "Delete Creation of Inventories"
                                          ) && (
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  item.is_userAssigned === 0 ? (
                                                    <Tooltip>Delete</Tooltip>
                                                  ) : (
                                                    <Tooltip>This item is already assigned. Unable to delete.</Tooltip>
                                                  )
                                                }
                                              >
                                                <Link
                                                  className="btn btn-sm btn-icon btn-danger"
                                                  style={{ opacity: item.is_userAssigned === 1 ? 0.5 : 1 }}
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title=""
                                                  to="#"
                                                  onClick={() => {
                                                    if (item.is_userAssigned === 0) {
                                                      handleShow(item._id);
                                                      deletemodel(item._id);
                                                    }
                                                  }}
                                                >
                                                  <span className="btn-inner">
                                                    <svg
                                                      width="20"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M20.708 6.23975H3.75"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </OverlayTrigger>
                                            )}
                                        </div>
                                      </div>
                                    </td>
                                  )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="99" className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  {inventoryData?.length > 0 &&
                    <div className="dataTables_length d-flex align-items-center mx-3" id="user-list-table_length">
                      <label>
                        Showing {Math.min(pageVisited + dataperPage, totalDataPage)} of {totalDataPage} entries
                      </label>
                    </div>
                  }
                  <div>
                    <div className="row align-items-center w-100">
                      <div
                        id="user-list-table_filter"
                        className="dataTables_filter d-flex align-items-center justify-content-end"
                        style={{ gap: "2%", position: "relative", right: "30%" }}
                      >
                        <div style={{ position: "relative", right: "74px" }}>
                          {totalPages > 1 && (
                            <div className="dataTables_paginate paging_simple_numbers">
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                breakLabel="..."
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ToastContainerWrapper />
        </Row>
      </div>
    </>
  );
};

export default MasterItemList;
