import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showToast } from '../../plugins/toastUtils';

const AddInventory = ({ api }) => {

  const navigate = useNavigate();
  const [Employee, setEmployee] = useState([]);
  const [inventoryDataListing, setInventoryDataListing] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedUserId, setSelectedUserId] = useState();
  const [loading, setLoading] = useState(false);
  const uniqueKeys = [...new Set(inventoryDataListing.flatMap(item => Object.keys(item)))];

  useEffect(() => {
    api.get(`/users_list`).then((response) => {
      if (response.data !== undefined) {
        if (response.data.status === false) {
          navigate('/403')
        }
        setEmployee(response.data.UserData);
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
    return () => {
    };
  }, []);

  useEffect(() => {
    api.get(`/mainInventoryItem`).then((response) => {
      if (response.data !== undefined) {
        if (response.data.status === false) {
          navigate('/403')
        }
        setInventoryDataListing(response.data.mainInventoryItem);
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
    return () => {
    };
  }, []);

  const addInventory = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isValid = form.checkValidity();

    if (isValid) {
      try {
        const inventoryItemIds = Object.values(selectedItems).filter((value) => value !== '');
        setLoading(true);
        const response = await api.post(
          `/addAssignInventory`,
          {
            user_id: selectedUserId,
            inventoryItem_id: inventoryItemIds,
          }
        );
        setLoading(false);
        navigate('/inventory-system');
        setTimeout(() => {
          showToast(response.data.message, "success")
        }, 100)
      } catch (error) {
        showToast(error.response.data.errors, 'error');
      }
    }
  };

  const handleUserChange = (data) => {
    setSelectedUserId(data.target.value)
  }

  return (
    <>
      <Row>
        <Col sm="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Add system to particular user</h4>
              </div>
              <div>
                <Button type="submit" variant="btn btn-primary me-2" onClick={addInventory} disabled={loading}>
                  {loading ? 'Submitting...' : 'Add system'}
                </Button>
                <Button
                  type="submit"
                  variant="btn btn-danger"
                  onClick={() => navigate("/inventory-system")}
                >
                  Cancel
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Form noValidate>
                <Row>
                  <div className="mb-4">
                    <div className="mt-2">
                      <h6 className="mb-1">Employee Name :-</h6>
                      <div className="edit-data w-75">
                        <select
                          className="form-control form-select form-select-sm"
                          name="user-list-table_length"
                          aria-controls="user-list-table"
                          defaultValue=""
                          onChange={handleUserChange}
                        >
                          <option value="" disabled>
                            Select Employee
                          </option>
                          {Employee?.length > 0 ? (
                            Employee.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.firstname + " " + item.last_name}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No Employees available
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap" style={{ gap: "27px" }}>
                    <div
                      className="d-flex align-Item-center"
                      style={{ gap: "20px" }}
                    >
                      {uniqueKeys.map((key, index) => {
                        const dataForKey = inventoryDataListing.find(item => Object.keys(item).includes(key))[key];
                        return (
                          <div key={index}>
                            <Form.Label htmlFor={`${key}_item`}>{key} :-</Form.Label>
                            <Form.Group className="form-group" style={{ marginTop: "10px" }}>
                              <Form.Select
                                id={`${key}_item`}
                                required
                                onChange={(e) =>
                                  setSelectedItems((prevSelectedItems) => ({
                                    ...prevSelectedItems,
                                    [key]: e.target.value,
                                  }))
                                }
                              >
                                <option value="">Select {key}</option>
                                {dataForKey.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option._id}>
                                    {option.name}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select valid information
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row >
    </>
  );
};

export default AddInventory;
