import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import '../../assets/css/customAlert.css'
import error01 from '../../assets/images/error/01.png'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'

const ActivityLog = ({ api, apiSecretKey, token, user_id, user_data }) => {

   const Permissions = useContext(PermissionData);
   const userdata = useSelector((state) => state.user);
   const navigate = useNavigate();
   const [logData, setLogData] = useState([]);
   const [deleteid, setDeleteid] = useState('');
   const [show, setShow] = useState(false);
   const [order, setOrder] = useState('ASC');
   const [pageNumber, setPageNumber] = useState(0);
   const [entry, setEntry] = useState(10);
   const [dataperPage, setDataperPage] = useState(entry);
   const [searchData, setSearchData] = useState([]);
   const [loading, setLoading] = useState(false);


   useEffect(() => {
      // LogData();
      return () => { };
   }, []);

   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   function deletemodel(id) {
      setDeleteid(id);
      setShow(true);
   }

   const PageChange = ({ selected }) => {

      setPageNumber(selected);
   }

   const perpagedata = (e) => {
      setDataperPage(e.target.value);
   }

   const sorting = (col) => {
      if (order === 'ASC') {
         const sorted = [...logData].sort((a, b) =>
            a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
         );
         if (searchData.length > 0) {
            setSearchData(sorted);
         }
         else {
            setLogData(sorted);
         }
         setOrder('DSC')
      }
      if (order === 'DSC') {
         const sorted = [...logData].sort((a, b) =>
            a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
         );
         if (searchData.length > 0) {
            setSearchData(sorted);
         }
         else {
            setLogData(sorted);
         }
         setOrder('ASC')
      }
   }

   function timeSince(date) {
      const seconds = Math.floor((new Date() - date) / 1000);
      const interval = Math.floor(seconds / 60);
      if (interval < 1) {
         return "Just now";
      }
      if (interval < 60) {
         return interval + " minutes ago";
      }
      const hours = Math.floor(interval / 60);
      if (hours < 24) {
         return hours + " hours ago";
      }
      const days = Math.floor(hours / 24);
      if (days < 30) {
         return days + " days ago";
      }
      const months = Math.floor(days / 30);
      if (months < 12) {
         return months + " months ago";
      }
      const years = Math.floor(months / 12);
      return years + " years ago";
   }

   const pageVisited = pageNumber * dataperPage;
   const pageCount = Math.ceil((searchData.length > 0 ? searchData.length : logData.length) / dataperPage);

   return (
      <>
         <div>
            <Row>
               <Modal show={show} onHide={handleClose} className='text-center'>
                  <>
                     <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <Button variant="primary"  >Delete</Button>
                        {' '}
                        <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>

                     </Modal.Body>
                  </>
               </Modal>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Activity Log</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="px-0">
                        <div className="table-responsive d-flex justify-content-center align-items-center flex-column gap-5">
                           <h3>This page is under maintanance</h3>
                           <Image src={error01} className="img-fluid mb-4 w-50" alt="maintanance" />
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>

   )

}

export default ActivityLog;
