import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "../../assets/css/customAlert.css";
import "../../assets/css/pagination.css";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast, successShowToast } from "../../plugins/toastUtils";
import { useNavigate } from "react-router-dom";

const PermissionList = ({ api }) => {
  const navigate = useNavigate();
  const Permissions = useContext(PermissionData);
  const [permission, setPermission] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [entry] = useState(10);
  const [dataperPage, setDataperPage] = useState(entry);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(false);
  let lastPermissionData = permission?.slice(-1);

  const permissiondata = () => {
    setLoading(true);
    api
      .get(
        `/viewpermissions?search=${searchValue}&page=${page}&limit=${dataperPage}&nameSort=${order}`
      )
      .then((response) => {
        if (response.data !== undefined) {
          setPermission(response.data.permissionData);
          setTotalPages(response.data.totalPages);
          setTotalData(response.data.totalData);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 403) {
          navigate("/403");
        }
        console.log("error", error);
      });
  };

  const Handledelete = async () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      await api
        .post(`/deletepermissions/${id}`, "")
        .then((response) => {
          setShow(false);
          showToast(response.data.message, "success");
          permissiondata();
        })
        .catch((error) => {
          if (error.response.status == 403) {
            navigate("/403");
          }
          setShow(false);
          showToast(error.response.data.errors, "error");
          console.log("error", error);
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      permissiondata();
    }
  };

  useEffect(() => {
    permissiondata();
  }, [page, dataperPage, order]);

  useEffect(() => {
    if (!searchValue && search) {
      permissiondata();
    }
    return () => { };
  }, [searchValue]);

  return (
    <>
      <Row>
        <Modal show={show} onHide={handleClose} className="text-center">
          <Modal.Header closeButton>
            <Modal.Title style={{ marginLeft: "5rem" }}>
              Do you want to delete it ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="me-2"
              disabled={loading}
              onClick={() => Handledelete()}
            >
              {loading ? "Deleting..." : "Delete"}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleClose()}
              value="Cancel"
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Permission</h4>
              </div>
              {Permissions.includes("Add Permission") && (
                <div className="Add-Permission">
                  <Link to="add">
                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      New Permission
                    </h4>
                  </Link>
                </div>
              )}
            </Card.Header>
            <div className="card-body px-0">
              <div className="table-responsive" style={{ overflowX: "hidden" }}>
                <div
                  id="user-list-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row align-items-center mb-3">
                    <div className="col-md-6">
                      <div
                        className="dataTables_length d-flex align-items-center"
                        id="user-list-table_length"
                        style={{ gap: "2%" }}
                      >
                        <label>Show</label>
                        <select
                          name="user-list-table_length"
                          aria-controls="user-list-table"
                          className="form-select form-select-sm w-25"
                          onChange={(e) => setPerPage(e.target.value)}
                          defaultValue={entry}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        id="user-list-table_filter"
                        className="dataTables_filter d-flex align-items-center justify-content-end"
                        style={{ gap: "2%" }}
                      >
                        <label>Search:</label>
                        <input
                          type="search"
                          className="form-control form-control-sm w-50"
                          placeholder=""
                          aria-controls="user-list-table"
                          onChange={(event) =>
                            setSearchValue(event.target.value)
                          }
                          onKeyDown={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped dataTable no-footer"
                      role="grid"
                      data-toggle="data-table"
                      aria-describedby="user-list-table_info"
                    >
                      <thead>
                        <tr className="ligth">
                          <th style={{ width: "66.6094px", cursor: "pointer" }}>
                            No
                          </th>
                          <th
                            style={{ width: "93.2344px", cursor: "pointer" }}
                            onClick={() => sorting("permission_name")}
                          >
                            <span className="title">Name</span>
                          </th>
                          <th style={{ width: "145.438px" }}>
                            <span className="title">Description</span>
                          </th>
                          {(Permissions.includes("Update Permission") ||
                            Permissions.includes("Delete Permission")) && (
                              <th style={{ width: "80.4844px" }}>Action</th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner
                                animation="border"
                                variant="primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </td>
                          </tr>
                        ) : permission?.length > 0 ? (
                          permission.map((item, index) => (
                            <tr key={index} className="border-light">
                              <td>{item.index}</td>
                              <td>{item.permission_name}</td>
                              <td>{item.permission_description}</td>
                              {Permissions &&
                                (Permissions.includes("Update Permission") ||
                                  Permissions.includes(
                                    "Delete Permission"
                                  )) && (
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {Permissions.includes(
                                        "Update Permission"
                                      ) && (
                                          <Link
                                            className="btn btn-sm btn-icon btn-warning"
                                            style={{ marginRight: "5px" }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Edit"
                                            to={`edit/${item._id}`}
                                          >
                                            <span className="btn-inner">
                                              <svg
                                                width="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M15.1655 4.60254L19.7315 9.16854"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                      {Permissions.includes(
                                        "Delete Permission"
                                      ) && (
                                          <Link
                                            onClick={() => deletemodel(item._id)}
                                            className="btn btn-sm btn-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Delete"
                                            to="#"
                                          >
                                            <span className="btn-inner">
                                              <svg
                                                width="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M20.708 6.23975H3.75"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              no data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row align-items-center w-100">
                  <div className="col-md-6">
                    <div
                      className="dataTables_length d-flex align-items-center mx-3"
                      id="user-list-table_length"
                    >
                      {permission?.length > 0 && (
                        <label>
                          Showing {permission[0]?.index} to{" "}
                          {lastPermissionData[0]?.index} of {totalData} entries
                        </label>
                      )}
                    </div>
                  </div>
                  {permission?.length > 0 && (
                    <div className="col-md-6">
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={PageChange}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination float-end"
                        renderOnZeroPageCount={null}
                      />
                      {/* <div
                        id="user-list-table_filter"
                        className="dataTables_filter d-flex dataTables_paginate paging_simple_numbers align-items-center justify-content-end"
                        style={{
                          gap: "2%",
                          position: "relative",
                          right: "30%",
                        }}
                      >
                        <div className="pagination-container"> */}
                      {/* <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={1500}
                            pageRangeDisplayed={2}
                            onPageChange={PageChange}
                            containerClassName={"pagination align-items-center"}
                            previousClassName={
                              "paginate_button page-item previous"
                            }
                            previousLinkClassName={"page-link border-0"}
                            nextLinkClassName={"page-link border-0"}
                            nextClassName={
                              "paginate_button page-item next border-none"
                            }
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            breakLabel="..."
                          /> */}
                      {/* </div>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainerWrapper />
    </>
  );
};

export default PermissionList;
