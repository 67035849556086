import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";
import { useLocation } from "react-router-dom";

const ProtectedRoutes = () => {
  const route = useLocation();
  sessionStorage.setItem("redirectUrl", route.pathname);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.user);
  const LoginDate = useSelector((state) => state.user.LoginDate);
  const DataLength = Object.keys(data).length;
  const currdate = new Date().getDate();

  useEffect(() => {
    if (LoginDate !== currdate || DataLength <= 0) {
      dispatch(logout());
    }
  }, [LoginDate, currdate, DataLength]);

  if (DataLength <= 0) {
    return <Navigate to={"/login"} />;
  } else {
    return <Outlet />;
  }
};
export default ProtectedRoutes;
