import { createStore, combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import Mode from "./setting/setting";
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import holidaysSlice from "./slices/holidaysSlice";

const persistconfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  mode: Mode,
  user: authSlice,
  holidays: holidaysSlice,
});
const persistedReducer = persistReducer(persistconfig, reducer);

const Store = configureStore({
  reducer: persistedReducer,
});

export default Store;
