import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Row
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import auth1 from "../../../assets/images/auth/01.png";
import auth7 from "../../../assets/images/auth/07.png";
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import Card from "../../../components/Card";
import { showToast } from "../../../plugins/toastUtils";
import { login } from "../../../store/slices/authSlice";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company_email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passType, setPassType] = useState("password");
  const showpass = () => {
    setPassType(passType === "password" ? "text" : "password");
  };
  const [activeStatus, setActiveStatus] = useState(false);
  const [networkError, setNetworkError] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    localStorage.getItem("activeStatus").then((value) => {
      if (value === true) {
        setActiveStatus(true);
      }
    });
    localStorage.getItem("serverError").then((value) => {
      if (value !== null) {
        setNetworkError({ status: true, message: value });
      }
    });
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("activeStatus");
      setActiveStatus(false);
      localStorage.removeItem("serverError");
      setNetworkError(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    return () => { };
  }, [networkError]);


  const onsubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const form = e.currentTarget;
      const isValid = form.checkValidity();
      if (isValid) {
        localStorage.removeItem("passwordError");
        const response = await dispatch(login({ company_email, password }));

        if (response.payload.status === 400) {
          showToast(response.payload.data.message, "error");
        } else if (response.payload.status === 200) {
          if (sessionStorage.getItem("redirectUrl") !== null) {
            navigate(sessionStorage.getItem("redirectUrl"));
            sessionStorage.removeItem("redirectUrl");
          } else {
            navigate("/");
          }
        } else {
          navigate("/403");
        }
      } else {
        setValidated(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      sessionStorage.removeItem("redirectUrl");
    }
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <Link
                      to="/dashboard"
                      className="navbar-brand d-flex align-items-center mb-3"
                    >
                      <img src={auth7} alt="" height={50} width={50} />
                      <h4 className="logo-title ms-3">CodeCrew</h4>
                    </Link>
                    <h2 className="mb-2 text-center">Sign In</h2>
                    <p className="text-center">Welcome To CodeCrew Infotech.</p>
                    <Form
                      onSubmit={(e) => onsubmit(e)}
                      noValidate
                      validated={validated}
                    >
                      <Row>
                        <Col lg="12" style={{ zIndex: "4" }}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              id="email"
                              aria-describedby="email"
                              placeholder=""
                              name="company_email"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type={passType}
                              id="password"
                              aria-describedby="password"
                              placeholder=""
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-between">
                          <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                              onClick={() => showpass()}
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Show Password
                            </Form.Check.Label>
                          </Form.Check>
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          type="submit"
                          disabled={loading}
                          variant="btn btn-primary"
                        >
                          {loading ? "Loading..." : "Sign In"}
                        </Button>
                      </div>
                      <p className="text-center my-3">Connect With Us</p>
                      <div className="d-flex justify-content-center">
                        <ListGroup
                          as="ul"
                          className="list-group-horizontal list-group-flush"
                        >
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <a
                              target="_blank"
                              href="https://www.facebook.com/profile.php?id=100089058859571&mibextid=LQQJ4d"
                            >
                              <Image src={facebook} alt="fb" />
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <a
                              target="_blank"
                              href="https://codecrewinfotech.com/"
                            >
                              <Image src={google} alt="gm" />
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <a
                              target="_blank"
                              href="https://www.instagram.com/codecrew_infotech/"
                            >
                              <Image src={instagram} alt="im" />
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item as="li" className="border-0 pb-0">
                            <a
                              target="_blank"
                              href="https://in.linkedin.com/company/codecrew-infotech-pvt-ltd"
                            >
                              <Image src={linkedin} alt="li" />
                            </a>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="sign-bg">
              <img
                src={auth7}
                alt=""
                height="50%"
                width="50%"
                style={{
                  opacity: "0.25",
                  position: "relative",
                  right: "3rem",
                  rotate: "400deg",
                }}
              />
            </div>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
          {/* <ToastContainerWrapper /> */}
        </Row>
      </section>
    </>
  );
};

export default SignIn;
