import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';
import './project.css';

function AddProject({ api }) {
    const [title, setTitle] = useState('');
    const [short_description, setShort_description] = useState('');
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [next_date, setNext_date] = useState('');
    const [projectstatus, setProjectstatus] = useState('');
    const [projectType, setProjectType] = useState('');
    const [technologyData, setTechnologyData] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [validated, setValidated] = useState(false);
    const [technolgy, setTechnolgy] = useState([]);
    const [projectEmployee, setProjectEmployee] = useState([]);
    const [technoError, setTechnoError] = useState(false);
    const [empError, setEmpError] = useState(false);
    const navigate = useNavigate();

    const projectdetail = async () => {
        await api.get(`/addProjects`).then((response) => {
            if (response.data.status === false) {
                navigate('/403')
            }
            if (response.data !== undefined) {
                setTechnologyData(response.data.technologyname);
                setEmployeeList(response.data.userName)
            }
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
        });
    }

    const AddProject = async (e) => {
        e.preventDefault();
        let Tech = [];
        technolgy.map((item) => Tech.push(item.value));
        let Emp = [];
        projectEmployee.map((item) => Emp.push(item.value));

        const form = e.currentTarget;
        const isValid = form.checkValidity();

        if (isValid) {
            await api.post(`/addProjects`, {
                title: title,
                short_description: short_description,
                start_date: start_date,
                end_date: end_date,
                status: projectstatus,
                technology: Tech,
                project_type: projectType,
                user_id: Emp,
            }).then((response) => {
                navigate('/project');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100)
            }).catch((error) => {
                if(error.response.status == 403){
                    navigate('/403')
                 }
                showToast(error.response.data.errors, 'error');
            })
        } else {
            if (technolgy.length > 0) {
                setTechnoError(false);
            }
            else {
                setTechnoError(true);
            }
            if (projectEmployee.length > 0) {
                setEmpError(false);
            } else {
                setEmpError(true);
            }
            setValidated(true);
        }
    }

    useEffect(() => {
        projectdetail();
        if (start_date !== '') {
            const startDate = new Date(start_date);
            startDate.setDate(startDate.getDate() + 1);
            const nextDate = startDate.toISOString().substr(0, 10);
            setNext_date(nextDate);
        }
        return () => {
        };
    }, [start_date]);

    useEffect(() => {
        if (technolgy.length > 0) {
            setTechnoError(false);
        }

        if (projectEmployee.length > 0) {
            setEmpError(false);
        }
        return () => {
        };
    }, [technolgy, projectEmployee]);

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Project</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>

                                <Form noValidate validated={validated} onSubmit={e => AddProject(e)}>
                                    <Row>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Project Title</Form.Label>
                                                <Form.Control type="text" id="title" onChange={e => setTitle(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Project title is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">Project Description</Form.Label>
                                                <Form.Control type="text" id="project_description" onChange={e => setShort_description(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Project Description is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Start Date</Form.Label>
                                                <Form.Control type="date" id="title" onChange={e => setStart_date(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Start Date is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">End Date</Form.Label>
                                                <Form.Control type="date" id="project_description" onChange={e => setEnd_date(e.target.value)} min={`${next_date}`} disabled={start_date ? false : true} />
                                                <Form.Control.Feedback type="invalid">
                                                    End Date is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <div className="form-group">
                                                <Form.Label htmlFor="permission_description">Project Status</Form.Label>
                                                <select name="" id="" className='form-select  shadow-none form-control' onChange={e => setProjectstatus(e.target.value)} required>
                                                    <option value="">Select</option>
                                                    <option value="on Hold">on Hold</option>
                                                    <option value="in Progress">in Progress</option>
                                                    <option value="Completed">Completed</option>
                                                </select>
                                                <Form.Control.Feedback type="invalid">
                                                    Project Status is required
                                                </Form.Control.Feedback>
                                            </div>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Label htmlFor="permission_description">Project Type</Form.Label>
                                            <select name="" id="" className='form-select  shadow-none form-control' onChange={e => setProjectType(e.target.value)} required>
                                                <option value="">Select</option>
                                                <option value="Fixed Cost">Fixed Cost</option>
                                                <option value="Dedicated">Dedicated</option>
                                                <option value="Hourly">Hourly</option>
                                            </select>
                                            <Form.Control.Feedback type="invalid">
                                                Project Type is required
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col sm="12" lg="6">
                                            <Form.Label htmlFor="role">Technology</Form.Label>
                                            <Select
                                                options={technologyData}
                                                // value='name'
                                                isMulti
                                                onChange={(data) => setTechnolgy(data)}
                                                required>
                                            </Select>
                                            {technoError === true &&
                                                <div className='text-danger'>
                                                    Technology is required
                                                </div>
                                            }
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Form.Group className="form-group ">
                                                <Form.Label htmlFor="role">Employees</Form.Label><br />
                                                <Select
                                                    options={employeeList}
                                                    isMulti
                                                    onChange={(data) => setProjectEmployee(data)}
                                                    required />
                                                {empError === true &&
                                                    <div className='text-danger'>
                                                        Please select employees
                                                    </div>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Button type="submit" variant="btn btn-primary me-2">Submit</Button>{' '}
                                            <Button type="button" variant="btn btn-danger" onClick={() => navigate
                                                ('/project')}>cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div >
        </>
    )
}

export default AddProject

