import { gasPump } from 'fontawesome';
import React, { useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const DetailsMasterItem = ({ api }) => {

    const navigate = useNavigate();
    const [ramOptions, setRamOptions] = React.useState([]);

    useEffect(() => {
        api.get(`/inventoryMaster`)
            .then((response) => {
                if (response.data !== undefined) {
                    if (response.data.status === false) {
                        navigate('/403');
                    } else {
                        api.get(`/getcpuData`)
                            .then((cpuResponse) => {
                                if (cpuResponse.data !== undefined) {
                                    if (cpuResponse.data.status === false) {
                                        navigate('/403');
                                    } else {
                                        const cpuData = cpuResponse.data.cpuData || [];
                                        setRamOptions(cpuData);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Error fetching CPU data:', error);
                            });
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching inventory items:', error);
            });
    }, []);

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Details of Inventory Item </h4>
                            </div>
                            <div>
                                <Form>
                                    <Button type="button" variant="btn btn-danger" onClick={() => navigate('/master-item')}>
                                        Cancel
                                    </Button>
                                </Form>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col sm="12">
                                    <div className='d-flex flex-wrap align-items-center' style={{ gap: "10px" }}>
                                        <div>
                                            <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                                                Name :-
                                            </Form.Label>
                                            <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                                                <Form.Control
                                                    type="text"
                                                    id="cpu_item"
                                                    value={""}
                                                    onChange={(e) => ""}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter valid information</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div>
                                            <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                                                description :-
                                            </Form.Label>
                                            <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                                                <Form.Control
                                                    type="text"
                                                    id="cpu_item"
                                                    value={""}
                                                    onChange={(e) => ""}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter valid information</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div>
                                            <Form.Label style={{ marginBottom: '0px' }} htmlFor="cpu_item">
                                                unique ID :-
                                            </Form.Label>
                                            <Form.Group className="form-group" style={{ marginTop: '10px' }}>
                                                <Form.Control
                                                    type="text"
                                                    id="cpu_item"
                                                    value={""}
                                                    onChange={(e) => ""}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter valid information</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </>
    )
}

export default DetailsMasterItem
