import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditLeave({ api }) {
    const { id } = useParams();
    const { userID } = useParams();
    const [reason, setReason] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [calenderStart_date, setCalenderStart_date] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [next_date, setNext_date] = useState('');
    const [halfDay, setHalfDay] = useState('');
    const [totalLeaveDay, setTotalLeaveDay] = useState('');
    const [validated, setValidated] = useState(false);
    const [adHockStatus, setAdHockStatus] = useState('');
    const [holidays, setHolidays] = useState([]);
    const [existDates, setExistDates] = useState([]);
    const [existDateFromError, setExistDateFromError] = useState(false);
    const [existDateToError, setExistDateToError] = useState(false);
    const [matchingDates, setMatchingDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paidStatus, setPaidStatus] = useState('');
    const [userId, setUserId] = useState('');
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/editLeave/${id}/${userID}`).then((response) => {
            setAdHockStatus(response.data.leavesData.is_adhoc);
            setReason(response.data.leavesData.reason);
            setHalfDay(response.data.leavesData.half_day);
            setPaidStatus(response.data.leavesData.paid_status);
            setUserId(response.data.leavesData.user_id)
            setUserList(response.data.userData);
            setTotalLeaveDay(response.data.leavesData.total_days)

            const dateStr = response.data.leavesData.datefrom;

            const formattedDate = moment(dateStr).format("YYYY-MM-DD");
            setDateFrom(formattedDate);
            setCalenderStart_date(formattedDate);

            const dateToStr = response.data.leavesData.dateto;

            const formattedDateTo = moment(dateToStr).format("YYYY-MM-DD");
            setDateTo(formattedDateTo);

            const holidayDates = response.data.allHolidayDate;

            const hhData = []
            for (let i = 0; i < holidayDates.length; i++) {
                const momentDate = moment(holidayDates[i]);
                const formattedDate = momentDate.format('DD-MM-YYYY');
                hhData.push(formattedDate);
            }
            setHolidays(hhData);
            let existdate = response.data.existLeaveDates;
            const dates = [];
            existdate.forEach(item => {
                const start = moment(item.datefrom);
                const end = moment(item.dateto);
                while (start <= end) {
                    dates.push(start.format('YYYY-MM-DD'));
                    start.add(1, 'day');
                }
            });
            const uniqueDates = [...new Set(dates)];
            setExistDates(uniqueDates);
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            console.log("error", error)
        })
        return () => {
        };
    }, []);

    useEffect(() => {
        if (dateFrom !== '') {
            setNext_date(dateFrom);
        }
        if(halfDay !== ''){
            setDateTo(dateFrom)
        }
        calculateTotalLeaveDays();
        return () => {
        };
    }, [dateFrom, dateTo]);

    useEffect(() => {
        if (adHockStatus || halfDay === '') {
            // document.getElementById('From').value = '';
            document.getElementById('To').value = document.getElementById('From').value;
        }
        if(halfDay !== ''){
            setDateTo(dateFrom)
        }
        calculateTotalLeaveDays();

    }, [adHockStatus, halfDay]);

    useEffect(() => {
        if (dateFrom) {
            if (existDates.includes(dateFrom)) {
                setExistDateFromError(true);
            } else {
                setExistDateFromError(false);
            }
        }
        if (dateFrom && dateTo) {
            const start = dateFrom;
            const end = dateTo;
            const dates = [];
            let curr = moment(start).startOf('day');
            while (curr.add(1, 'days').diff(end) < 0) {
                dates.push(new Date(curr.clone().toDate()).toISOString().split('T')[0]);
            }
            const formattedDates = [dateFrom, ...dates.map(date => date), dateTo];
            const matchingDates = formattedDates.filter(date =>
                existDates.includes(date)
            );
            if (matchingDates.length > 0) {
                setExistDateToError(true);
            } else {
                setExistDateToError(false);
            }
            setMatchingDates(matchingDates);
        }
        return () => {
        };
    }, [dateFrom, dateTo]);

    const calculateTotalLeaveDays = () => {
        if (dateFrom && dateTo) {
            const start = moment(dateFrom, 'YYYY-MM-DD');
            const end = moment(dateTo, 'YYYY-MM-DD');
            let totalLeaveDays = 0;

            for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
                const dayOfWeek = m.format('dddd');
                const currentDate = m.format('DD-MM-YYYY');
                if (dayOfWeek !== 'Sunday' && dayOfWeek !== 'Saturday' && !holidays.includes(currentDate)) {
                     totalLeaveDays++;
                }
            }
            if (halfDay === '') {
                setTotalLeaveDay(totalLeaveDays);
            } else {
                setTotalLeaveDay(0.5)
            }
        }
    };

    const HandleAdHocLeaves = (e) => {
        const { checked } = e.target;
        if (checked) {
            setAdHockStatus('1');
        } else {
            setAdHockStatus('0');
        }
    }

    const LeaveRequest = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid && !existDateFromError && !existDateToError) {
            try {
                const response = await api.post(`/editLeave/${id}`, {
                    user_id: userId,
                    reason: reason,
                    datefrom: dateFrom,
                    dateto: dateTo,
                    is_adhoc: adHockStatus,
                    half_day: halfDay,
                    totalLeaveDay: totalLeaveDay,
                    paidStatus: paidStatus
                });
                navigate('/leaves');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            } catch (error) {
                if(error.response.status == 403){
                    navigate('/403')
                 }
                setLoading(false);
                showToast(error.response.data.errors, 'error');
            }
        } else {
            setLoading(false);
            setValidated(true);
        }
    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Leave Request</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={e => LeaveRequest(e)} noValidate validated={validated}>
                                    <Row>
                                        <Col sm="12" lg="4" className='d-flex align-items-end'>
                                            <Form.Group className="form-group">
                                                <div className="bd-example">
                                                    <ListGroup>
                                                        <ListGroupItem as="label" style={{ paddingRight: '15rem' }}>
                                                            {adHockStatus === 1 ?
                                                                <input className="form-check-input me-1" type="checkbox" defaultValue="" onChange={(e) => HandleAdHocLeaves(e)} checked />
                                                                :
                                                                <input className="form-check-input me-1" type="checkbox" defaultValue="" onChange={(e) => HandleAdHocLeaves(e)} />
                                                            }
                                                            Ad-Hoc Levaes
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Reason</Form.Label>
                                                <Form.Control type="text" id="title" value={reason} onChange={e => setReason(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Label htmlFor="permission_description">Select Half Day:</Form.Label>
                                            <select
                                                name=""
                                                id=""
                                                className="form-select mb-3 shadow-none form-control"
                                                onChange={(e) => setHalfDay(e.target.value)}
                                                value={halfDay}>
                                                <option value="">Full Leave</option>
                                                <option value="firsthalf">First Half (AM)</option>
                                                <option value="secondhalf">Second Half (PM)</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">From</Form.Label>
                                                <Form.Control type="date" id="From" onChange={e => setDateFrom(e.target.value)}
                                                    min={adHockStatus === "0" ? calenderStart_date : "1900-01-01"}
                                                    max={adHockStatus === "1" ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0] : ""}
                                                    style={existDateFromError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    value={dateFrom}
                                                />
                                                {existDateFromError &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        You have already taken leave of {moment(dateFrom).format('D MMMM')}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">To</Form.Label>
                                                <Form.Control type="date" id="To" onChange={e => setDateTo(e.target.value)}
                                                    min={`${next_date}`}
                                                    max={halfDay !== '' ? dateFrom : adHockStatus === 1 ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0] : ""}
                                                    disabled={dateFrom && !existDateFromError ? false : true}
                                                    style={existDateToError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    value={dateTo}
                                                />
                                                {existDateToError && (
                                                    <Form.Control.Feedback type="invalid" className="d-block">
                                                        You have already taken leave of {matchingDates && matchingDates.map((item, index) => {
                                                            const formattedDate = moment(item).format("D MMMM");
                                                            return index === matchingDates.length - 1 ? formattedDate !== dateFrom && formattedDate : formattedDate !== dateFrom && formattedDate + ", ";
                                                        })}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Total Leave Day:</Form.Label>
                                                <Form.Control type="text" id="title" value={totalLeaveDay} onChange={e => setTotalLeaveDay(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Leave Status</Form.Label>
                                                <div>
                                                    <select
                                                        name=""
                                                        id=""
                                                        className="form-select mb-3 shadow-none form-control"
                                                        onChange={(e) => setPaidStatus(e.target.value)}
                                                        value={paidStatus}>
                                                        <option value="PAID">Paid</option>
                                                        <option value="UNPAID">Unpaid</option>
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Employees</Form.Label>
                                                <div>
                                                    <select
                                                        name="user-list-table_length" aria-controls="user-list-table"
                                                        className="form-select mb-3 shadow-none form-control"
                                                        value={userId}
                                                        onChange={(e) => setUserId(e.target.value)}>
                                                        {userList && userList.map((item, index) => (
                                                            <option value={item._id} key={index}>{item.firstname + ' ' + item.last_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Col sm="12" lg="6">
                                        <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                                            {loading ? 'Updating...' : 'Update'}
                                        </Button>
                                        <Button type="button" variant="btn btn-danger" onClick={() => navigate('/leaves')}>Cancel</Button>
                                    </Col>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div>
        </>
    )
}

export default EditLeave;
