import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const UserInfoModal = ({ userData, inventoryData, showModal, handleClose }) => {
    const renderInventoryField = (fieldName) => {
        return inventoryData && inventoryData[fieldName] ? (
            <b>{inventoryData[fieldName].name}</b>
        ) : (
            <b>N/A</b>
        );
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>User Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Name:  <b>{userData.firstname} {userData.last_name} </b></p>
                <p>Dashboard Name:  <b> {renderInventoryField('DASHBOARD')} </b></p>
                <p>Keyboard Name: <b> {renderInventoryField('KEYBOARD')} </b></p>
                <p>Mouse Name: <b> {renderInventoryField('MOUSE')} </b></p>
                <p>CPU Name: <b> {renderInventoryField('CPU')} </b></p>
                <p>HeadPhone Name: <b> {renderInventoryField('HEADPHONE')} </b></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserInfoModal;