import React, { createContext, useEffect, useState } from 'react'
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import Loader from '../../components/Loader'
import SettingOffcanvas from '../../components/partials/components/settingoffcanvas'
import Footer from '../../components/partials/dashboard/FooterStyle/footer'
import Header from '../../components/partials/dashboard/HeaderStyle/header'
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header'
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast } from '../../plugins/toastUtils'
import DefaultRouter from '../../router/default-router'
import { ColorCustomizerAction, ModeAction, NavbarstyleAction, SchemeDirAction, SidebarActiveStyleAction, SidebarColorAction, getDarkMode, getDirMode, getNavbarStyleMode, getSidebarActiveMode, getSidebarColorMode, getSidebarTypeMode, getcustomizerMode, getcustomizerinfoMode, getcustomizerprimaryMode } from '../../store/setting/setting'
import api from '../../utils/api'


const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})
const PermissionData = createContext();
const Default = (props) => {

    useEffect(() => {
        const colorMode = sessionStorage.getItem('color-mode');
        if (colorMode === null) {
            props.ModeAction(props.darkMode);
        }
        else {
            props.ModeAction(colorMode);
        }

        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.SchemeDirAction(props.schemeDirMode)
        }
        else {
            props.SchemeDirAction(rtlMode);
        }
        return () => {
        };
    }, []);

    // new code 
    let user_id = props.user_id;
    let role_id = props.role_id;

    const [userPermission, setUserPermission] = useState([]);
    const UserPermission = async () => {
        await api.get(`/checkUserHasPermission/${user_id}/${role_id}`).
            then((response) => {
                setUserPermission(response.data.Allpermission);
            }).catch((error) => {
            })
    }
    useEffect(() => {
        UserPermission();
        return () => {
        };
    }, []);

    // const api = process.env.REACT_APP_API_KEY;
    const userdata = useSelector((state) => { return state.user });
    const imagePath = process.env.REACT_APP_IMAGE_KEY;
    const [announce, setannounce] = useState(false);
    const announcementSetting = (e) => {
        setannounce(e);
    }

    return (
        <>
            <Loader />
            <ToastContainerWrapper />
            <PermissionData.Provider value={userPermission}>
                <Sidebar api={api} imagePath={imagePath} />
            </PermissionData.Provider>
            <main className="main-content">
                <ToastContainerWrapper />
                <div className="position-relative">
                    <ToastContainerWrapper />
                    <Header api={api} userdata={userdata} imagePath={imagePath} announce={announce} />
                    <PermissionData.Provider value={userPermission}>
                        <SubHeader api={api} announcementSetting={announcementSetting} />
                    </PermissionData.Provider>
                </div>
                <div className="py-0 conatiner-fluid content-inner mt-n5">
                    <ToastContainerWrapper />
                    <PermissionData.Provider value={userPermission}>
                        <DefaultRouter api={api} showToast={showToast} />
                    </PermissionData.Provider>
                </div>
                <Footer />
            </main>
            <SettingOffcanvas />
            <ToastContainerWrapper />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Default)
export { PermissionData }




