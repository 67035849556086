import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import Card from '../../components/Card'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast } from '../../plugins/toastUtils'

const JunkEmployee = ({ api, imagePath }) => {
    const themeData = useSelector((state) => state.mode.colorcustomizer);
    const [deletedemployee, setDeletedemployee] = useState([]);
    const [deleteid, setDeleteid] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [multiDelete, setMultiDelete] = useState([]);
    const [multidelshow, setMultidelshow] = useState(false);
    const navigate = useNavigate();

    const employeedata = () => {
        api.get(`/deleteduser`).then((response) => {
            if (response.data.userData?.length > 0) {
                setDeletedemployee(response.data.userData);
            } else {
                setDeletedemployee([]);
            }
        }).catch((error) => {
            showToast(error.response.data.errors, 'error');
        });
    }

    const Handlerestore = () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            api.post(`/restoreuser/${id}`, '').then((response) => {
                navigate('/employee')
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
                setShow(false);
                employeedata();
            }).catch((error) => {
                showToast(error.response.data.errors, 'error');
            });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    function restoremodel(id) {
        setDeleteid(id);
        setShow(true);
    }

    const HandleMultiDelete = (e) => {
        const { value, name, checked } = e.target;
        if (checked) {
            setMultiDelete([...multiDelete, name]);
            deletedemployee.map((d) => d._id === name ? { ...d, isChecked: checked } : d)
        }
        else {
            setMultiDelete(multiDelete.filter((e) => e !== name));
        }
    }

    const PostMultidelete = async () => {
        setLoading(true);
        if (multiDelete.length > 0) {
            await api.post(`/deleteMany/`, {
                multiDelete
            }).then((response) => {
                showToast(response.data.message, "success")
                setMultidelshow(false);
                setMultiDelete([]);
                employeedata();
            }).catch((error) => {
                showToast(error.response.data.errors, 'error');
            });
        }
        setLoading(false);
    }

    const handleMultidelClose = () => {
        setMultidelshow(false);
    }

    function Multideletemodel() {
        setMultidelshow(true);
    }

    useEffect(() => {
        employeedata();
    }, []);

    return (
        <>
            <div>
                <Row>
                    <Col sm="12">

                        <Modal show={multidelshow} onHide={handleMultidelClose} className='text-center'>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button variant="primary" className='me-2' disabled={loading} onClick={() => PostMultidelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                                <Button variant="danger" onClick={() => handleMultidelClose()} value="Cancel" >Cancel</Button>
                            </Modal.Body>
                        </Modal>
                        <Modal show={show} onHide={handleClose} className='text-center'>
                            <Modal.Header closeButton>
                                <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to Restore it!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button variant="primary" className='me-2' disabled={loading} onClick={() => Handlerestore()}>{loading ? 'Restoring...' : 'Restore'}</Button>
                                <Button variant="danger" onClick={handleClose} value="Cancel" >Cancel</Button>
                            </Modal.Body>
                        </Modal>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Deleted Employees</h4>
                                </div>
                                <div>
                                    <Button type="button" variant="btn btn-danger" onClick={() => navigate('/employee')}>Cancel</Button>
                                </div>
                            </Card.Header>
                            <Card.Header className={`d-flex justify-content-between`}>
                                <div className="row align-items-center w-100">
                                    <div className="col-lg-6 col-md-12">
                                        {multiDelete.length > 0 &&
                                            <button type="button" className={`d-flex bg-danger border-0 btn btn-primary p-0 px-1 py-1`} style={{ position: 'relative', float: 'right' }} onClick={() => Multideletemodel()}>
                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z" fill="currentColor" />
                                                    <path d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z" fill="currentColor" />
                                                </svg>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                        <thead>
                                            <tr className="ligth">
                                                <th>#</th>
                                                <th>Profile</th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Employee Code</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Name</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Contact</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Email</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Status</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className=" d-flex ">
                                                        <span className="title">Join Date</span>
                                                    </div>
                                                </th>
                                                <th min-width="100px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deletedemployee.length > 0 ?
                                                deletedemployee.map((item, index) => (
                                                    <tr key={index} className="border-light">
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                name={item._id}
                                                                defaultChecked={item.isChecked}
                                                                onChange={(e) => HandleMultiDelete(e)}
                                                            />
                                                        </td>
                                                        <td><Image className="bg-soft-primary rounded-circle img-fluid " src={item.photo ? imagePath + item.photo :
                                                            themeData === 'theme-color-default' ? avatars1 :
                                                                themeData === 'theme-color-blue' ? avatars2 :
                                                                    themeData === 'theme-color-gray' ? avatars3 :
                                                                        themeData === 'theme-color-red' ? avatars5 :
                                                                            themeData === 'theme-color-yellow' ? avatars6 :
                                                                                themeData === 'theme-color-pink' && avatars4
                                                        }
                                                            alt="profile-pic" style={{ height: '50px', width: '50px' }} /></td>
                                                        <td>{item.emp_code}</td>
                                                        <td>{item.firstname}</td>
                                                        <td>{item.mo_number}</td>
                                                        <td>{item.company_email}</td>
                                                        <td><span style={{ fontWeight: '200' }} className={`badge ${item.status === "ExEmployee" ? "bg-danger" : item.status === "Pending" ? "bg-warning" : item.status === "InActive" ? "bg-info" : item.status === "Active" ? 'bg-success' : 'bg-primary'} p-2 w-100`}>{item.status}</span></td>
                                                        <td>{moment(item.doj).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            <div className="flex align-items-center list-user-action">
                                                                <Link onClick={() => { restoremodel(item._id) }} className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to='#'>
                                                                    <svg width="30px" height="30px" viewBox="0 0 21 18" version="1.1">
                                                                        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <g id="Two-Tone" transform="translate(-577.000000, -553.000000)">
                                                                                <g id="Action" transform="translate(100.000000, 100.000000)">
                                                                                    <g id="Two-Tone-/-Action-/-restore" transform="translate(476.000000, 450.000000)">
                                                                                        <g>
                                                                                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                                                            <path d="M13,3 C8.03,3 4,7.03 4,12 L1,12 L5,15.99 L9,12 L6,12 C6,8.13 9.13,5 13,5 C16.87,5 20,8.13 20,12 C20,15.87 16.87,19 13,19 C11.07,19 9.32,18.21 8.06,16.94 L6.64,18.36 C8.27,19.99 10.51,21 13,21 C17.97,21 22,16.97 22,12 C22,7.03 17.97,3 13,3 Z M12,8 L12,13 L16.25,15.52 L17.02,14.24 L13.5,12.15 L13.5,8 L12,8 Z" id="🔹-Primary-Color" fill="#1aa053"></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>))
                                                :
                                                (
                                                    <tr>
                                                        <td colSpan="99" className='text-center'><button className="btn btn-primary" type="button" disabled>
                                                            <span className="" role="status" aria-hidden="true"></span>
                                                            No Data Found
                                                        </button></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div>
        </>
    )
}

export default JunkEmployee
