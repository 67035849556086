import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VerticalNav from '../SidebarStyle/vertical-nav'
import Scrollbar from 'smooth-scrollbar'
import { bindActionCreators } from "redux"
import { NavbarstyleAction, getDirMode, SchemeDirAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../../store/setting/setting'
import { connect } from "react-redux"

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
        },
        dispatch
    )
})

const Sidebar = (props) => {

    const [minisidebarLogo, setMinisidebarLogo] = useState(false);
    useEffect(
        () => {
            // sidebarcolormode
            const sidebarcolorMode1 = sessionStorage.getItem('sidebarcolor-mode');
            if (sidebarcolorMode1 === null) {
                props.SidebarColorAction(props.sidebarcolorMode);
            }
            else {
                props.SidebarColorAction(sidebarcolorMode1);
            }

            // sidebarstylemode
            const sidebarstyleMode = sessionStorage.getItem('sidebarstyle-mode');
            if (sidebarstyleMode === null) {
                props.SidebarActiveStyleAction(props.sidebaractivestyleMode);
            }
            else {
                props.SidebarActiveStyleAction(sidebarstyleMode);
            }
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }



    )
    const minisidebar = () => {
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini');
        setMinisidebarLogo(!minisidebarLogo);
    }
    const [logopath, setLogopath] = useState([]);

    const Logo = () => {
        props.api.post(`/getSettingData`, {
            key: 'logo,mini-logo'
        }).then((response) => {
            if (response.data.status === false) {
            }
            else {
                setLogopath(response.data)
            }
        }).catch((error) => {
        })
    }
    useEffect(() => {
        Logo();
        return () => {
        };
    }, []);
    const screenWidth = window.innerWidth;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return (
        <>
            <aside className="sidebar sidebar-default navs-rounded-all {{ sidebarVariants }}">
                <div className="sidebar-header d-flex align-items-center justify-content-start">
                    <Link to="/dashboard" className="navbar-brand" style={{
                        height: '5.3rem',
                        // overflow: "visible",
                        transition: "max-width 0.5s ease-in-out"
                    }}>
                        {
                            minisidebarLogo ? (
                                screenWidth <= 420 ?
                                    <img src={props.imagePath + logopath[0]} alt="" /> :
                                    <img src={props.imagePath + logopath[1]} alt="" />
                            ) : (
                                <img src={props.imagePath + logopath[0]} alt="" style={{ width: isSafari && '12rem' }} />
                            )
                        }
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar} onKeyDown={minisidebar}>
                        <i className="icon">
                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </i>
                    </div>
                </div>
                <div className="pt-0 sidebar-body data-scrollbar" data-scroll="1" id="my-scrollbar">
                    {/* sidebar-list class to be added after replace css */}
                    <div className="sidebar-list navbar-collapse" id="sidebar">
                        <VerticalNav />
                    </div>
                </div>
                <div className="sidebar-footer"></div>
            </aside>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

