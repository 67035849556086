import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddTask({ api, user_data }) {

    const [title, setTitle] = useState("");
    const [task_type, setTask_type] = useState("0");
    const [short_description, setShort_description] = useState("");
    const [project, setProject] = useState("");
    const [employee, setEmployee] = useState("");
    const [projectlist, setProjectlist] = useState([]);
    const [employeelist, setEmployeelist] = useState([]);
    const [prevProject, setPrevProject] = useState("");
    const [hours, setHours] = useState(0);
    const [hoursError, setHoursError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const projectdetail = async () => {
        await api.get(`/addtask`)
            .then((response) => {
                if (response.data !== undefined) {
                    setProjectlist(response.data.projectData);
                    setEmployeelist(response.data.userData);
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    navigate('/403')
                 }
            });
    }

    useEffect(() => {
        if (project) {
            api.post(`/getUserByProject/${project}`, '')
                .then((response) => {
                    if (response.data !== undefined) {
                        setEmployeelist(response.data.userProjectData.userData);
                    }
                }).catch((error) => {
                });
            if (prevProject !== project) {
                resetEmployeeList();
            }
            setPrevProject(project);
        }
        return () => {

        };
    }, [project]);

    const AddTask = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            setLoading(true);
            try {
                const response = await api.post(`/addTask`, {
                    project_id: project,
                    user_id: employee,
                    title: title,
                    task_type: task_type,
                    short_description: short_description,
                    task_estimation: hours,
                });
                navigate('/task');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100)
            } catch (error) {
                if(error.response.status == 403){
                    navigate('/403')
                 }
                showToast(error.response.data.errors, 'error');
            } finally {
                setLoading(false);
            }
        } else {
            setValidated(true);
        }
    }

    const getEmpData = (e) => {
        setProject(e.target.value);
    }

    const resetEmployeeList = () => {
        setEmployeelist([]);
    };

    useEffect(() => {
        projectdetail();
    }, []);

    useEffect(() => {
        if (hours > 24 || hours < 0) {
            setHoursError(true);
        }
        else {
            setHoursError(false);
        }
        return () => {
        };
    }, [hours]);

    const optionsType = [
        { value: '0', label: 'Feature' },
        { value: '1', label: 'Bug' },
    ]

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Create Task</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => AddTask(e)} noValidate validated={validated}>
                                <Row>
                                    <Col sm="12" lg="4">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Project</Form.Label>
                                            <select name="" id="" className='form-select mb-3 shadow-none form-control' onChange={e => getEmpData(e)}>
                                                <option value="">Select</option>
                                                {projectlist?.length > 0 ?
                                                    projectlist.map((item, index) => (
                                                        < option value={item._id} key={index} > {item.title}</option>
                                                    ))
                                                    :
                                                    <option disabled>No data found</option>
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Label htmlFor="permission_description">Employee</Form.Label>
                                        <select
                                            name=""
                                            id=""
                                            className="form-select mb-3 shadow-none form-control"
                                            onChange={(e) => setEmployee(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            {project !== "" &&
                                                (employeelist?.length > 0 ? (
                                                    employeelist.map((item, index) => (
                                                        <option value={item._id} key={index}>
                                                            {item.firstname}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option>No data found</option>
                                                ))}
                                        </select>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Type</Form.Label>
                                            <select
                                                name=""
                                                id=""
                                                className="form-select mb-3 shadow-none form-control"
                                                defaultValue={task_type}
                                                onChange={(e) => setTask_type(e.target.value)}
                                            >
                                                {optionsType?.length > 0 ? (
                                                    optionsType.map((item, index) => (
                                                        <option value={item.value} key={index}>
                                                            {item.label}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option>No data found</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>

                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Title</Form.Label>
                                            <Form.Control type="text" id="title" onChange={e => setTitle(e.target.value)} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="task_description">Description</Form.Label>
                                            <Form.Control type="text" id="task_description" onChange={e => setShort_description(e.target.value)} autocomplete="off" />
                                        </Form.Group>
                                    </Col>

                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Estimated hours</Form.Label>
                                            <Form.Control type="number" id="title" value={hours} onChange={e => setHours(e.target.value)} step={0.5} min={0}
                                                style={hoursError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm="12" lg="6">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <button
                                                type="submit"
                                                className="btn btn-primary me-2"
                                                disabled={loading}
                                                onClick={(e) => AddTask(e)}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => navigate('/task')}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
            <ToastContainerWrapper />
        </>
    )
}

export default AddTask

