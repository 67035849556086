import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card";

function EditSettting({ api, imagePath }) {

  const navigate = useNavigate();
  const Cancel = () => {
    navigate("/setting");
  };
  const { id } = useParams();
  const [key, setKey] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [valuePreview, setValuePreview] = useState("");
  const [validated, setValidated] = useState(false);
  const [existKey, setExistkey] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleEditModel = async () => {
    await api.get(`/editSetting/${id}`).then((response) => {
      setType(response.data.settingData.type);
      setKey(response.data.settingData.key);
      setValue(response.data.settingData.value);
      setValuePreview(imagePath + response.data.settingData.value);
    }).catch((error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    HandleEditModel();
    return () => { };
  }, []);

  const UpdateSetting = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isValid = form.checkValidity();
    const formData = new FormData();
    formData.set("key", key);
    formData.set("type", type);
    formData.set("value", value);
    if (isValid) {
      setLoading(true);
      await api.post(`/editSetting/${id}`, formData).then((response) => {
        if (response.data.existKeyStatus === true) {
          setExistkey(true);
        } else {
          navigate("/setting");
        }
      }).catch((error) => {
        console.log("error", error)
        setLoading(false);
      });
    } else {
      setValidated(true);
      setLoading(false);
    }
  };

  const handlePhotoChange = (event) => {
    const selectedPhoto = event.target.files[0];
    setValue(selectedPhoto);
    const imageObjectURL = URL.createObjectURL(selectedPhoto);
    setValuePreview(imageObjectURL);
  };

  return (
    <div>
      <Row>
        <Col sm="12" lg="6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Edit Setting</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Form
                id="form-wizard1"
                onSubmit={(e) => UpdateSetting(e)}
                noValidate
                validated={validated}
              >
                <Form.Group className="form-group">
                  <Form.Label htmlFor="value">Key</Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    id="value"
                    onChange={(e) => setKey(e.target.value)}
                    value={key}
                    required
                    style={
                      existKey
                        ? { backgroundImage: "none", borderColor: "#c03221" }
                        : {}
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                  {existKey && (
                    <Form.Control.Feedback className="d-block text-danger">
                      This Key is already exist
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="role">Type</Form.Label>
                  <Form.Control
                    className=""
                    as="select"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    <option value="text">Text</option>
                    <option value="image">Image</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Col lg="12">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="value">Value</Form.Label>
                    {type === "text" ? (
                      <>
                        <Form.Control
                          className=""
                          value={value}
                          type="text"
                          id="value"
                          onChange={(e) => setValue(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </>
                    ) : (
                      <div className="edit-setting-value d-flex justify-content-between">
                        <Col lg="8">
                          <Form.Control
                            className=""
                            type="file"
                            id="value"
                            onChange={(e) => handlePhotoChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Col>
                        <Col lg="3">
                          <img
                            src={valuePreview}
                            alt=""
                            srcset=""
                            height={40}
                          />
                        </Col>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Button variant="primary" disabled={loading} type="submit">
                  {loading ? "Updating..." : "Update"}
                </Button>{" "}
                <Button
                  variant="danger"
                  type="reset"
                  onClick={() => Cancel()}
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EditSettting;
