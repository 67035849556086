import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

const EditMasterItem = ({ api }) => {

    const navigate = useNavigate();
    const [ramOptions, setRamOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const formik = useFormik({
        initialValues: {
            Name: '',
            Description: '',
            UniqueID: '',
            MainKey: "",
            selectedOptions: {},
            selectedOptionsID: {},
        },
        onSubmit: async (values) => {
            const cpuDataValues = Object.values(formik.values.selectedOptions).flatMap(option => option.map(data => data.value));
            const payload = {
                inventoryItemID: values?.selectedInventoryItem?._id,
                name: values.Name,
                description: values.Description,
                uniqueID: values.UniqueID,
                cpu_data: cpuDataValues,
            };
            try {
                setLoading(true);
                const response = await api.post(
                    `/editInventoryItem/${id}`,
                    { payload }
                );
                setLoading(false);
                navigate('/master-item');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            } catch (error) {
                setLoading(false);
                showToast(error.response.data.errors,'error');
                console.error('Error updating inventory item:', error);
            }
        },

    });

    useEffect(() => {
        api.get(`/inventoryMaster`)
            .then((response) => {
                if (response.data !== undefined) {
                    if (response.data.status === false) {
                        navigate('/403');
                    } else {
                        const inventoryData = response.data.inventory || [];
                        formik.setFieldValue('inventoryItems', inventoryData);
                        api.get(`/getcpuData`)
                            .then((cpuResponse) => {
                                if (cpuResponse.data !== undefined) {
                                    if (cpuResponse.data.status === false) {
                                        navigate('/403');
                                    } else {
                                        const cpuData = cpuResponse.data.cpuData || [];
                                        setRamOptions(cpuData);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Error fetching CPU data:', error);
                            });
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching inventory items:', error);
            });
    }, []);

    useEffect(() => {
        api.get(`/editInventoryItem/${id}`)
            .then((response) => {
                if (response.data !== undefined) {
                    if (response.data.status === false) {
                        navigate('/403');
                    } else {
                        const { InventoryItemData } = response.data;
                        const formattedObject = InventoryItemData[0].cpu_data.reduce((acc, item) => {
                            if (!acc[item.key]) {
                                acc[item.key] = [];
                            }
                            acc[item.key].push({ value: item._id, label: item.value });
                            return acc;
                        }, {});
                        formik.setFieldValue('Name', InventoryItemData[0].name);
                        formik.setFieldValue('Description', InventoryItemData[0].description);
                        formik.setFieldValue('UniqueID', InventoryItemData[0].unique_id);
                        formik.setFieldValue('MainKey', InventoryItemData[0].main_key);
                        formik.setFieldValue('selectedOptions', formattedObject);
                        formik.setFieldValue('selectedOptionsID', InventoryItemData);
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching inventory items:', error);
            });
    }, [id]);

    return (
        <Row>
            <Col sm="12" lg="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Edit Master Inventory Item </h4>
                        </div>
                        <div>
                            <Form onSubmit={formik.handleSubmit}>
                                <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Update Master Inventory Item'}
                                </Button>
                                <Button type="button" variant="btn btn-danger" onClick={() => navigate('/master-item')}>
                                    Cancel
                                </Button>
                            </Form>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col md={6}>
                                <h6 className="mb-1">Edit master inventory details :</h6>
                                <div>
                                    <Form.Group>
                                        <Form.Label>Name:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Name"
                                            value={formik.values.Name}
                                            onChange={formik.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Description"
                                            value={formik.values.Description}
                                            onChange={formik.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Unique ID:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="UniqueID"
                                            value={formik.values.UniqueID}
                                            onChange={formik.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div>
                                    <div className="row">
                                        {ramOptions.map((obj, index) => {
                                            if (formik.values.MainKey == "CPU") {
                                                return (
                                                    (
                                                        <div key={index} className="col-md-6">
                                                            {Object.keys(obj).map((key, innerIndex) => (
                                                                <div key={innerIndex}>
                                                                    <label style={{ marginBottom: '0px' }} htmlFor={`cpu_item_${index}_${innerIndex}`}>
                                                                        {key} :-
                                                                    </label>
                                                                    <Select
                                                                        isMulti
                                                                        options={obj[key].map((data) => ({ value: data._id, label: data.value }))}
                                                                        onChange={(selected) => {
                                                                            formik.setFieldValue(`selectedOptions.${key}`, selected);
                                                                            const selectedIds = (selected || []).map((option) => option.value);
                                                                            formik.setFieldValue(`selectedOptionsID.${key}`, selectedIds);
                                                                        }}
                                                                        value={formik.values.selectedOptions[key] || []}
                                                                        id={`cpu_item_${index}_${innerIndex}`}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <ToastContainerWrapper />
        </Row >
    );
};

export default EditMasterItem;
