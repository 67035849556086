import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { useLocation } from 'react-router-dom';
import { showToast } from '../../plugins/toastUtils'

const EditTechnology = ({ api }) => {

    const location = useLocation();
    const query = location.state;
    const [value, setValue] = useState(query.data.technology)
    const [id] = useState(query.data._id)
    const [validated] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const EditTechnology = async (e) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/technology/${id}`, { technology: value }).then((response) => {
            navigate("/technology");
            setTimeout(() => {
                showToast(response.data.message, "success")
            }, 300);
        }).catch((error) => {
            if(error.response.status == 403){
                navigate('/403')
             }
            showToast(error.response.data.errors, 'error');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Technology</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => EditTechnology(e)} noValidate={validated}>
                                <Row>
                                    <Col sm="12" lg="6">
                                        <Form.Label htmlFor="permission_description">Technology Name :-</Form.Label>
                                        <input
                                            name=""
                                            id=""
                                            className='form-input mb-3  form-control'
                                            onChange={(e) => setValue(e.target.value)}
                                            defaultValue={value}
                                            required />

                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm="12" lg="12">
                                        <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</Button>
                                        <Button type="button" variant="btn btn-danger" onClick={() => navigate
                                            ('/technology')}>cancel</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default EditTechnology