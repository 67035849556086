import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { showToast } from '../../plugins/toastUtils';

function AddPermissionModule({ api }) {
    const [permissionModule, setpermissionModule] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const AddPermissionModule = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        setValidated(true);
        if (isValid) {
            setLoading(true);
            try {
                const response = await api.post(`/permissionModule`, {
                    name: permissionModule,
                });
                if (response.data.status === false) {
                    navigate('/403')
                }
                navigate('/permission-module');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            } catch (error) {
                showToast(error.response.data.errors, 'error');
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add Permission Module</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>

                                <Form
                                    id="form-wizard1"
                                    onSubmit={(e) => AddPermissionModule(e)}
                                    noValidate
                                    validated={validated}
                                >
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Permission Name :-</Form.Label>
                                        <Form.Control
                                            type="name"
                                            id="permissionModule "
                                            onChange={(e) => setpermissionModule(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        variant="btn btn-primary me-2"
                                        disabled={loading}
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="btn btn-danger"
                                        onClick={() => navigate('/permission-module')}
                                    >
                                        Cancel
                                    </Button>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AddPermissionModule;
