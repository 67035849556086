import FsLightbox from 'fslightbox-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Image, Nav, Row, Tab } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/employeeprofile.css';
import avatars11 from '../../assets/images/avatars/01.png';
import avatars22 from '../../assets/images/avatars/avtar_1.png';
import avatars33 from '../../assets/images/avatars/avtar_2.png';
import avatars44 from '../../assets/images/avatars/avtar_3.png';
import avatars55 from '../../assets/images/avatars/avtar_4.png';
import avatars66 from '../../assets/images/avatars/avtar_5.png';
import icon1 from '../../assets/images/icons/01.png';
import icon2 from '../../assets/images/icons/02.png';
import icon4 from '../../assets/images/icons/04.png';
import icon5 from '../../assets/images/icons/05.png';
import icon8 from '../../assets/images/icons/08.png';
import shap2 from '../../assets/images/shapes/02.png';
import shap4 from '../../assets/images/shapes/04.png';
import shap6 from '../../assets/images/shapes/06.png';
import Card from '../../components/Card';
import { showToast } from '../../plugins/toastUtils';

const EmployeeProfile = ({ api, imagePath }) => {

   const { id } = useParams();
   const navigate = useNavigate();
   const [employeedata, setemployeedata] = useState([]);
   const [oldDoj, setoldDoj] = useState(employeedata.doj);

   useEffect(() => {
      api.get(`/profile/${id}`)
         .then((response) => {
            if (response.data.userData[0] !== undefined) {
               if (response.data.status === false) {
                  navigate('/403')
               }
               setemployeedata(response.data.userData[0]);
            }
         }).catch((error) => {
            showToast(error.response.data.errors, 'error');
         });
      return () => {
      };
   }, []);

   useEffect(() => {
      var dateObj = moment(employeedata.doj);
      setoldDoj(dateObj.format("DD-MM-YYYY"));
   }, [employeedata])

   let oldDob = moment(new Date(employeedata.dob)).format('DD-MM-YYYY').split('T')[0];

   return (
      <>
         <FsLightbox
            sources={[icon4, shap2, icon8, shap4, icon2, shap6, icon5, shap4, icon1]}
         />
         <div className="profile-data">
            <Tab.Container defaultActiveKey="first">
               <Row className='edit-profile'>
                  <Form className='main-form'>
                     <Col lg="12" md="12">
                        <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">

                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                       {employeedata.photo ?
                                          <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={imagePath + employeedata.photo}
                                             alt="profile-pic" /> :
                                          <>
                                             <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11}
                                                alt="profile-pic" />
                                             <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100" src={avatars22}
                                                alt="profile-pic" />
                                             <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100" src={avatars33}
                                                alt="profile-pic" />
                                             <Image className="theme-color-green-img img-fluid rounded-pill avatar-100" src={avatars55}
                                                alt="profile-pic" />
                                             <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100" src={avatars66}
                                                alt="profile-pic" />
                                             <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100" src={avatars44}
                                                alt="profile-pic" />
                                          </>
                                       }
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                       <h4 className="me-2 h4">{employeedata.firstname}</h4>
                                       <span> - {employeedata.roleData && employeedata.roleData[0].role_name}</span>
                                    </div>
                                 </div>
                                 <Nav as="ul" className="d-flex nav-pills mb-0 bg-transparent text-center profile-tab" data-toggle="slider-tab"
                                    id="profile-pills-tab" role="tablist">
                                    <Nav.Item as="li">
                                       <Link className='btn btn-danger active me-2' to={`/employee`}>Cancel</Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                       <Link className='btn btn-primary active' to={`/employee/edit/${employeedata._id}`}>Edit Profile</Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>

                     <Col lg="12" md="12">
                        <Card>
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Personal information</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              <Row>
                                 <div className='part-1 col-lg-6 col-md-6'>

                                    <div className="mt-2">
                                       <h6 className="mb-1">First name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.firstname ? (
                                             <Form.Control disabled type="text" className="form-control" name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.firstname} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Middle name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.middle_name ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.middle_name} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Last name:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.last_name ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.last_name} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Contact number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.mo_number ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.mo_number} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Date of birth:</h6>
                                       <div className="edit-data w-75">
                                          {oldDob ? (
                                             <Form.Control disabled type="text" className="form-control  position-relative"
                                                name="user_name" id='username' placeholder=""
                                                defaultValue={oldDob !== "Invalid date" ? oldDob : ''} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Gender:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.gender ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.gender} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Company email:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.company_email ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.company_email} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Joining date:</h6>
                                       <div className="edit-data w-75">
                                          {oldDoj ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                value={oldDoj} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Role:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.roleData ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.roleData && employeedata.roleData[0].role_name}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>
                                 </div>

                                 <div className="part-2 col-lg-6 col-md-6">

                                    <div className="mt-2">
                                       <h6 className="mb-1">Employee Code:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.emp_code ? (
                                             <Form.Control disabled type="text" className="form-control " name="user_name" id='username'
                                                placeholder=""
                                                defaultValue={employeedata.emp_code} />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Pancard number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.pan_number ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.pan_number}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Aadhar number:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.aadhar_number ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.aadhar_number}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 1:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.add_1 ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.add_1}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Address line 2:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.add_2 ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.add_2}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">City:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.city ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.city}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">State:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.state && employeedata.state !== "undefined" ? (
                                             < Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.state}
                                             />
                                          ) : (<span className="form-control">N/A</span>)
                                          }
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Pincode:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.pincode ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.pincode}
                                             />
                                          ) : (
                                             <span className="form-control ">N/A</span>
                                          )}
                                       </div>
                                    </div>

                                    <div className="mt-2">
                                       <h6 className="mb-1">Country:</h6>
                                       <div className="edit-data w-75">
                                          {employeedata.country ? (
                                             <Form.Control
                                                disabled
                                                type="text"
                                                className="form-control"
                                                name="user_name"
                                                id='username'
                                                placeholder=""
                                                defaultValue={employeedata.country}
                                             />
                                          ) : (
                                             <span className="form-control "> N/A</span>
                                          )}
                                       </div>
                                    </div>

                                 </div>
                              </Row>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Row>
                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Banking Detail</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-2'>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Bank name:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.bank_name ? (
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username' placeholder=""
                                             defaultValue={employeedata.bank_name} />
                                       ) : (
                                          <span className="form-control ">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Ifsc Code:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.ifsc_code ? (
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username' placeholder=""
                                             defaultValue={employeedata.ifsc_code} />
                                       ) : (
                                          <span className="form-control ">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Account number:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.bank_account_no ? (
                                          <Form.Control disabled type="text" className="form-control " name="user_name" id='username' placeholder=""
                                             defaultValue={employeedata.bank_account_no} />
                                       ) : (
                                          <span className="form-control ">N/A</span>
                                       )}
                                    </div>
                                 </div>
                              </Card.Body>
                           </Card>
                        </Col>
                        <Col lg="6" md="6">
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">Login Information</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body className='box-3'>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Username:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.user_name ? (
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id='username'
                                             placeholder=""
                                             defaultValue={employeedata.user_name}
                                          />
                                       ) : (
                                          <span className="form-control ">N/A</span>
                                       )}
                                    </div>
                                 </div>

                                 <div className="mt-2">
                                    <h6 className="mb-1">Email address:</h6>
                                    <div className="edit-data w-75">
                                       {employeedata.personal_email ? (
                                          <Form.Control
                                             disabled
                                             type="text"
                                             className="form-control"
                                             name="user_name"
                                             id='username'
                                             placeholder=""
                                             defaultValue={employeedata.personal_email}
                                          />
                                       ) : (
                                          <span className="form-control ">N/A</span>
                                       )}
                                    </div>
                                 </div>

                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </Form>
               </Row>
            </Tab.Container>
         </div>
      </>
   )

}

export default EmployeeProfile;