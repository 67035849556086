import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'

const AnnouncementsList = ({ api }) => {

    const [announcement, setAnnouncement] = useState([]);
    const [deleteid, setDeleteid] = useState('');
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState('ASC');
    const [pageNumber] = useState(0);
    const [entry] = useState(10);
    const [dataperPage, setDataperPage] = useState(entry);
    const [searchData, setSearchData] = useState([]);
    const [searchResult, setSearchResult] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const Permissions = useContext(PermissionData);
    const navigate = useNavigate();

    const Announcements = () => {
        setLoading(true);
        api.get(`/announcementListing/`).then((response) => {
            if (response.data.status === false) {
                navigate('/403');
            }
            else {
                setAnnouncement(response.data.AnnouncementData);
            }
            setLoading(false);
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const Handledelete = async () => {
        const id = deleteid;
        setLoading(true);
        if (id !== undefined) {
            await api.post(`/deleteAnnouncement/${id}`, '')
                .then(() => {
                    setShow(false);
                    const updatedAnnouncements = announcement.filter(
                        (announcement) => announcement._id !== id
                    );
                    setAnnouncement(updatedAnnouncements);

                }).catch((error) => {
                    console.log("error", error)
                });
        }
        setLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    function deletemodel(id) {
        setDeleteid(id);
        setShow(true);
    }

    const perpagedata = (e) => {
        setDataperPage(e.target.value);
    }

    const handleSearch = async (event) => {
        if (searchValue && event.key === 'Enter') {
            setSearchResult(true)
            await api.post(`/announcementListing/${searchValue}`, '').then((response) => {
                if (response.data !== undefined) {
                    if (response.data.status === false) {
                        setSearchResult(false)
                    }
                    else {
                        setSearchResult(true)
                        setSearchData(response.data.searchData);
                    }
                    if (response.data.status === false) {
                        navigate('/403')
                    }
                }
            }).catch((error) => {
                console.log("error", error)
                setSearchData([]);
                setSearchResult(true);
            });
        } else {
            setSearchResult(true);
            Announcements();
        }
    }

    useEffect(() => {
        if (!searchValue) {
            setSearchData([]);
            setSearchResult(true);
        }
        return () => {
        };
    }, [searchValue, searchResult]);

    const sorting = (col) => {
        if (order === 'ASC') {
            const sorted = [...announcement].sort((a, b) => {
                const aVal = getValueToCompare(a, col);
                const bVal = getValueToCompare(b, col);
                const result = aVal.toLowerCase() > bVal.toLowerCase() ? 1 : -1;
                if (result === 0) {
                    const nextCol = col + 1;
                    if (nextCol < a.userData.length) {
                        return sorting(nextCol);
                    }
                }
                return result;
            });
            setAnnouncement(sorted);
            setOrder('DSC')
        }
        if (order === 'DSC') {
            const sorted = [...announcement].sort((a, b) => {
                const aVal = getValueToCompare(a, col);
                const bVal = getValueToCompare(b, col);
                const result = aVal.toLowerCase() < bVal.toLowerCase() ? 1 : -1;
                if (result === 0) {
                    const nextCol = col + 1;
                    if (nextCol < a.userData.length) {
                        return sorting(nextCol);
                    }
                }
                return result;
            });
            setAnnouncement(sorted);
            setOrder('ASC')
        }
    };

    const getValueToCompare = (item, col) => {
        if (col === 0) {
            return item.userData[0].firstname;
        } else if (col === 2) {
            return item.date;
        } else if (col === 3) {
            return item.description;
        } else if (col === 4) {
            return item.title;
        } else {
            return '';
        }
    };

    const pageVisited = pageNumber * dataperPage;

    useEffect(() => {
        Announcements();
        return () => {
        };
    }, []);

    return (
        <>
            <div>
                <Row>
                    <Modal show={show} onHide={handleClose} className='text-center'>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button variant="primary" className='me-2' disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                            <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>
                        </Modal.Body>
                    </Modal>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Announcements</h4>
                                </div>
                            </Card.Header>
                            <Card.Header className={`d-flex justify-content-between`}>
                                <div className="row align-items-center w-100">
                                    <div className="col-md-6">
                                        <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                            <label>Show</label>
                                            <select
                                                name="user-list-table_length" aria-controls="user-list-table"
                                                className="form-select form-select-sm w-25" onChange={(e) => perpagedata(e)}
                                                defaultValue={entry}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%' }}>
                                            <label>Search:</label>
                                            <input type="search" className="form-control form-control-sm w-50" placeholder=""
                                                aria-controls="user-list-table" onChange={(event) => setSearchValue(event.target.value)}
                                                onKeyDown={handleSearch} />
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                        <thead>
                                            <tr className="ligth">
                                                <th>No</th>
                                                <th>
                                                    <span className="title">Announced By</span>
                                                </th>
                                                <th>
                                                    <span className="title">Title</span>
                                                </th>
                                                <th>
                                                    <span className="title">Date</span>
                                                </th>
                                                <th>
                                                    <span className="title">Description</span>
                                                </th>
                                                {(Permissions.includes('Update Holiday') || Permissions.includes('Delete Holiday')) &&
                                                    <th min-width="100px">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={20} className='text-center'>
                                                        <Spinner animation="border" variant="primary" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className='text-capitalize'>
                                                {searchResult === false ?
                                                    <tr>
                                                        <td colSpan='20' className='text-center'>No Search results found</td>
                                                    </tr>
                                                    :
                                                    searchData && searchData.length > 0 ? searchData.slice(pageVisited, +pageVisited + +dataperPage).map((item, index) => (
                                                        <tr key={index} className="border-light">
                                                            <td>{pageVisited + index + 1}</td>
                                                            <td>{item.userData && item.userData[0].firstname}</td>
                                                            <td>{item.title}</td>
                                                            <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                                                            <td>{item.description}</td>
                                                            {(Permissions.includes('Update Holiday') || Permissions.includes('Delete Holiday')) && (
                                                                <td>
                                                                    <div className="flex align-items-center list-user-action">
                                                                        {Permissions.includes('Delete Holiday') && (
                                                                            <Link
                                                                                onClick={() => deletemodel(item._id)}
                                                                                className="btn btn-sm btn-icon btn-danger"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title=""
                                                                                data-original-title="Delete"
                                                                                to="#"
                                                                            >
                                                                                <span className="btn-inner">
                                                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )) :
                                                        announcement.length > 0 ? announcement.slice(pageVisited, +pageVisited + +dataperPage).map((item, index) => (
                                                            <tr key={index} className="border-light">
                                                                <td>{pageVisited + index + 1}</td>
                                                                <td>{item.userData && item.userData[0].firstname}</td>
                                                                <td>{item.title}</td>
                                                                <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                                                                <td>{item.description}</td>
                                                                {(Permissions.includes('Update Holiday') || Permissions.includes('Delete Holiday')) && (
                                                                    <td>
                                                                        <div className="flex align-items-center list-user-action">
                                                                            {Permissions.includes('Delete Holiday') && (
                                                                                <Link
                                                                                    onClick={() => deletemodel(item._id)}
                                                                                    className="btn btn-sm btn-icon btn-danger"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title=""
                                                                                    data-original-title="Delete"
                                                                                    to="#"
                                                                                >
                                                                                    <span className="btn-inner">
                                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                            <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </Link>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )) :
                                                            <tr>
                                                                <td colSpan={9} className='text-center'>No data Found</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col >
                </Row >
            </div >
        </>
    )
}

export default AnnouncementsList
