import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import { useDispatch, useSelector } from "react-redux";
import { fetchHolidayList } from "../../store/slices/holidaysSlice";

const HolidayList = ({ api, showToast }) => {
  const dispatch = useDispatch();
  const Permissions = useContext(PermissionData);
  const navigate = useNavigate();
  const [holiday, setHoliday] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [dataperPage, setDataperPage] = useState(10);
  const [totalData, setTotalData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  let currentYear = new Date().getFullYear();
  let lastHolidayData = holiday.slice(-1);
  const holidays = useSelector((state) => state.holidays.holidays);

  const HolidayData = async () => {
    setLoading(true);
    await api
      .get(
        `/holidayListing?search=${searchValue}&page=${page}&limit=${dataperPage}&year=${year}&nameSort=${order}`
      )
      .then((response) => {
        if (response.data !== undefined) {
          if (response.data.status === false) {
            navigate("/403");
          } else {
            setHoliday(response.data.holidayData);
            setTotalPages(response.data.totalPages);
            setTotalData(response.data.totalData);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 403) {
          navigate("/403");
        }
        console.log("error", error);
      });
  };

  const Handledelete = async () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      await api
        .post(`/deleteHoliday/${id}`, "")
        .then((response) => {
          setShow(false);
          // HolidayData()
          showToast(response.data.message, "success", "success");
        })
        .catch((error) => {
          showToast(error.response.data.errors, "error");
        });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      // HolidayData()
    }
  };

  const HandleYear = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (!searchValue && search) {
      HolidayData();
    }
    return () => { };
  }, [searchValue]);

  useEffect(() => {
    HolidayData();
  }, [page, dataperPage, year, order]);

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="primary"
                className="me-2"
                disabled={loading}
                onClick={() => Handledelete()}
              >
                {loading ? "Deleting..." : "Delete"}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Holiday</h4>
                </div>
                {Permissions.includes("Add Holiday") && (
                  <div className="Add-holiday">
                    <Link to="add">
                      {" "}
                      <h4 className="btn btn-primary">
                        <i
                          className="fa-solid fa-plus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        New Holiday
                      </h4>
                    </Link>
                  </div>
                )}
              </Card.Header>
              <Card.Header className={`d-flex justify-content-between`}>
                <div className="row align-items-center w-100">
                  <div className="col-md-2">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Show</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        onChange={(e) => {
                          setPerPage(e.target.value);
                        }}
                        value={dataperPage}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Year</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        onChange={(e) => HandleYear(e)}
                        defaultValue={currentYear}
                      >
                        <option value={`${moment().year() - 2}`}>
                          {moment().year() - 2}
                        </option>
                        <option value={`${moment().year() - 1}`}>
                          {moment().year() - 1}
                        </option>
                        <option value={`${moment().year()}`}>
                          {moment().year()}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      id="user-list-table_filter"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <label>Search: </label>
                      <input
                        type="search"
                        className="form-control form-control-sm w-25"
                        placeholder=""
                        aria-controls="user-list-table"
                        onChange={(event) => {
                          setSearchValue(event.target.value);
                        }}
                        onKeyDown={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>No</th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("holiday_name")}
                        >
                          <div className=" d-flex ">
                            <span className="title">Holiday</span>
                          </div>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("holiday_date")}
                        >
                          <div className=" d-flex ">
                            <span className="title">Date</span>
                          </div>
                        </th>
                        <th>
                          <div className=" d-flex ">
                            <span className="title">Day</span>
                          </div>
                        </th>
                        {(Permissions.includes("Update Holiday") ||
                          Permissions.includes("Delete Holiday")) && (
                            <th min-width="100px">Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={20} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      ) : holiday?.length > 0 ? (
                        holiday.map((item, index) => (
                          <tr key={index} className="border-light">
                            <td>{item.index}</td>
                            <td>{item.holiday_name}</td>
                            <td>
                              {moment(item.holiday_date).format("DD/MM/YYYY")}
                            </td>
                            <td>{moment(item.holiday_date).format("dddd")}</td>
                            {(Permissions.includes("Update Holiday") ||
                              Permissions.includes("Delete Holiday")) && (
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {Permissions.includes("Update Holiday") && (
                                      <Link
                                        className="btn btn-sm btn-icon btn-warning"
                                        style={{ marginRight: "5px" }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Edit"
                                        to={`edit/${item._id}`}
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M15.1655 4.60254L19.7315 9.16854"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}
                                    {Permissions.includes("Delete Holiday") && (
                                      <Link
                                        onClick={() => deletemodel(item._id)}
                                        className="btn btn-sm btn-icon btn-danger"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        to="#"
                                      >
                                        <span className="btn-inner">
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="currentColor"
                                          >
                                            <path
                                              d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M20.708 6.23975H3.75"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row align-items-center w-100 mt-2">
                    <div className="col-md-6">
                      <div
                        className="dataTables_length d-flex align-items-center mx-3"
                        id="user-list-table_length"
                      >
                        {holiday?.length > 0 && (
                          <label>
                            Showing {holiday[0]?.index} to{" "}
                            {lastHolidayData[0]?.index} of {totalData} entries
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={PageChange}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination float-end"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HolidayList;
