import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';

function AddSetting({ api }) {

    const navigate = useNavigate();
    const Cancel = () => {
        navigate('/setting');
    }
    const [key, setKey] = useState('');
    const [type, setType] = useState("text");
    const [value, setValue] = useState("");
    const [validated, setValidated] = useState(false);
    const [existKey, setExistkey] = useState(false);
    const [loading, setLoading] = useState(false);

    const Addsetting = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        const formData = new FormData();
        formData.set('key', key);
        formData.set('type', type);
        formData.set('value', value);
        if (isValid) {
            setLoading(true);
            api.post(`/addsetting`, formData).then((response) => {
                setLoading(false);
                if (response.data.existKeyStatus === true) {
                    setExistkey(true)
                } else {

                    navigate('/setting');
                }
            }).catch((error) => {
                console.log("error", error)
                setLoading(false);
            })
        }
        else {
            setLoading(false);
            setValidated(true)
        }
    }

    return (
        <div>
            <Row>
                <Col sm="12" lg="6">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Add Setting</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form id="form-wizard1" onSubmit={(e) => Addsetting(e)} noValidate validated={validated}>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="value">Key</Form.Label>
                                    <Form.Control className="" type='text' id="value" onChange={e => setKey(e.target.value)} required
                                        style={existKey ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        This field is required
                                    </Form.Control.Feedback>
                                    {existKey &&
                                        <Form.Control.Feedback className='d-block text-danger'>
                                            This Key is already exist
                                        </Form.Control.Feedback>}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="role">Type</Form.Label>
                                    <Form.Control className="" as="select" name="city" id='city' onChange={e => setType(e.target.value)} required>
                                        <option value="text">Text</option>
                                        <option value="image">Image</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        This field is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="value">Value</Form.Label>
                                    <Form.Control className="" type={`${type === 'image' ? 'file' : 'text'}`} id="value" onChange={e => setValue(type === 'text' ? e.target.value : e.target.files[0])} required />
                                    <Form.Control.Feedback type="invalid">
                                        This field is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" disabled={loading} type='submit'>{loading ? 'Submiting...' : 'Submit'}</Button>{' '}
                                <Button variant="danger" type='reset' onClick={() => Cancel()}>Cancel</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default AddSetting
