import React from 'react'
import { Row, Col, Image, Form, Button, ListGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../components/Card'
import facebook from '../../assets/images/brands/fb.svg'
import google from '../../assets/images/brands/gm.svg'
import instagram from '../../assets/images/brands/im.svg'
import linkedin from '../../assets/images/brands/li.svg'
import auth5 from '../../assets/images/auth/05.png'
import auth7 from '../../assets/images/auth/07.png'
import { useState } from 'react'
import { useEffect } from 'react'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'
import api from '../../utils/api'

const ForgotPassword = () => {
   let navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [emailerror, setemailerror] = useState("");
   const [validated, setValidated] = useState(false);
   const [loading, setLoading] = useState(false);

   const Forgot = async (e) => {
      e.preventDefault();
      if (email) {
         setLoading(true);
         api.post(`/forget`, {
            company_email: email
         }).then((response) => {
            setLoading(false);
            // if (response.data.status === 1) {
            //    localStorage.setItem('hasChangeEmail', true)
            //    navigate(`/confirm-mail/${email}`);
            // }
            // if (response.data.status === 0) {
            //    setemailerror(1);
            // }
            // navigate(`/confirm-mail/${email}`);
            // setTimeout(() => {
            showToast(response.data.message, "success")
            // }, 300);
         }).catch((error) => {
            showToast(error.response.data.message, 'error');
            setLoading(false);
         });
      } else {
         setValidated(true);
         setLoading(false);
      }
   }

   useEffect(() => {
      return () => {
      };
   }, [email]);

   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </div>
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                           <Card.Body>
                              <Link className="navbar-brand d-flex align-items-center mb-3">
                                 <img src={auth7} alt="" height={50} width={50} />
                                 <h4 className="logo-title ms-3">Forgot Password</h4>
                              </Link>

                              <Form id="form-wizard1" onSubmit={e => Forgot(e)} noValidate validated={validated}>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group" style={{ position: 'relative', zIndex: '99' }}>
                                          <Form.Label htmlFor="full-name" className="">Enter Your Email</Form.Label>
                                          <Form.Control type="text" className={`form-control bg-transparent ${emailerror === 1 ? 'is-invalid' : ''}`} id="full-name" placeholder="provide company-email" onChange={(e) => setEmail(e.target.value)} required />
                                          <Form.Control.Feedback type="invalid">
                                             This field is required
                                          </Form.Control.Feedback>
                                       </Form.Group>
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <Button type="submit" variant="primary">Submit</Button>
                                 </div>
                                 <p className="text-center my-3">Connect With Us</p>
                                 <div className="d-flex justify-content-center">
                                    <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <a target='_blank' href="https://www.facebook.com/profile.php?id=100089058859571&mibextid=LQQJ4d"><Image src={facebook} alt="fb" /></a>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <a target='_blank' href="https://codecrewinfotech.com/"><Image src={google} alt="gm" /></a>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <a target='_blank' href="https://www.instagram.com/codecrew_infotech/"><Image src={instagram} alt="im" /></a>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <a target='_blank' href="https://in.linkedin.com/company/codecrew-infotech-pvt-ltd"><Image src={linkedin} alt="li" /></a>
                                       </ListGroup.Item>
                                    </ListGroup>
                                 </div>
                                 <p className="mt-3 text-center">
                                    Already have an Account <Link to="/login" disabled={loading} className="text-underline">{loading ? 'Loading...' : 'Sign In'}</Link>
                                 </p>
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </Col>
               <ToastContainerWrapper />
            </Row>
         </section>
      </>
   )
}

export default ForgotPassword
