import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";


export const initialState = {
    message: "",
    user: [],
    loading: true,
    login: false,
    LoginDate: null
}

export const login = createAsyncThunk('login', async ({ company_email, password }) => {
    try {
        const data = await api.post(`/`, {
            company_email: company_email,
            password: password
        })
        localStorage.setItem('user', JSON.stringify(data.data.userData[0]));
        return data;
    } catch (error) {
        return error.response
    }
})

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        token: (state, action) => {
            state.token = token;
        },
        user: (state, action) => {
            state.user = JSON.parse(user);
        },
        logout: (state, action) => {
            state.LoginDate = new Date().getDate();
            state.user = [];
            state.message = "";
            localStorage.removeItem('user');
        }
    },

    extraReducers: {
        [login.pending]: (state, action) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, { user, login, message }) => {
            state.loading = false;
            if (localStorage.getItem('user')) {
                user = localStorage.getItem('user', user);
                message = localStorage.getItem('message', message);
                state.message = message;
                state.user = JSON.parse(user);
            }
        },
        [login.rejected]: (state, action) => {
            state.loading = true;
        },
    }
})

export const { token, user, logout, } = authSlice.actions;
export default authSlice.reducer
