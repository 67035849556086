import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card";
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditMasterItemInventory({ api }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [validated, setValidated] = useState(false);
  const [existKey, setExistkey] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleEditModel = async () => {
    await api.get(`/editInventoryMaster/${id}`)
      .then((response) => {
        setKey(response.data.masterInventoryData.key);
        setValue(response.data.masterInventoryData.value);
        setDescription(response.data.masterInventoryData.description);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    HandleEditModel();
    return () => { };
  }, []);

  const UpdateMasterInventory = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isValid = form.checkValidity();
    const formData = new FormData();
    formData.set("key", key);
    formData.set("value", value);
    formData.set("description", description);
    if (isValid) {
      setLoading(true);
      api.post(`/editInventoryMaster/${id}`, formData)
        .then((response) => {
          if (response.data.existKeyStatus === true) {
            setExistkey(true);
          } else {
            navigate("/Master-Item-List");
          }
          setTimeout(() => {
            showToast(response.data.message, "success")
          }, 300);
        })
        .catch((error) => {
          showToast(error.response.data.errors,'error');
          setLoading(false);
        });
    } else {
      setValidated(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col sm="12" lg="6">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Edit new inventories</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <Form
                  id="form-wizard1"
                  onSubmit={(e) => UpdateMasterInventory(e)}
                  noValidate
                  validated={validated}
                >
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="value">Key</Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      id="value"
                      onChange={(e) => setKey(e.target.value)}
                      value={key}
                      required
                      style={
                        existKey
                          ? { backgroundImage: "none", borderColor: "#c03221" }
                          : {}
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                    {existKey && (
                      <Form.Control.Feedback className="d-block text-danger">
                        This Key is already exist
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="value">Value</Form.Label>
                      <>
                        <Form.Control
                          className=""
                          value={value}
                          type="text"
                          id="value"
                          onChange={(e) => setValue(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="value">description</Form.Label>
                      <>
                        <Form.Control
                          className=""
                          value={description}
                          type="text"
                          id="description"
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </>
                    </Form.Group>
                  </Col>
                  <Button className="me-2" variant="primary" disabled={loading} type="submit">
                    {loading ? "Updating..." : "Update inventories"}
                  </Button>
                  <Button
                    variant="danger"
                    type="reset"
                    onClick={() => navigate("/Master-Item-List")}
                  >
                    Cancel
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div >
    </>
  );
}

export default EditMasterItemInventory;
