import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import '../../assets/scss/cc-custom/Add.css'
import Card from '../../components/Card'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'

const AddEmployee2 = ({ api }) => {
    const [show, AccountShow] = useState('Login');
    const [pan_numberError, setPan_numberError] = useState(false)
    const [role_id, setRole_id] = useState("");
    const [emp_code, setEmp_code] = useState("");
    const [reporting_user_id, setReporting_user_id] = useState("");
    const [firstname, setFirstname] = useState("");
    const [user_name, setUser_name] = useState("");
    const [middle_name, setMiddle_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [doj, setDoj] = useState("");
    const [personal_email, setPersonal_email] = useState("");
    const [company_email, setCompany_email] = useState("");
    const [mo_number, setMo_number] = useState("");
    const [pan_number, setPan_number] = useState("");
    const [aadhar_number, setAadhar_number] = useState("");
    const [add_1, setAdd_1] = useState("");
    const [add_2, setAdd_2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [country, setCountry] = useState("");
    const [photo, setphoto] = useState("");
    const [bank_account_no, setBank_account_no] = useState("");
    const [bank_name, setBank_name] = useState("");
    const [ifsc_code, setIfsc_code] = useState("");
    const [status, setStatus] = useState("");
    const [validated, setValidated] = useState(false);
    const [existusername, setExistusername] = useState(false);
    const [existemail, setExistmail] = useState(false);
    const [roles, setRoles] = useState([]);
    const [reportinguser, setReportinguser] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const regexEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    let yyyy;
    let today = new Date();
    yyyy = today.getFullYear() - 18;

    const HandleExistUsername = (username) => {
        setUser_name(username);
        if (username) {
            api.post(`/existusername`, { user_name: username }).then((response) => {
                if (response.data.status === true) {
                    setExistusername(true)
                } else {
                    setExistusername(false)
                }
            }).catch((error) => {
                console.log("error", error)
            })
        }
    }

    const HandleExistEmail = (company_email) => {
        setCompany_email(company_email);
        if (company_email) {
            api.post(`/existemail`, { company_email: company_email }).then((response) => {
                if (response.data.status === true) {
                    setExistmail(true)
                } else {
                    setExistmail(false)
                }
            }).catch((error) => {
                console.log("error", error)
            })
        } else {
            setExistmail(false);
        }
    }

    const HandleLogin = async () => {
        if (user_name && company_email && !existemail && !existusername) {
            try {
                const response = await api.post(`/existusername`, { user_name });
                if (response.data.status === true) {
                    setValidated(true);
                    setExistmail(true);
                    AccountShow('Login');
                } else {
                    const emailResponse = await api.post(`/existemail`, { personal_email });
                    if (emailResponse.data.status === true) {
                        setValidated(true);
                        setExistmail(true);
                        AccountShow('Login');
                    } else {
                        setValidated(false);
                        AccountShow('Personal')
                    }
                }
            } catch (error) {
                console.log("error", error)
            }
        } else {
            setValidated(true)
        }
    }

    const HandlePersonal = async () => {
        if (firstname && last_name && last_name && mo_number && dob && personal_email && doj && add_1 && pincode && reporting_user_id) {
            setValidated(false);
            AccountShow('Banking')
        } else {
            setValidated(true)
        }
    }

    const HandleBanking = async () => {
        if (bank_account_no && ifsc_code && bank_name) {
            setValidated(false);
            AddEmployee();
        } else {
            setValidated(true)
        }
    }

    const AddEmployee = async () => {
        const formData = new FormData();
        formData.set('photo', photo);
        formData.set('role_id', role_id);
        formData.set('emp_code', emp_code);
        formData.set('reporting_user_id', reporting_user_id);
        formData.set('firstname', firstname);
        formData.set('user_name', user_name);
        formData.set('middle_name', middle_name);
        formData.set('last_name', last_name);
        formData.set('gender', gender);
        formData.set('dob', dob);
        formData.set('doj', doj);
        formData.set('personal_email', personal_email);
        formData.set('company_email', company_email);
        formData.set('status', status);
        formData.set('mo_number', mo_number);
        formData.set('pan_number', pan_number);
        formData.set('aadhar_number', aadhar_number);
        formData.set('add_1', add_1);
        formData.set('add_2', add_2);
        formData.set('city', city);
        formData.set('state', state);
        formData.set('pincode', pincode);
        formData.set('country', country);
        formData.set('bank_account_no', bank_account_no);
        formData.set('bank_name', bank_name);
        formData.set('ifsc_code', ifsc_code);

        try {
            setLoading(true);
            await api.post(`/addUser`, formData, {
                headers: {
                    "Content-Type": "multipart/mixed",
                }
            }).then((response) => {
                setLoading(false);
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
                navigate('/employee');
            });
        } catch (error) {
            if(error.response.status == 403){
                navigate('/403')
             }
            setLoading(false);
            showToast(error.response.data.errors,'error');
        }
    }

    const handlePanChange = (e) => {
        const panNumber = e.target.value;
        const panPattern = /^[A-Z]{5}\d{4}[A-Z]$/;
        const isValid = panPattern.test(panNumber);
        setPan_number(panNumber);
        setPan_numberError(!isValid);
    };

    useEffect(() => {
        api.get(`/addUser`).then((response) => {
            setCityData(response.data.cityData);
            setReportinguser(response.data.userData);
            setRoles(response.data.roleData);
            setEmp_code(response?.data?.newEmpCode);

        }).catch((error) => {
             if(error.response.status == 403){
                navigate('/403')
             }
            console.log("error", error)
        });
    }, []);

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">New Employee</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form noValidate validated={validated}>
                                <ul id="top-tab-list" className="p-0 row list-inline">
                                    <li className={` ${show === 'Image' ? ' active done' : ''} ${show === 'Banking' ? ' active done' : ''} ${show === 'Personal' ? ' active done' : ''} ${show === 'Login' ? 'active' : ''} col-lg-4 col-md-6 text-start mb-2 active`} id="account">
                                        <Link to="#">
                                            <div className="iq-icon me-3">
                                                <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                                </svg>
                                            </div>
                                            <span>Login</span>
                                        </Link>
                                    </li>
                                    <li id="personal" className={`${show === 'Banking' ? ' active done' : ''} ${show === 'Image' ? ' active done' : ''} ${show === 'Personal' ? 'active ' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                        <Link to="#">
                                            <div className="iq-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                </svg>
                                            </div>
                                            <span>Personal</span>
                                        </Link>
                                    </li>
                                    <li id="payment" className={`${show === 'Image' ? ' active done' : ''} ${show === 'Banking' ? 'active' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                        <Link to="#">
                                            <div className="iq-icon me-3">
                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                </svg>
                                            </div>
                                            <span>Banking</span>
                                        </Link>
                                    </li>
                                </ul>
                                <fieldset className={`${show === 'Login' ? 'd-block' : 'd-none'}`}>
                                    <div className="form-card text-start">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3 className="mb-4">Login Information: </h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group has-validation">
                                                    <label className="form-label">Username :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control login-fields" name="user_name" id='username' placeholder="UserName" onChange={e => HandleExistUsername(e.target.value)} required
                                                        style={existusername ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    />
                                                    <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                                                    {existusername &&
                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                            This username already exist
                                                        </Form.Control.Feedback>
                                                    }
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group has-validation">
                                                    <label className="form-label">Company Email :<span className='text-danger'>*</span></label>
                                                    <Form.Control
                                                        type="email"
                                                        className="form-control login-fields"
                                                        name="personal_email"
                                                        id='personal_email'
                                                        placeholder="Email Id"
                                                        data-temp-mail-org="0"
                                                        onChange={(e) => {
                                                            HandleExistEmail(e.target.value);
                                                        }}
                                                        required
                                                        style={existemail ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                        isInvalid={!regexEmail.test(company_email) && company_email.trim() !== ''}
                                                    />
                                                    {company_email.trim() === '' ?
                                                        <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                                                        :
                                                        (!regexEmail.test(company_email) && company_email.trim() !== '') ?
                                                            (<Form.Control.Feedback type="invalid" className='d-block'>
                                                                Please enter a valid email
                                                            </Form.Control.Feedback>) :
                                                            existemail &&
                                                            <Form.Control.Feedback type="invalid" className='d-block'>
                                                                This email already exists
                                                            </Form.Control.Feedback>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        data-value="PersonalForm"
                                        name="next"
                                        className="btn btn-primary  action-button float-end"
                                        data-form-type="Login"
                                        onClick={() => HandleLogin()}>Next
                                    </Button>
                                    <button
                                        type="button"
                                        name="previous"
                                        className="btn btn-danger float-end me-1"
                                        onClick={() => navigate('/employee')} >Cancel
                                    </button>
                                </fieldset>
                                <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                    <div className="form-card text-start">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3 className="mb-4">Personal Information:</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">First Name :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" id="firstname" name="firstname" placeholder="First Name" onChange={e => setFirstname(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">Middle Name :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" name="middle_name" id="middle_name" placeholder="middle Name" onChange={e => setMiddle_name(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">Please enter middle name</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">Last Name :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" name="last_name" id="last_name" placeholder="Last Name" onChange={e => setLast_name(e.target.value)} required />
                                                    <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Contact No :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" name="mo_number" id="mo_number" placeholder="Contact No." onChange={e => setMo_number(e.target.value)} required maxLength={10} pattern="^[0-9]{10}$" />
                                                    <Form.Control.Feedback type="invalid">Please enter contact number</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Date Of Birth :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="date" className="form-control" name="dob" id="dob" placeholder="Alternate Contact No." onChange={e => setDob(e.target.value)} required max={`${yyyy}-01-01`} />
                                                    <Form.Control.Feedback type="invalid">Please enter date of birth</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="form-label">Gender :<span className='text-danger'>*</span></label>
                                                    <div className="gender-list d-flex">
                                                        <Form.Check className="d-block me-2">
                                                            <Form.Check.Input className="me-2" type="radio" name="gender" onChange={e => setGender('Male')} required />
                                                            <Form.Check.Label htmlFor="flexRadioDefault1">
                                                                Male
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="d-block">
                                                            <Form.Check.Input className="me-2" type="radio" name="gender" onChange={e => setGender('Female')} required />
                                                            <Form.Check.Label htmlFor="flexRadioDefault1">
                                                                Female
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">Profile Image :</label>
                                                <Form.Control type="file" className="form-control" name="photo" id="photo" aria-label="file example" onChange={e => setphoto(e.target.files[0])} />

                                            </div>

                                            <div className="col-md-6 mt-2">
                                                <div className="form-group has-validation">
                                                    <label className="form-label">Personal email :<span className='text-danger'>*</span></label>
                                                    <Form.Control
                                                        type="email"
                                                        className="form-control login-fields"
                                                        name="personal_email"
                                                        id='personal_email'
                                                        placeholder="Email Id"
                                                        data-temp-mail-org="0"
                                                        onChange={(e) => {
                                                            setPersonal_email(e.target.value);
                                                        }}
                                                        required
                                                        style={existemail ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                        isInvalid={!regexEmail.test(personal_email) && personal_email.trim() !== ''}
                                                    />
                                                    {personal_email.trim() === '' ?
                                                        <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                                                        :
                                                        (!regexEmail.test(personal_email) && personal_email.trim() !== '') ?
                                                            (<Form.Control.Feedback type="invalid" className='d-block'>
                                                                Please enter a valid email
                                                            </Form.Control.Feedback>) :
                                                            existemail &&
                                                            <Form.Control.Feedback type="invalid" className='d-block'>
                                                                This email already exists
                                                            </Form.Control.Feedback>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-2">
                                                <div className="form-group">
                                                    <label className="form-label">Date of joining :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="date" className="form-control" name="doj" id="doj" placeholder="example@examle.com" onChange={e => setDoj(e.target.value)} required max={new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0]} min="2018-01-01" />
                                                    <Form.Control.Feedback type="invalid">PLease enter Date of joining</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Reporting User :<span className='text-danger'>*</span></label>
                                                    <Form.Control as="select" className="form-select mb-3 shadow-none" name="reporting_user_id" id='reporting_user_id' onChange={e => setReporting_user_id(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" >Select Name</option>

                                                        {reportinguser?.length > 0 ?
                                                            reportinguser.map((data, id) => {
                                                                return <option key={id} value={data._id}>{data.firstname}</option>
                                                            }) :
                                                            (<option value="" disabled>no user found</option>)
                                                        }

                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">Please select Reporting user</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Role :<span className='text-danger'>*</span></label>
                                                    <select name="" id="" className='text-capitalize form-select mb-3 shadow-none form-control' onChange={e => setRole_id(e.target.value)} required>
                                                        <option value="">Select role</option>
                                                        {roles?.length > 0 ?
                                                            roles.map((data, id) => {
                                                                return <option key={id} value={data?._id}>{data?.role_name}</option>
                                                            }) :
                                                            (<option value="" disabled>no roles found</option>)
                                                        }
                                                    </select>
                                                    <Form.Control.Feedback type="invalid">Please Select Role name</Form.Control.Feedback>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Employee Code :</label>
                                                    <div className="input-group">

                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">CC</span>
                                                        </div>
                                                        <Form.Control type="text" className="form-control" name="emp_code" id="emp_code" placeholder="0123" required defaultValue={emp_code.slice(3)} disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Employee Status :</label>
                                                    <Form.Control as="select" className="form-select shadow-none" id='state' name="status" onChange={e => setStatus(e.target.value)}
                                                    >
                                                        <option value="" >Employee status</option>
                                                        <option value="Pending ">Pending</option>
                                                        <option value="Active ">Active </option>
                                                        <option value="InActive ">InActive</option>
                                                        <option value="ExEmployee">ExEmployee</option>
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <hr className='mt-2' />
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Pancard Number :<span className='text-danger'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="pan_number"
                                                        id="pan_number"
                                                        pattern="^[A-Z]{5}\d{4}[A-Z]$"
                                                        placeholder="ABCDE1234F"
                                                        onChange={handlePanChange}
                                                        value={pan_number}
                                                        required
                                                    />
                                                    <div className="err text-danger">
                                                        {pan_numberError && "Please enter pan number"}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Aadharcard number :</label>
                                                    <Form.Control type="text" className="form-control" name="aadhar_number" id="aadhar_number" data-type="aadhar_number" placeholder="enter your aadhar number here" onChange={e => setAadhar_number(e.target.value)} pattern="^[0-9]{12}$" />
                                                    <Form.Control.Feedback type="invalid">Please enter Aadharcard Number</Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="col-md-6">
                                                <label htmlFor="validationTextarea" className="form-label">Address 1 :<span className='text-danger'>*</span></label>
                                                <Form.Control as="textarea" className="form-control" id="add_1" name="add_1" placeholder="add 1 textarea" onChange={e => setAdd_1(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">PLease Enter Address</Form.Control.Feedback>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="validationTextarea" className="form-label">Address 2 :</label>
                                                <Form.Control as="textarea" className="form-control" id="add_2" name="add_2" placeholder="add 2 textarea" onChange={e => setAdd_2(e.target.value)} />
                                            </div>

                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="form-label">City :</label>
                                                    <Form.Control as="select" className="form-select mb-3 shadow-none" name="city" id='city' onChange={e => setCity(e.target.value)} defaultValue={'DEFAULT'}>
                                                        <option value="DEFAULT">Select city</option>
                                                        {cityData?.length > 0 ?
                                                            cityData.map((data, id) => {
                                                                return <option key={id} value={data.city}>{data.city}</option>
                                                            }) :
                                                            (<option value="" disabled>no city found</option>)
                                                        }
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="form-label">State :</label>
                                                    <Form.Control as="select" className="form-select mb-3 shadow-none" id='state' name="state" onChange={e => setState(e.target.value)} defaultValue={'DEFAULT'} >
                                                        <option >Select</option>
                                                        <option value="Gujarat">Gujarat</option>
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="form-label">Country :</label>
                                                    <Form.Control as="select" className="form-select mb-3 shadow-none" id="country" name="country" onChange={e => setCountry(e.target.value)} defaultValue={'DEFAULT'} >
                                                        <option >Select</option>
                                                        <option value="Gujarat">India</option>
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">Pincode :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" id="pincode" name="pincode" placeholder="pin" onChange={e => setPincode(e.target.value)} required pattern="^[0-9]{6}$" setlimit={6} />
                                                    <Form.Control.Feedback type="invalid">Please enter valid pincode</Form.Control.Feedback>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <Button type="button" name="next" className="btn btn-primary  action-button float-end" onClick={() => HandlePersonal()}>Next</Button>
                                    <Button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('Login')}>Previous</Button>
                                </fieldset>
                                <fieldset className={`${show === 'Banking' ? 'd-block' : 'd-none'}`}>
                                    <div className="form-card text-start">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3 className="mb-4">Banking</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Ifsc Code :<span className='text-danger'>*</span></label>
                                                <Form.Control type="text" className="form-control" name="ifsc_code" id="ifsc_code" placeholder="example@examle.com" onChange={e => setIfsc_code(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">Please enter IFSD code</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Bank Name :<span className='text-danger'>*</span></label>
                                                <Form.Control type="text" className="form-control" name="bank_name" id="bank_name" placeholder="Bank Name" defaultValue={bank_name ? bank_name : ""} onChange={e => setBank_name(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">PLease enter Bank Name</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Bank Account Number :<span className='text-danger'>*</span></label>
                                                <Form.Control type="text" className="form-control" id="bank_account_no" name="bank_account_no" placeholder="0123...upto 17" onChange={e => setBank_account_no(e.target.value)} required />
                                                <Form.Control.Feedback type="invalid">Please enter bank account number</Form.Control.Feedback>
                                            </div>
                                        </div>
                                    </div>
                                    <Button type="button" className="btn btn-primary action-button float-end" disabled={loading} onClick={() => HandleBanking()}>{loading ? 'Submiting...' : 'Submit'}</Button>
                                    <Button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('Personal')} >Previous</Button>
                                </fieldset>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <ToastContainerWrapper />
            </Row >
        </>
    )
}

export default AddEmployee2
