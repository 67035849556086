import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditEntry({ api }) {

    const { id } = useParams();
    const [hours, setHours] = useState("");
    const [date, setDate] = useState("");
    const [project, setProject] = useState("");
    const [task, setTask] = useState("");
    const [projectlist, setProjectlist] = useState([]);
    const [prevProject, setPrevProject] = useState("");
    const [tasklist, setTasklist] = useState([]);
    const [validated, setValidated] = useState(false);
    const [isSunday, setIsSunday] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [isholiday, setIsHoliday] = useState(false);
    const [holiday, setHoliday] = useState([]);
    const [isSaturday, setIsSaturday] = useState(false);
    const [saturdays, setSaturdays] = useState([]);
    const [isOnleave, setIsOnleave] = useState(false);
    const [userLeaves, setUserLeaves] = useState([]);
    const navigate = useNavigate();

    const EditEntry = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid && !isSunday && !isholiday && !isOnleave) {
            await api.post(`/editTimeEntry/${id}`, {
                project_id: project,
                task_id: task,
                hours: hours,
                date: date,
            }).then((response) => {
                navigate('/time-entry');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            }).catch((error) => {
                console.log("error", error)
                showToast(error.response.data.errors,'error');
            })
        }
        else {
            setValidated(true)
        }
    }

    useEffect(() => {
        api.get(`/editTimeEntry/${id}`).then((response) => {
            if (response.data !== undefined) {
                setProject(response.data.timeEntryData.project_id);
                setTask(response.data.timeEntryData.task_id);
                setHours(response.data.timeEntryData.hours)
                const date = new Date(response.data.timeEntryData.date);
                const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                setDate(formattedDate);
                const dates = [];
                response.data.userLeavesdata.forEach((entry) => {
                    const startDate = new Date(entry.datefrom);
                    const endDate = new Date(entry.dateto);
                    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                        dates.push(new Date(date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        }).replace(/\//g, '-'));
                    }
                });
                dates.length > 0 && setUserLeaves(dates);
                setProjectlist(response.data.projectData);
                const parsedHolidays = response.data.holidayData.map(holiday => (new Date(holiday.holiday_date)).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                }).replace(/\//g, '-'));
                setHoliday(parsedHolidays);
            }
        }).catch((error) => {
            console.log("error", error)
        });
    }, []);

    useEffect(() => {
        if (project) {
            api.post(`/getTaskByProject/${project}`, '').then((response) => {
                if (response.data !== undefined) {
                    setTasklist(response.data.taskData);
                }
            }).catch((error) => {
                console.log("error", error)
            });
            if (prevProject !== project) {
                setTasklist([]);
            }
            setPrevProject(project);
        }
    }, [project]);

    function get2nd4thSaturdays(year, month) {
        const firstDay = new Date(year, month - 1, 1);
        const weekdayOfFirstDay = firstDay.getDay();
        const secondSaturday = 14 - weekdayOfFirstDay + 1;
        const fourthSaturday = 28 - weekdayOfFirstDay + 1;
        const secondSatDate = new Date(year, month - 1, secondSaturday);
        const fourthSatDate = new Date(year, month - 1, fourthSaturday);
        secondSatDate.setUTCHours(12);
        fourthSatDate.setUTCHours(12);
        const secondSatString = secondSatDate.toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-');
        const fourthSatString = fourthSatDate.toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-');
        secondSatString !== "Invalid Date" && fourthSatString !== "Invalid Date" && saturdays.push(secondSatString, fourthSatString);
    }

    useEffect(() => {
        if (date) {
            const formatedDate = ((new Date(date).toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' })).replace(/\//g, '-'));
            if (saturdays.includes(formatedDate)) {
                setIsSaturday(true);
                setIsHoliday(false);
                setDateError(false);
                setIsSunday(false);
                setIsOnleave(false)
                return;
            }
            if (holiday.includes(formatedDate)) {
                setIsHoliday(true);
                setIsSaturday(false);
                setDateError(false);
                setIsSunday(false);
                setIsOnleave(false)
                return
            }
            if (userLeaves.includes(formatedDate)) {
                setIsOnleave(true)
                setIsSaturday(false);
                setIsHoliday(false);
                setDateError(false);
                setIsSunday(false);
                return
            }
            if ((new Date(date).getDay() === 0) === true) {
                setIsSunday(true);
                setIsSaturday(false);
                setDateError(false);
                setIsOnleave(false);
                setIsHoliday(false);
                return;
            }
        }
    }, [date]);

    useEffect(() => {
        setSaturdays([])
        let year = new Date(date).getFullYear();
        let month = new Date(date).getMonth() + 1;
        get2nd4thSaturdays(year, month)
    }, [date])

    return (
        <>
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Time Entry</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={e => EditEntry(e)} noValidate validated={validated}>
                                <Row>
                                    <Col sm="12" lg="6">
                                        <div className="form-group">
                                            <Form.Label htmlFor="permission_description">Project</Form.Label>
                                            <select
                                                name=""
                                                id=""
                                                className='form-select mb-3 shadow-none form-control'
                                                value={project}
                                                onChange={e => setProject(e.target.value)}
                                                required>
                                                <option value="">Select</option>
                                                {projectlist.length > 0 ?
                                                    projectlist.map((item, index) => (
                                                        <option value={item._id} key={index}>{item.title}</option>
                                                    ))
                                                    :
                                                    <option>No data found</option>
                                                }
                                            </select>
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <Form.Label htmlFor="permission_description">Project Task</Form.Label>
                                        <select name="" id="" className='form-select mb-3 shadow-none form-control' onChange={e => setTask(e.target.value)}
                                            value={task}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {tasklist.length > 0 ?
                                                tasklist.map((item, index) => (
                                                    <option value={item._id} key={index}>{item.title}</option>
                                                ))
                                                :
                                                <option>No data found</option>
                                            }
                                        </select>
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Total Hours</Form.Label>
                                            <Form.Control type="number" id="title" onChange={e => setHours(e.target.value)} value={hours} required />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="task_description">Date:</Form.Label>
                                            <Form.Control type="date" id="task_description" onChange={e => setDate(e.target.value)} value={date}
                                                max={(new Date()).toISOString().split('T')[0]}
                                                required
                                                style={isSaturday || isSunday || dateError || isholiday || isOnleave ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                            />
                                            {isSaturday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Saturday.
                                                </Form.Control.Feedback>
                                            }
                                            {isSunday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Sunday
                                                </Form.Control.Feedback>
                                            }
                                            {isOnleave &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    You are on leave on this date
                                                </Form.Control.Feedback>
                                            }
                                            {isholiday &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Selected date is Holiday
                                                </Form.Control.Feedback>
                                            }
                                            {dateError &&
                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                    Date is out of range
                                                </Form.Control.Feedback>
                                            }
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="6">
                                        <Button type="submit" variant="btn btn-primary me-2">Update</Button>
                                        <Button type="button" variant="btn btn-danger" onClick={() => navigate
                                            ('/time-entry')}>cancel</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainerWrapper />
        </>
    )
}

export default EditEntry
