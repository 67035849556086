import React from 'react';
import { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddPermission({ api }) {
    const [permission_name, setPermission_name] = useState('');
    const [permission_description, setPermission_description] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const AddPermission = (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            api.post(`/addpermissions`, {
                permission_name,
                permission_description,
            }
            )
                .then((response) => {
                    navigate('/permission');
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 100)
                })
                .catch((error) => { 
                    showToast(error.response.data.errors,'error');
                })
                .finally(() => {
                    setLoading(false); // Set loading to false when API call is complete
                });
        } else {
            setValidated(true);
            setLoading(false);
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Permission</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    id="form-wizard1"
                                    onSubmit={(e) => AddPermission(e)}
                                    noValidate
                                    validated={validated}
                                >
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Permission Name:<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="permission_name"
                                            onChange={(e) => setPermission_name(e.target.value)}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="permission_description">Permission Description:<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="exampleFormControlTextarea1"
                                            rows="5"
                                            onChange={(e) => setPermission_description(e.target.value)}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        variant="btn btn-primary me-2"
                                        disabled={loading} // Disable the button when loading
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </Button>{' '}
                                    <Button
                                        type="button"
                                        variant="btn btn-danger"
                                        onClick={() => navigate('/permission')}
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ToastContainerWrapper />
            </div>
        </>
    );
}

export default AddPermission;
