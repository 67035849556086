import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function EditRole({ api }) {
    const [role_name, setRole_name] = useState('');
    const [role_description, setRole_description] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        GetRole();
    }, []);

    const GetRole = () => {
        setLoading(true);
        api.get(`/editRole/${id}`)
            .then((Response) => {
                setRole_name(Response.data.roleData.role_name);
                setRole_description(Response.data.roleData.role_description);
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    navigate('/403')
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const UpdateRole = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        setValidated(true);
        setLoading(true);
        if (isValid) {
            api.post(
                `/editRole/${id}`,
                {
                    role_name,
                    role_description,
                }
            )
                .then((response) => {
                    if (response.data.status === false) {
                        navigate('/403')
                    }
                    navigate('/role');
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 100)
                })
                .catch((error) => {
                    if (error.response.status == 403) {
                        navigate('/403')
                    }
                    showToast(error.response.data.errors, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <div style={{ margin: '10px' }}>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Edit Role</h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={(e) => UpdateRole(e)} noValidate validated={validated}>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="role">Role Name:<span className='text-danger'>*</span></Form.Label>
                                <Form.Control type="text" id="role_name" value={role_name} onChange={(e) => setRole_name(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="role_description">Role Description:<span className='text-danger'>*</span></Form.Label>
                                <Form.Control as="textarea" id="exampleFormControlTextarea1" rows="5" value={role_description} onChange={(e) => setRole_description(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" variant="btn btn-primary me-2" disabled={loading}>
                                {loading ? 'Updating...' : 'Update'}
                            </Button>
                            <Button type="button" variant="btn btn-danger" onClick={() => navigate('/role')}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <ToastContainerWrapper />
            </div>
        </>
    );
}

export default EditRole;
