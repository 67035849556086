import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import '../../assets/scss/cc-custom/Add.css'
import Card from '../../components/Card'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast } from '../../plugins/toastUtils'

function EditEmployee({ api, imagePath }) {

    const [show, AccountShow] = useState('Login');
    const [_id, set_id] = useState('');
    const [role_id, setRole_id] = useState("");
    const [emp_code, setEmp_code] = useState("");
    const [reporting_user_id, setReporting_user_id] = useState("");
    const [firstname, setFirstname] = useState("");
    const [user_name, setUser_name] = useState("");
    const [middle_name, setMiddle_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [doj, setDoj] = useState("");
    const [personal_email, setPersonal_email] = useState("");
    const [company_email, setCompany_email] = useState("");
    const [mo_number, setMo_number] = useState("");
    const [pan_number, setPan_number] = useState("");
    const [aadhar_number, setAadhar_number] = useState("");
    const [pan_numberError, setPan_numberError] = useState(false);
    const [add_1, setAdd_1] = useState("");
    const [add_2, setAdd_2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [country, setCountry] = useState("");
    const [photo, setPhoto] = useState("");
    const [bank_account_no, setBank_account_no] = useState("");
    const [bank_name, setBank_name] = useState("");
    const [ifsc_code, setIfsc_code] = useState("");
    const [status, setStatus] = useState("");
    const [imagePreview, setImagePreview] = useState('');
    const [isRequiredForm1, setIsRequiredForm1] = useState(true);
    const [isRequiredForm2, setIsRequiredForm2] = useState(false);
    const [isRequiredForm3, setIsRequiredForm3] = useState(false);
    const [existusername, setExistusername] = useState("false");
    const [existemail, setExistmail] = useState(false);
    const [roles, setRoles] = useState([]);
    const [reportinguser, setReportinguser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cityData, setCityData] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    let today = new Date();
    const yyyy = today.getFullYear() - 18;

    useEffect(() => {
        api.get(`/editUser/${id}`).then((response) => {
            let userData = response.data.userData;
            if (userData !== undefined) {
                setRole_id(userData.role_id);
                setEmp_code(userData.emp_code)
                setReporting_user_id(userData.reporting_user_id)
                setFirstname(userData.firstname)
                setUser_name(userData.user_name)
                setMiddle_name(userData.middle_name)
                setLast_name(userData.last_name)
                setGender(userData.gender)
                setDob(userData.dob)
                setDoj(userData.doj)
                setPersonal_email(userData.personal_email)
                setCompany_email(userData.company_email)
                setMo_number(userData.mo_number)
                setPan_number(userData.pan_number)
                setAadhar_number(userData.aadhar_number)
                setAdd_1(userData.add_1)
                setAdd_2(userData.add_2)
                setCity(userData.city)
                setState(userData.state)
                setPincode(userData.pincode)
                setCountry(userData.country)
                setImagePreview(imagePath + userData.photo)
                setBank_account_no(userData.bank_account_no)
                setBank_name(userData.bank_name)
                setIfsc_code(userData.ifsc_code)
                setStatus(userData.status)

            }
            setRoles(response.data.roleData)
            setReportinguser(response.data.allUserData)
            setCityData(response.data.cityData)
        }).catch((error) => {
            if (error.response.status == 403) {
                navigate('/403')
            }
            console.log("error", error)
        });
    }, []);

    const handlePanChange = (e) => {
        const panNumber = e.target.value;
        const panPattern = /^[A-Z]{5}\d{4}[A-Z]$/;
        const isValid = panPattern.test(panNumber);
        setPan_number(panNumber);
        setPan_numberError(!isValid);
    };

    const UpdateEmployee = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        switch (show) {
            case "Login":
                try {
                    const usernameResponse = await api.post(
                        `/checkUpdateUsername`,
                        {
                            user_name: user_name || "",
                            user_id: id
                        }
                    );
                    if (usernameResponse.data.status === true) {
                        setIsRequiredForm1(false);
                        setExistusername(true);
                        AccountShow("Login");
                    } else {
                        const emailResponse = await api.post(
                            `/checkUpdateEmail`,
                            {
                                company_email: company_email || "",
                                user_id: id
                            },
                        );

                        if (emailResponse.data.status === true) {
                            setIsRequiredForm1(false);
                            setExistmail("true");
                            AccountShow("Login");
                        } else {
                            setIsRequiredForm2(true);
                            AccountShow("Personal");
                        }
                    }
                } catch (error) {
                    console.log("error", error)
                }
                break;
            case "Personal":
                setIsRequiredForm3(true);
                AccountShow("Banking");
                break;
            case "Banking":
                try {
                    const formData = new FormData();
                    formData.set('photo', photo);
                    formData.set('role_id', role_id);
                    formData.set('emp_code', emp_code);
                    formData.set('reporting_user_id', reporting_user_id);
                    formData.set('firstname', firstname);
                    formData.set('user_name', user_name);
                    formData.set('middle_name', middle_name);
                    formData.set('last_name', last_name);
                    formData.set('gender', gender);
                    formData.set('dob', dob);
                    formData.set('doj', doj);
                    formData.set('personal_email', personal_email);
                    formData.set('company_email', company_email);
                    formData.set('status', status);
                    formData.set('mo_number', mo_number);
                    formData.set('pan_number', pan_number);
                    formData.set('aadhar_number', aadhar_number);
                    formData.set('add_1', add_1);
                    formData.set('add_2', add_2);
                    formData.set('city', city);
                    formData.set('state', state);
                    formData.set('pincode', pincode);
                    formData.set('country', country);
                    formData.set('bank_account_no', bank_account_no);
                    formData.set('bank_name', bank_name);
                    formData.set('ifsc_code', ifsc_code);
                    setLoading(true);
                    const response = await api.post(
                        `/editUser/${id}`, formData
                    );
                    if (response.data.status === true) {
                        AccountShow("Image");
                    }
                    navigate("/employee")
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 300);
                } catch (error) {
                    if (error.response.status == 403) {
                        navigate('/403')
                    }
                    showToast(error.response.data.errors, 'error');
                    console.log("error", error)
                } finally {
                    setLoading(false);
                }
                break;
            default:
                break;
        }
    };

    let oldDob = moment(new Date(dob)).format().split('T')[0];

    let oldDoj = moment(new Date(doj)).format().split('T')[0];

    const inputRef = useRef(null);

    const handlePhotoChange = (event) => {
        const selectedPhoto = event.target.files[0];
        setPhoto(selectedPhoto);
        setImagePreview('');
        const imageObjectURL = URL.createObjectURL(selectedPhoto);
        setImagePreview(imageObjectURL);
    };

    const handleRemovePhoto = () => {
        setPhoto('');
        setImagePreview('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Employee</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form id="form-wizard1" noValidate className="text-center mt-3 add-form" onSubmit={e => UpdateEmployee(e)}>
                                    <ul id="top-tab-list" className="p-0 row list-inline">
                                        <li className={` ${show === 'Image' ? ' active done' : ''} ${show === 'Banking' ? ' active done' : ''} ${show === 'Personal' ? ' active done' : ''} ${show === 'Login' ? 'active' : ''} col-lg-4 col-md-6 text-start mb-2 active`} id="account">
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                                    </svg>
                                                </div>
                                                <span>Login</span>
                                            </Link>
                                        </li>
                                        <li id="personal" className={`${show === 'Banking' ? ' active done' : ''} ${show === 'Image' ? ' active done' : ''} ${show === 'Personal' ? 'active ' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                    </svg>
                                                </div>
                                                <span>Personal</span>
                                            </Link>
                                        </li>
                                        <li id="payment" className={`${show === 'Image' ? ' active done' : ''} ${show === 'Banking' ? 'active' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>                                </svg>
                                                </div>
                                                <span>Banking</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <fieldset className={`${show === 'Login' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h3 className="mb-4">Login Information: </h3>
                                                </div>
                                            </div>
                                            <input type="hidden" name="_id" value={_id} onChange={e => set_id(e.target.value)} />
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group has-validation">
                                                        <label className="form-label">Username: <span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" name="user_name" id='username' placeholder="UserName" onChange={e => setUser_name(e.target.value)} required={isRequiredForm1} value={user_name} />
                                                        <div className="err text-danger">Invalid Username</div>
                                                        <div className={`${existusername === true ? "d-block" : "d-none"} text-danger`}>{existusername === true ? "username already exist" : ""}{existusername === false ? "" : ""}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group has-validation">
                                                        <label className="form-label">Company Email: <span className='text-danger'>*</span></label>
                                                        <Form.Control type="email" className="form-control" name="personal_email" id='personal_email' placeholder="Email Id" data-temp-mail-org="0" onChange={e => setCompany_email(e.target.value)} required={isRequiredForm1} value={company_email} />
                                                        <div className="err text-danger">Invalid Email</div>
                                                        <div className={`${existemail === "true" ? "d-block" : "d-none"} text-danger`}>{existemail === "true" ? "this email is already exist" : ''}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button type="submit" data-value="PersonalForm" name="next" className="next action-button float-end" value="Next" >Next</Button>
                                        <Button type="button" variant='danger' name="previous" className="float-end me-1" onClick={() => navigate('/employee')} >Cancel</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h3 className="mb-4">Personal Information:</h3>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">First Name: <span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" id="firstname" name="firstname" placeholder="First Name" onChange={e => setFirstname(e.target.value)} required={isRequiredForm2} value={firstname} />
                                                        <div className="err text-danger">Please enter first name</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Middle Name: <span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" name="middle_name" id="middle_name" placeholder="middle Name" onChange={e => setMiddle_name(e.target.value)} required={isRequiredForm2} value={middle_name} />
                                                        <div className="err text-danger">Please enter middle name</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Last Name: <span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" name="last_name" id="last_name" placeholder="Last Name" onChange={e => setLast_name(e.target.value)} required={isRequiredForm2} value={last_name} />
                                                        <div className="err text-danger">Please enter last name</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Contact No : <span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" name="mo_number" id="mo_number" placeholder="Contact No."
                                                            onChange={e => setMo_number(e.target.value)}
                                                            required={isRequiredForm2}
                                                            maxLength={10}
                                                            pattern="^[0-9]{10}$"
                                                            value={mo_number} />
                                                        <div className="err text-danger">Please enter contact number</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Date Of Birth :<span className='text-danger'>*</span></label>
                                                        <Form.Control type="date" className="form-control" name="dob" id="dob" placeholder=""
                                                            onChange={e => setDob(e.target.value)}
                                                            value={oldDob !== "Invalid date" ? oldDob : ''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="form-label">Gender :<span className='text-danger'>*</span></label>
                                                        <Form.Check className="d-block">
                                                            {gender === 'Male' ?
                                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('Male')} checked={true} /> :
                                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('Male')} />}
                                                            <Form.Check.Label htmlFor="flexRadioDefault1">
                                                                Male
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="d-block">
                                                            {gender === 'Female' ?
                                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('Female')} checked={true} /> :
                                                                <Form.Check.Input className="me-2" type="radio" name="gender" onClick={e => setGender('Female')} />}
                                                            <Form.Check.Label htmlFor="flexRadioDefault1">
                                                                Female
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="col-sm-2 col-form-label">Profile Image</label>
                                                    <div className="d-flex" style={{ alignItems: "center" }}>
                                                        <div className={`${imagePreview ? 'col-lg-8' : 'col-lg-12'} me-5`} >
                                                            <input type="file" className="form-control" name="photo" id="photo" aria-label="file example" onChange={handlePhotoChange} ref={inputRef} />
                                                        </div>
                                                        {imagePreview && (
                                                            <>
                                                                <div className="col-sm-2">
                                                                    <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />
                                                                </div>
                                                                <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleRemovePhoto} style={{ cursor: 'pointer', position: 'relative', bottom: '2.1rem' }}>
                                                                    <path d="M14.3955 9.59497L9.60352 14.387" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M14.3971 14.3898L9.60107 9.59277" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                                                                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Personal email :<span className='text-danger'>*</span></label>
                                                        <Form.Control type="email" className="form-control" name="company_email" id="company_email" placeholder="example@examle.com" onChange={e => setPersonal_email(e.target.value)} required={isRequiredForm2} value={personal_email} />
                                                        <div className="err text-danger">Please enter valid email</div>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Date of joining :<span className='text-danger'>*</span></label>
                                                        <Form.Control type="date" className="form-control" name="doj" id="doj" placeholder="example@examle.com" onChange={e => setDoj(e.target.value)}
                                                            max={new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0]} min="2018-01-01"
                                                            value={oldDoj !== "Invalid date" ? oldDoj : ''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Reporting User :<span className='text-danger'>*</span></label>
                                                        <Form.Control as="select" className="form-select mb-3 shadow-none" name="reporting_user_id" id='reporting_user_id' onChange={e => setReporting_user_id(e.target.value)}
                                                            value={reporting_user_id}
                                                        >
                                                            <option>select</option>
                                                            <option value="" disabled>Select Name</option>

                                                            {reportinguser.length > 0 ?
                                                                reportinguser.map((data, id) => {
                                                                    return <option key={id} value={data._id}>{data.firstname}</option>
                                                                }) :
                                                                (<option value="" disabled>no user found</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Role :<span className='text-danger'>*</span></label>
                                                        <Form.Control as="select" className="form-select mb-3 shadow-none text-capitalize" name="role_id" id="role_id" value={role_id} onChange={e => setRole_id(e.target.value)}
                                                        >
                                                            <option value="">Select</option>
                                                            {roles.length > 0 ?
                                                                roles.map((roles, id) => {
                                                                    return <option key={id} value={roles._id}>{roles.role_name}</option>
                                                                }) :
                                                                (<option value="" disabled>no roles found</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Employee Code</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">CC</span>
                                                            </div>
                                                            <Form.Control type="text" className="form-control" name="emp_code" id="emp_code" placeholder="0123" onChange={e => setEmp_code(e.target.value)} required={isRequiredForm2} value={emp_code.slice(3)} disabled />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Employee Status</label>
                                                    <Form.Control as="select" className="form-select shadow-none" id='status' name="status" value={status} onChange={e => setStatus(e.target.value)}>
                                                        <option value="" >Select status</option>
                                                        <option value="Pending ">Pending</option>
                                                        <option value="Active">Active </option>
                                                        <option value="InActive ">InActive</option>
                                                        <option value="ExEmployee">ExEmployee</option>
                                                    </Form.Control>
                                                </div>
                                                <hr />
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Pancard Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="pan_number"
                                                            id="pan_number"
                                                            pattern="^[A-Z]{5}\d{4}[A-Z]$"
                                                            placeholder="ABCDE1234F"
                                                            onChange={handlePanChange}
                                                            value={pan_number}
                                                            required
                                                        />
                                                        <div className="err text-danger">
                                                            {pan_numberError && "Please enter a valid PAN card number"}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Aadharcard number</label>
                                                        <Form.Control type="text" className="form-control" name="aadhar_number" id="aadhar_number" data-type="aadhar_number" placeholder="enter your aadhar number here" onChange={e => setAadhar_number(e.target.value)} pattern="^[0-9]{12}$" value={aadhar_number} />
                                                        <div className="err text-danger">Please enter aadharcard number</div>
                                                    </div>
                                                </div>
                                                <hr className='mt-2' />

                                                <div className="col-md-6">
                                                    <label htmlFor="validationTextarea" className="form-label">Address 1 :<span className='text-danger'>*</span></label>
                                                    <Form.Control as="textarea" className="form-control" id="add_1" name="add_1" placeholder="add 1 textarea" onChange={e => setAdd_1(e.target.value)} value={add_1} />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="validationTextarea" className="form-label">Address 2</label>
                                                    <Form.Control as="textarea" className="form-control" id="add_2" name="add_2" placeholder="add 2 textarea" onChange={e => setAdd_2(e.target.value)} value={add_2} />
                                                </div>

                                                <div className="col-md-4 mt-2">
                                                    <div className="form-group">
                                                        <label className="form-label">City</label>
                                                        <Form.Control
                                                            as="select"
                                                            className="form-select mb-3 shadow-none"
                                                            name="city"
                                                            id='city'
                                                            onChange={e => setCity(e.target.value)}
                                                            value={city}
                                                            required
                                                        >
                                                            <option value="">Select city</option>
                                                            {cityData && cityData.length > 0 ? (
                                                                cityData.map((data, id) => (
                                                                    <option key={id} value={data.city}>
                                                                        {data.city}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="N/A" disabled>
                                                                    N/A
                                                                </option>
                                                            )}
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-2">
                                                    <div className="form-group">
                                                        <label className="form-label">State</label>
                                                        <Form.Control as="select" className="form-select mb-3 shadow-none" id='state' name="state" onChange={e => setState(e.target.value)} value={state}>
                                                            <option value="">Select</option>
                                                            <option value="Gujarat">Gujarat</option>
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Country</label>
                                                        <Form.Control as="select" className="form-select mb-3 shadow-none" id="country" name="country" onChange={e => setCountry(e.target.value)} value={country}>
                                                            <option value="">Select</option>
                                                            <option value="India">India</option>
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Pincode :<span className='text-danger'>*</span></label>
                                                        <Form.Control type="text" className="form-control" id="pincode" name="pincode" placeholder="pin" onChange={e => setPincode(e.target.value)} required={isRequiredForm2} pattern="^[0-9]{6}$" setlimit={6} value={pincode} />
                                                        <div className="err text-danger">Please enter valid pincode</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Button type="submit" name="next" className="btn btn-primary next action-button float-end" value="Next" >Next</Button>
                                        <Button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('Login')} >Previous</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Banking' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h3 className="mb-4">Banking</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Ifsc Code  :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" name="ifsc_code" id="ifsc_code" placeholder="example@examle.com" onChange={e => setIfsc_code(e.target.value)} required={isRequiredForm3} value={ifsc_code} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Bank Name :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" name="bank_name" id="bank_name" placeholder="Bank Name" value={bank_name} onChange={e => setBank_name(e.target.value)} required={isRequiredForm3} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">bank Account Number :<span className='text-danger'>*</span></label>
                                                    <Form.Control type="text" className="form-control" id="bank_account_no" name="bank_account_no" placeholder="0123...upto 17" onChange={e => setBank_account_no(e.target.value)} required={isRequiredForm3} value={bank_account_no} />
                                                    <div className="err text-danger">Please enter valid bank account number</div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button type="submit" name="next" className="btn btn-primary  action-button float-end" disabled={loading} value="Submit">{loading ? 'Updating...' : 'Update'}</Button>

                                        <Button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('Personal')} >Previous</Button>
                                    </fieldset>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div >
        </>
    )
}

export default EditEmployee
