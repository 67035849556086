import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import { showToast } from '../../plugins/toastUtils';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';

function EditPermission({ api }) {
    const { id } = useParams();

    const [permission_name, setPermission_name] = useState('');
    const [permission_description, setPermission_description] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        const getPermission = async () => {
            try {
                const response = await api.get(`/editpermissions/${id}`);
                const permissionData = response.data.permissionData;
                setPermission_name(permissionData.permission_name);
                setPermission_description(permissionData.permission_description);
            } catch (error) {
                // Handle the error
            }
        };

        getPermission();
    }, [id]);

    const navigate = useNavigate();

    const updatePermission = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when API call starts

        try {
           const response = await api.post(
                `/editpermissions/${id}`,
                {
                    permission_name: permission_name,
                    permission_description: permission_description,
                }
            );
            setTimeout(() => {
                showToast(response.data.message, "success")
            }, 100)
            navigate('/permission');
        } catch (error) {
            // Handle the error
            showToast(error.response.data.errors,'error');
        } finally {
            setLoading(false); // Set loading to false when API call is complete
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Permission</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={(e) => updatePermission(e)} noValidate validated={validated}>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Permission Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="permission_name"
                                            value={permission_name}
                                            onChange={(e) => setPermission_name(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="permission_description">Permission Description:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            value={permission_description}
                                            onChange={(e) => setPermission_description(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        variant="btn btn-primary me-2"
                                        disabled={loading} // Disable the button when loading
                                    >
                                        {loading ? 'Updating...' : 'Update'}
                                    </Button>{' '}
                                    <Button
                                        type="button"
                                        variant="btn btn-danger"
                                        onClick={() => navigate('/permission')}
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ToastContainerWrapper />
        </>
    );
}

export default EditPermission;
