import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper';
import { showToast } from '../../plugins/toastUtils';

function AddRole({ api }) {
    const [role_name, setRole_name] = useState('');
    const [role_description, setRole_description] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const AddRole = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            try {
                const response = await api.post(`/addRole`, {
                    role_name,
                    role_description,
                });
                if (response.data.status === false) {
                    navigate('/403')
                }
                navigate('/role');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 100)
            } catch (error) {
                if(error.response.status == 403){
                    navigate('/403')
                 }
                showToast(error.response.data.errors,'error');
            } finally {
                setLoading(false);
            }
        } else {
            setValidated(true);
            setLoading(false);
        }
    };

    return (
        <>
            <div style={{ margin: '10px' }}>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Create Role</h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form id="form-wizard1" onSubmit={(e) => AddRole(e)} noValidate validated={validated}>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="role">Role Name:<span className='text-danger'>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="role_name"
                                    onChange={(e) => setRole_name(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="role_description">Role Description:<span className='text-danger'>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    onChange={(e) => setRole_description(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button
                                type="submit"
                                variant="btn btn-primary me-2"
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                            <Button
                                type="button"
                                variant="btn btn-danger"
                                onClick={() => navigate('/role')}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                    <ToastContainerWrapper />
                </Card>
            </div>
        </>
    );
}

export default AddRole;
