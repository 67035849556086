import React from 'react'
import { Row, Col, Image, Form, Button, ListGroup, Alert } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../components/Card'

// img
import facebook from '../../assets/images/brands/fb.svg'
import google from '../../assets/images/brands/gm.svg'
import instagram from '../../assets/images/brands/im.svg'
import linkedin from '../../assets/images/brands/li.svg'
import auth5 from '../../assets/images/auth/05.png'
import auth7 from '../../assets/images/auth/07.png'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'
import { useState, useEffect } from 'react'
import api from '../../utils/api'

const ForgotPasswordChange = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const { token } = useParams();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);


    useEffect(() => {
        api.post(`/password-checkToken/${id}/${token}`)
            .then(() => {
                localStorage.setItem('activeStatus', true);
                navigate('/login')

            }).catch((error) => {
                if (error.response.data.tokenStatus === false) {
                    setTokenExpired(true);
                }
            });
        return () => {

        };
    }, []);

    useEffect(() => {
        if (cpassword) {
            if (cpassword !== password) {
                setPasswordError(true);
            }
            else {
                setPasswordError(false);
            }
        } else {
            setPasswordError(false);
        }
        return () => {
        };
    }, [cpassword, password]);
    const ActiveAccount = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid && passwordError === false) {
            api.post(`/forgot-password-change/${id}/${token}`, {
                password: password,
                cpassword: cpassword
            })
                .then((response) => {
                    navigate('/login')
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 100)

                }).catch((error) => {
                    navigate('/forgot-password')
                    setTimeout(() => {
                        showToast(error.response.data.message, 'error');
                    }, 100)
                });
        }
        else {
            setValidated(true)
        }

    }

    return (
        <>
            {tokenExpired ?
                <section className="login-content">
                    <Row className="m-0 align-items-center bg-white vh-100">
                        <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                            <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
                        </div>
                        <Col md="6">
                            <Row className="justify-content-center">
                                <Col md="10">
                                    <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                                        <Card.Body>
                                            <Link className="navbar-brand d-flex align-items-center mb-3">
                                                <img src={auth7} alt="" height={50} width={50} />
                                                <h4 className="logo-title ms-3">Change Password</h4>
                                            </Link>
                                            <Row>
                                                <Col lg="12">
                                                    <Alert variant="warning d-flex align-items-center" role="alert">
                                                        <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                        </svg>
                                                        <div>
                                                            The reset link is invalid or expired. Try requesting a new one.
                                                        </div>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
                :
                <section className="login-content">
                    <Row className="m-0 align-items-center bg-white vh-100">
                        <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                            <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
                        </div>
                        <Col md="6">
                            <Row className="justify-content-center">
                                <Col md="10">
                                    <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                                        <Card.Body>
                                            <Link className="navbar-brand d-flex align-items-center mb-3">
                                                <img src={auth7} alt="" height={50} width={50} />
                                                <h4 className="logo-title ms-3">Change Password</h4>
                                            </Link>

                                            <Form id="form-wizard1" onSubmit={e => ActiveAccount(e)} noValidate validated={validated}>
                                                <Row>
                                                    <Col lg="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="full-name" className="">Enter Your Password</Form.Label>
                                                            <Form.Control type="text" className={`form-control`} id="full-name" placeholder="password" onChange={(e) => setPassword(e.target.value)} required
                                                                pattern="^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=]).*$"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="full-name" className="">Confirm Password</Form.Label>
                                                            <Form.Control type="text" className={`form-control`} id="full-name" placeholder="confirm password" onChange={(e) => setCpassword(e.target.value)} required
                                                                style={passwordError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}

                                                            />
                                                            {passwordError &&
                                                                <Form.Control.Feedback type="invalid" className='d-block'>
                                                                    Password and Confirm Password should be same
                                                                </Form.Control.Feedback>
                                                            }
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex justify-content-center">
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </div>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainerWrapper />

                </section>
            }
        </>
    )
}

export default ForgotPasswordChange
