import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Card from '../../components/Card';

const UserLeave = ({ api }) => {

    const [employeeData, setEmployeeData] = useState([]);
    const [order, setOrder] = useState('ASC');
    const [entry] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [totalData, setTotalData] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [dataperPage, setDataperPage] = useState(entry);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    let lastEmployeeLeaveHistoryData = employeeData?.slice(-1);
    const defaultDate = moment().format('YYYY-MM-DD');

    const fetchingData = async (e) => {
        var date;
        if (e == undefined) {
            date = defaultDate
        } else {
            date = e.target.value;
        }
        try {
            await api.get(`/userLeaveHistory?search=${searchValue}&date=${date}&page=${page}&limit=${dataperPage}`)
                .then((response) => {
                    if (response?.data !== undefined) {
                        setLoading(false)
                        if (response?.data.status === false) {
                            navigate("/403");
                        } else {
                            setEmployeeData(response?.data.leavesData);
                            setTotalPages(response?.data.totalPages);
                            setTotalData(response?.data.totalData);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status == 403) {
                        navigate("/403");
                    }
                });
        } catch (error) {
            console.log("error", error);
        }
    }

    const perpagedata = (value) => {

        setDataperPage(value)
        setPage(1)
    }

    const PageChange = ({ selected }) => {
        setPage(selected + 1);
    };

    const sorting = () => {
        if (order === 'ASC') {
            setOrder('DSC')
        }
        if (order === 'DSC') {
            setOrder('ASC')
        }
    }

    const handleSearch = async (event) => {
        if (searchValue && event.key === 'Enter') {
            setPage(1)
            setSearch(true)
            fetchingData()
        }
    }

    useEffect(() => {
        fetchingData();
    }, [page, dataperPage]);

    useEffect(() => {
        if (!searchValue && search) {
            fetchingData();
        }
        return () => { };
    }, [searchValue]);

    return (
        <>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className={`d-flex justify-content-between`}>
                            <div className="row align-items-center w-100">
                                <div className="col-md-3">
                                    <div
                                        className="dataTables_length d-flex align-items-center"
                                        id="user-list-table_length"
                                        style={{ gap: '2%' }}
                                    >
                                        <label>Show</label>
                                        <select
                                            name="user-list-table_length"
                                            aria-controls="user-list-table"
                                            className="form-select form-select-sm"
                                            onChange={(e) => perpagedata(e.target.value)}
                                            defaultValue={entry}
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                </div>


                                {/* DATE  */}

                                <div className="col-md-3">
                                    <div className='d-flex align-items-center'>
                                        <label className=''>Year</label>
                                        <input
                                            type="date"
                                            defaultValue={defaultDate}
                                            onChange={fetchingData}
                                            // onChange={(e) => setYear(e.target.value)}
                                            className="form-control ms-2 w-100"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        className="dataTables_length d-flex align-items-center"
                                        id="user-list-table_length"
                                        style={{ gap: '2%' }}
                                    >
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        id="user-list-table_filter"
                                        className='d-flex justify-content-end'
                                    >
                                        <label>Search:</label>
                                        <input
                                            type="search"
                                            className='ms-2'
                                            placeholder=""
                                            aria-controls="user-list-table"
                                            onChange={(event) => setSearchValue(event.target.value)}
                                            onKeyDown={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Header>

                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="user-list-table"
                                    className="table table-striped"
                                    role="grid"
                                    data-toggle="data-table"
                                >
                                    <thead>
                                        <tr className="ligth">
                                            <th>No</th>
                                            <th
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => sorting('firstname')}
                                            >
                                                <span className="title">Name</span>
                                            </th>
                                            <th>Reason</th>
                                            <th>From</th>
                                            <th>to</th>
                                            <th>Satus</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={20} className='text-center'>
                                                    <Spinner animation="border" variant="primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        employeeData?.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={20} className='text-center'>
                                                        No data found
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className="text-capitalize">
                                                {employeeData?.map((item, index) => {

                                                    return (
                                                        <tr key={index} className="border-light">
                                                            <td>{index + 1}</td>
                                                            <td>{item.userData.firstname}</td>
                                                            <td>{item.reason}</td>
                                                            <td> {moment(item.datefrom).format("DD/MM/YYYY")}</td>
                                                            <td> {moment(item.dateto).format("DD/MM/YYYY")}</td>
                                                            <td>
                                                                <span
                                                                    className={`badge ${item.status === "PENDING"
                                                                        ? "bg-warning"
                                                                        : item.status === "APPROVED"
                                                                            ? "bg-success"
                                                                            : "bg-primary"
                                                                        } p-2`}
                                                                    style={{ fontWeight: "200" }}
                                                                >
                                                                    {item.status}
                                                                </span>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        )
                                    )}
                                </table>
                            </div>
                            <div className="row align-items-center w-100 mt-2 p-0 m-0">
                                <div className="col-md-6">
                                    <div
                                        className="dataTables_length d-flex align-items-center mx-3"
                                        id="user-list-table_length"
                                    >

                                        {employeeData?.length > 0 && (

                                            <label>
                                                Showing {employeeData[0]?.index} to{" "}
                                                {lastEmployeeLeaveHistoryData[0]?.index} of {totalData} entries
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/*  <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%', position: "relative", right: '40%' }}>
                                            {leaves?.length > 0 && (
                                                <div className="dataTables_paginate paging_simple_numbers" id="user-list-table_paginate"> */}
                                    <ReactPaginate
                                        nextLabel="Next"
                                        onPageChange={PageChange}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        previousLabel="Previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        disabledClassName={"disabled"}
                                        activeClassName={"active"}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination float-end"
                                        renderOnZeroPageCount={null}
                                    />

                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </>
    );
};

export default UserLeave;
