import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionContext, Button, Col, Form, Row, Tab, useAccordionButton } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';

function CustomToggle({ children, eventKey, onClick }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}

function EditWorkingHour({ api, user_data }) {

    const { id } = useParams();
    const { userID } = useParams();
    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [date, setDate] = useState('');
    const [totalHour, setTotalHour] = useState('');
    const [validated, setValidated] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [disableTo, setDisableTo] = useState(false);
    const [existTimeError, setExistTimeError] = useState(false);
    const [toggleState, setToggleState] = useState(true);
    const [add_form_start_time, setAdd_form_Start_time] = useState('');
    const [add_form_end_time, setAdd_form_End_time] = useState('');
    const [add_form_date, setAdd_form_Date] = useState('');
    const [add_form_totalHour, setAdd_form_TotalHour] = useState('');
    const [add_form_validated, setAdd_form_Validated] = useState(false);
    const [add_form_endDateError, setAdd_form_EndDateError] = useState(false);
    const [add_form_disableTo, setAdd_form_DisableTo] = useState(false);
    const [add_form_existTimeError, setAdd_form_ExistTimeError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/editWorkingHour/${id}`).then((response) => {
            setStart_time(response.data.workingData.start_time);
            setEnd_time(response.data.workingData.end_time);
            setDate((response.data.workingData.date).split('T')[0]);
        }).catch((error) => {
            console.log("error", error)
        })
        return () => {
        };
    }, []);

    const calculateTotalHour = () => {
        if (!start_time || !end_time) {
            return "";
        }
        const start = new Date(`2000-01-01T${start_time}:00`);
        const end = new Date(`2000-01-01T${end_time}:00`);
        let diff = Math.abs(end - start);
        const hours = Math.floor(diff / 3600000).toString().padStart(2, "0");
        diff -= hours * 3600000;
        const minutes = Math.floor(diff / 60000).toString().padStart(2, "0");
        setTotalHour(hours + ':' + minutes);
    };

    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        const todayDate = `${year}-${month}-${day}`;
        setDate(todayDate)
        return () => {
        };
    }, []);

    useEffect(() => {
        calculateTotalHour();
        const startTimeMoment = moment(start_time, 'HH:mm');
        const endTimeMoment = moment(end_time, 'HH:mm');
        if (start_time && end_time) {
            if (endTimeMoment.isBefore(startTimeMoment)) {
                setEndDateError(true)
                setDisableTo(true);
                setTotalHour('00:00');
            } else {
                setEndDateError(false);
            }
        }
    }, [start_time, end_time, disableTo]);

    const EditWorkingHour = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            await api.post(`/editWorkingHour/${id}`, {
                start_time: start_time,
                end_time: end_time,
                date: date,
                total_hour: totalHour,
            }).then(() => {
                navigate('/working-hour');
            }).catch((error) => {
                console.log("error", error);
            })
        } else {
            setValidated(true);
        }
    }

    useEffect(() => {
        api.post(`/checkHour`, {
            user_id: user_data.user_id,
            hour_id: id
        }).then((response) => {
            const __workingHours = response.data.workingHourData;
            const exist = __workingHours.some((workingHour) => {
                return (
                    start_time >= workingHour.start_time &&
                    start_time < workingHour.end_time
                );
            });
            setExistTimeError(exist);
        }).catch((error) => {
            console.log('error', error);
        })
        return () => {
        };
    }, [date, start_time]);

    useEffect(() => {
        if (!start_time || existTimeError) {
            setDisableTo(true);
        }
        else {
            setDisableTo(false)
        }
        return () => {
        };
    }, [disableTo, start_time, existTimeError]);

    const AddFormcalculateTotalHour = () => {
        if (!add_form_start_time || !add_form_end_time) {
            return "";
        }
        const start = new Date(`2000-01-01T${add_form_start_time}:00`);
        const end = new Date(`2000-01-01T${add_form_end_time}:00`);
        let diff = Math.abs(end - start);
        const hours = Math.floor(diff / 3600000).toString().padStart(2, "0");
        diff -= hours * 3600000;
        const minutes = Math.floor(diff / 60000).toString().padStart(2, "0");
        setAdd_form_TotalHour(hours + ':' + minutes);
    };

    useEffect(() => {
        AddFormcalculateTotalHour();
        const startTimeMoment = moment(add_form_start_time, 'HH:mm');
        const endTimeMoment = moment(add_form_end_time, 'HH:mm');
        if (add_form_start_time && add_form_end_time) {
            if (endTimeMoment.isBefore(startTimeMoment)) {
                setAdd_form_EndDateError(true)
                setAdd_form_DisableTo(true);
                setAdd_form_TotalHour('00:00');
            } else {
                setAdd_form_EndDateError(false);
            }
        }
    }, [add_form_start_time, add_form_end_time, add_form_disableTo]);

    useEffect(() => {
        api.post(`/checkHour`, {
            user_id: userID,
            date: add_form_date
        }).then((response) => {
            const __workingHours = response.data.workingHourData;
            const exist = __workingHours.some((workingHour) => {
                return (
                    add_form_start_time >= workingHour.start_time &&
                    add_form_start_time < workingHour.end_time
                );
            });
            setAdd_form_ExistTimeError(exist);
        }).catch((error) => {
            console.log("error", error)
        })
        return () => {
        };
    }, [add_form_date, add_form_start_time]);

    useEffect(() => {
        if (!add_form_start_time || add_form_existTimeError) {
            setAdd_form_DisableTo(true);
        }
        else {
            setAdd_form_DisableTo(false)
        }
        return () => {
        };
    }, [add_form_disableTo, add_form_start_time, add_form_existTimeError]);

    useEffect(() => {
        if (!add_form_date) {
            document.getElementById('from_time').value = '';
            document.getElementById('to_time').value = '';
        }
        return () => {
        };
    }, [add_form_date]);

    const AddWorkingHour = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            await api.post(`/addWorkingHour`, {
                user_id: userID,
                start_time: add_form_start_time,
                end_time: add_form_end_time,
                date: add_form_date,
                total_hour: add_form_totalHour,
            }).then(() => {
                navigate('/working-hour');
            }).catch((error) => {
                console.log("error", error);
            })
        } else {
            setAdd_form_Validated(true);
        }
    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Edit Working Hour</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={e => EditWorkingHour(e)} noValidate validated={validated}>
                                    <Row>
                                        <Col sm="12" lg="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">From</Form.Label>
                                                <Form.Control type="time" id="title"
                                                    value={start_time}
                                                    onChange={e => setStart_time(e.target.value)}
                                                    style={existTimeError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    required />
                                                {existTimeError &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        You already added this time's working hour
                                                    </Form.Control.Feedback>
                                                }
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">To</Form.Label>
                                                <Form.Control type="time" id="project_description"
                                                    value={end_time}
                                                    onChange={e => setEnd_time(e.target.value)} required
                                                    style={endDateError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    disabled={disableTo}
                                                />
                                                {endDateError &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        End time must be less than start time
                                                    </Form.Control.Feedback>
                                                }
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="permission_description">Date</Form.Label>
                                                <Form.Control type="date" id="project_description"
                                                    onChange={e => setDate(e.target.value)} required
                                                    value={date}
                                                    disabled
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Total Hour</Form.Label>
                                                <Form.Control type="text" id="title" value={totalHour} onChange={e => setTotalHour(e.target.value)} required disabled />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="12" className='d-flex justify-content-center mt-2'>
                                            <Button type="submit" variant="btn btn-primary me-2">Update</Button>{' '}
                                            <Button type="submit" variant="btn btn-danger" onClick={() => navigate('/working-hour')}>Cancel</Button>{' '}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className="iq-document-card iq-doc-head">
                    <Tab.Container defaultActiveKey="first">
                        <Tab.Content>
                            <Tab.Pane eventKey="first" className={toggleState === true ? 'active' : ''} id="content-accordion-prv" role="tabpanel" aria-labelledby="typo-output">
                                <div className="bd-example">
                                    <Accordion defaultActiveKey="">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Add Working Hour</Accordion.Header>
                                            <Accordion.Body>
                                                <Col sm="12" lg="12">
                                                    <Form onSubmit={e => AddWorkingHour(e)} noValidate validated={add_form_validated}>
                                                        <Row>
                                                            <Col sm="12" lg="3">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="permission_description">Date</Form.Label>
                                                                    <Form.Control type="date" id="project_description"
                                                                        onChange={e => setAdd_form_Date(e.target.value)} required
                                                                        max={new Date().toISOString().substr(0, 10)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm="12" lg="3">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="role">From</Form.Label>
                                                                    <Form.Control type="time" id="from_time"
                                                                        onChange={e => setAdd_form_Start_time(e.target.value)}
                                                                        style={add_form_existTimeError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                                        required
                                                                        disabled={add_form_date ? false : true}
                                                                    />
                                                                    {add_form_existTimeError &&
                                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                                            You already added this time's working hour
                                                                        </Form.Control.Feedback>
                                                                    }
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm="12" lg="3">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="permission_description">To</Form.Label>
                                                                    <Form.Control type="time" id="to_time"
                                                                        onChange={e => setAdd_form_End_time(e.target.value)} required
                                                                        style={add_form_endDateError ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                                        disabled={add_form_disableTo}
                                                                    />
                                                                    {add_form_endDateError &&
                                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                                            End time must be less than start time
                                                                        </Form.Control.Feedback>
                                                                    }
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm="12" lg="3">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="role">Total Hour</Form.Label>
                                                                    <Form.Control type="text" id="title" value={add_form_totalHour} onChange={e => setTotalHour(e.target.value)} required disabled />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm="12" lg="12" className='d-flex justify-content-center mt-2'>
                                                                <Button type="submit" variant="btn btn-primary me-2">Save</Button>{' '}
                                                                <Button type="submit" variant="btn btn-danger" onClick={() => navigate('/working-hour')}>Cancel</Button>{' '}
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Card>
            </div>
        </>
    )
}

export default EditWorkingHour

